/**
 * assistiveListeningSystems
 * Don't modify this file. It is generated by scripts/build.js and is
 * ignored by git. The source svg file is located in the '../src' directory.
 */
import BrowserSpriteSymbol from 'svg-baker-runtime/browser-symbol';

const symbol = new BrowserSpriteSymbol({
  "id": "symbol-assistiveListeningSystems-1843354942",
  "use": "symbol-assistiveListeningSystems-1843354942-usage",
  "viewBox": "0 0 512 512",
  "content": "<symbol viewBox=\"0 0 512 512\" id=\"symbol-assistiveListeningSystems-1843354942\"><path d=\"M189.149 512c-13.255 0-24-10.745-24-24s10.745-24 24-24c36.393 0 66-30.016 66-66.909l.002-.334C256.157 324.62 328 312.824 328 264c0-66.918-53.497-120-120-120-66.38 0-120 52.95-120 120 0 13.255-10.745 24-24 24s-24-10.745-24-24c0-93.338 74.866-168 168-168 92.97 0 168 74.484 168 168 0 74.659-72.099 87.835-72.851 133.282-.106 63.272-51.205 114.718-114 114.718zM296 264c0-48.523-39.477-88-88-88s-88 39.477-88 88c0 13.255 10.745 24 24 24s24-10.745 24-24c0-22.056 17.944-40 40-40s40 17.944 40 40c0 13.255 10.745 24 24 24s24-10.745 24-24zm130.99-71c11.94-5.755 16.955-20.1 11.2-32.04-17.206-35.699-42.929-67.404-74.385-91.688-10.495-8.099-25.564-6.16-33.664 4.333s-6.16 25.563 4.332 33.664c25.581 19.748 46.493 45.521 60.477 74.532 5.759 11.946 20.109 16.951 32.04 11.199zm71.404-35.37c11.945-5.744 16.974-20.083 11.23-32.029-23.882-49.678-55.813-90.241-94.916-120.565-10.475-8.122-25.549-6.218-33.674 4.258-8.122 10.474-6.216 25.55 4.258 33.673 33.17 25.723 60.443 60.522 81.073 103.432 5.744 11.949 20.084 16.972 32.029 11.231zM208 280c-13.255 0-24 10.745-24 24s10.745 24 24 24 24-10.745 24-24-10.745-24-24-24zm-64 64c-13.255 0-24 10.745-24 24s10.745 24 24 24 24-10.745 24-24-10.745-24-24-24zM24 464c-13.255 0-24 10.745-24 24s10.745 24 24 24 24-10.745 24-24-10.745-24-24-24zm104.971-16.971l-64-64L31.03 416.97l64 64 33.941-33.941z\" /></symbol>"
});


symbol.name = "assistiveListeningSystems";

export default symbol;
