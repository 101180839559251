import React from 'react';
import { map, filter, debounce } from 'lodash';
import smoothscroll from 'smoothscroll-polyfill';
import { themeGet } from 'styled-system';
import { chevronLeftSolid, chevronRightSolid } from '@mlc/symbols';
import styled from 'styled-components';
import { mlcMediumGrey } from '@mlc/mlc-palette';

import Svg from '../Svg';
import { TabsButtonProps } from '../TabsButton/TabsButton';

import * as Styled from './TabsButtonGroup.style';

//enabling the polyfill
smoothscroll.polyfill();

export const AnimatedMover = styled.div`
	position: absolute;
	border-width: 0px;
	border-bottom: medium ${themeGet('colors.brandPrimaryLight')} solid;
	transition: all 0.3s ease-in-out;
	bottom: 0px;
`;

export type TabsButtonGroupProps = {
	/** Must be of type TabsButton. It can have one or more. */
	children: TabsButtonProps | TabsButtonProps[];
	/** This function is for the TabsButtonGroup, but will be overridden when the TabsButtonGroup is used inside Tabs component.  */
	handleActiveTabChange?: (any) => void;
	/** Size will be passed on to the TabsButton components. */
	size: 'medium' | 'small';
};

type TabsButtonGroupState = {
	activeTabIndex: number;
	activeTabKey?: number;
	showScrollButtons: boolean;
	rightChevronActive: boolean;
	leftChevronActive: boolean;
	activeTabDimensions: {
		left: number;
		width: number;
	};
};

const OUTLINE_WIDTH = 2;

class TabsButtonGroup extends React.Component<TabsButtonGroupProps, TabsButtonGroupState> {
	public static defaultProps = {
		size: 'medium',
	};

	private tabsButtonRefs: React.RefObject<any>[];
	private tabButtonContainerRef: any;
	private tabScrollContainerRef: any;
	private moverRef: any;

	constructor(props: TabsButtonGroupProps) {
		super(props);
		this.tabsButtonRefs = [];
		this.tabButtonContainerRef = React.createRef();
		this.tabScrollContainerRef = React.createRef();
		this.moverRef = React.createRef();
		this.state = {
			activeTabIndex: 0,
			showScrollButtons: false,
			rightChevronActive: true,
			leftChevronActive: false,
			activeTabDimensions: {
				left: 0,
				width: 0,
			},
		};
		this.handleScroll = debounce(this.handleScroll, 100);
		this.getActiveTabDimensions = debounce(this.getActiveTabDimensions.bind(this), 100);
	}

	componentDidMount() {
		const { tabsButtons } = this.getChildren();
		this.tabsButtonRefs = map(tabsButtons, () => React.createRef());

		this.setState({
			activeTabKey: tabsButtons[0].props.value,
			activeTabIndex: 0,
		});
		this.getActiveTabDimensions();
		window.addEventListener('resize', this.handleResize);
	}

	componentDidUpdate() {
		this.getActiveTabDimensions();
		this.checkIfScrollIsNeeded();
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.handleResize);
	}

	// remove transitions for resizing period to stop strange position/width adjustment animations on Safari on zoom
	handleResize = () => {
		const mover = this.moverRef.current;
		if (mover) {
			mover.style.transition = 'none';
		}
		this.getActiveTabDimensions();
		setTimeout(() => {
			if (mover) {
				mover.style.transition = 'all 0.3s ease-in-out';
			}
		}, 500);
	};

	getActiveTabDimensions = () => {
		const { activeTabIndex } = this.state;
		let width = 0;
		let left = 0;
		if (
			activeTabIndex > -1 &&
			!!this.tabsButtonRefs[activeTabIndex] &&
			!!this.tabsButtonRefs[activeTabIndex].current
		) {
			const activeElement = this.tabsButtonRefs[activeTabIndex].current;
			const clientRect = activeElement.getBoundingClientRect();

			// this is done to offset the margins on sides of the buttons.
			// If margins are not there the browser outline on focus will not be shown.
			width = clientRect.width + 4;
			left = activeElement.offsetLeft - 3;
		}
		this.setState((prevState) => {
			if (
				left !== prevState.activeTabDimensions.left ||
				width !== prevState.activeTabDimensions.width
			) {
				return {
					activeTabDimensions: {
						left: left,
						width: width,
					},
				};
			}
		});
	};

	getChildren = () => {
		const { children } = this.props;
		if (React.isValidElement(children)) {
			return {
				tabsButtons: [children],
			};
		}

		const tabsButtons = filter(children, (child) => {
			return child.type.tabsRole === 'tabsButton';
		});

		return {
			tabsButtons,
		};
	};

	openPreviousTab = () => {
		const { activeTabIndex } = this.state;
		const { tabsButtons } = this.getChildren();
		let nextTabIndex = activeTabIndex - 1;
		if (nextTabIndex === -1) {
			nextTabIndex = tabsButtons.length - 1;
		}
		this.handleActiveTabChange(nextTabIndex);
		this.focusOnElement(this.tabsButtonRefs[nextTabIndex].current);
	};

	openNextTab = () => {
		const { activeTabIndex } = this.state;
		const { tabsButtons } = this.getChildren();
		let nextTabIndex = activeTabIndex + 1;

		if (nextTabIndex === tabsButtons.length) {
			nextTabIndex = 0;
		}

		this.handleActiveTabChange(nextTabIndex);
		this.focusOnElement(this.tabsButtonRefs[nextTabIndex].current);
	};

	focusOnElement = (el) => {
		const nextButtonWidth = this.getElementWidth(el);
		const scrollContainerWidth = this.getElementWidth(this.tabScrollContainerRef);

		let nextScrollLeft = el.offsetLeft - OUTLINE_WIDTH;
		if (scrollContainerWidth > nextButtonWidth) {
			const centeredLeft = nextScrollLeft - (scrollContainerWidth - nextButtonWidth) / 2;
			nextScrollLeft = centeredLeft;
		}
		this.scrollTo(nextScrollLeft);
		el.focus({ preventScroll: true });
	};

	getElementWidth = (elementRef) => {
		if (elementRef && elementRef.getBoundingClientRect) {
			return elementRef.getBoundingClientRect().width;
		} else {
			return null;
		}
	};

	scrollTo = (scrollLeft) => {
		if (!this.tabScrollContainerRef) {
			return;
		}
		// @ts-ignore
		this.tabScrollContainerRef.scrollTo({
			top: 0,
			left: scrollLeft,
			behavior: 'smooth',
		});
	};

	checkIfScrollIsNeeded() {
		const scrollContainerWidth = this.getElementWidth(this.tabScrollContainerRef);
		const buttonContainerWidth = this.getElementWidth(this.tabButtonContainerRef);
		if (!scrollContainerWidth || !buttonContainerWidth) {
			return false;
		}
		if (scrollContainerWidth <= buttonContainerWidth && !this.state.showScrollButtons) {
			this.setState({ showScrollButtons: true });
		} else if (scrollContainerWidth > buttonContainerWidth && this.state.showScrollButtons) {
			this.setState({ showScrollButtons: false });
		}
	}

	handleActiveTabChange = (activeTabIndex) => {
		this.setState(
			{
				activeTabIndex,
			},
			() => {
				this.props.handleActiveTabChange &&
					this.props.handleActiveTabChange(activeTabIndex);
			}
		);
	};

	handleKeyPress = (event) => {
		const { tabsButtons } = this.getChildren();
		event.preventDefault();
		if (event.keyCode === 37) {
			// left arrow
			this.openPreviousTab();
		} else if (event.keyCode === 39) {
			// right arrow
			this.openNextTab();
		} else if (event.keyCode === 36) {
			event.preventDefault();
			// home button
			this.handleActiveTabChange(0);
		} else if (event.keyCode === 35) {
			// end button
			this.handleActiveTabChange(tabsButtons.length - 1);
		}
	};

	handleKeyDown = (event) => {
		// if home button or end button
		if (event.keyCode === 36 || event.keyCode === 35) {
			event.preventDefault();
			// if we do not stop propagation, the page will be scrolled to the bottom
			event.stopPropagation();
		}
	};

	handleScroll = () => {
		const tabsButtonContainerWidth = this.getElementWidth(this.tabButtonContainerRef);
		const tabsScrollContainerLeft = this.tabScrollContainerRef.scrollLeft;
		const scrollWidth = this.getElementWidth(this.tabScrollContainerRef);

		this.setState({
			leftChevronActive: tabsScrollContainerLeft > 0,
			rightChevronActive:
				scrollWidth + tabsScrollContainerLeft < tabsButtonContainerWidth - OUTLINE_WIDTH,
		});
	};

	handleScrollLeftClick = () => {
		const currentScrollLeft = this.tabScrollContainerRef.scrollLeft;
		const scrollableDivWidth = this.getElementWidth(this.tabScrollContainerRef);
		const activeTabButtonWidth = this.getElementWidth(
			this.tabsButtonRefs[this.state.activeTabIndex].current
		);

		const nextScrollLeft = currentScrollLeft - scrollableDivWidth + activeTabButtonWidth;

		this.scrollTo(nextScrollLeft);
	};

	handleScrollRightClick = () => {
		const currentScrollLeft = this.tabScrollContainerRef.scrollLeft;
		const scrollableDivWidth = this.getElementWidth(this.tabScrollContainerRef);
		const activeTabButtonWidth = this.getElementWidth(
			this.tabsButtonRefs[this.state.activeTabIndex].current
		);

		const nextScrollLeft = currentScrollLeft + scrollableDivWidth - activeTabButtonWidth;
		this.scrollTo(nextScrollLeft);
	};

	render() {
		const { children, size } = this.props;
		const { leftChevronActive, rightChevronActive, activeTabDimensions } = this.state;
		const { tabsButtons } = this.getChildren();

		if (!children || tabsButtons.length === 0 || this.tabsButtonRefs.length === 0) {
			return null;
		}
		const { left, width } = activeTabDimensions;

		return (
			<Styled.TabsButtonGroupContainer>
				<Styled.TabScrollContainer
					onScroll={this.handleScroll}
					ref={(ref) => {
						this.tabScrollContainerRef = ref;
					}}
				>
					<Styled.TabScroll
						onKeyDown={this.handleKeyDown}
						ref={(ref) => {
							this.tabButtonContainerRef = ref;
						}}
					>
						<div
							style={{
								minWidth: '100%',
								borderBottom: `thin ${mlcMediumGrey} solid`,
							}}
						>
							{tabsButtons.map((tab, index) => {
								return React.cloneElement(tab, {
									...this.state,
									ref: this.tabsButtonRefs[index],
									index,
									key: index,
									onKeyPress: (event) => {
										this.handleKeyPress(event);
									},
									handleActiveTabChange: (index) => {
										this.handleActiveTabChange(index);
										if (index !== this.state.activeTabIndex) {
											this.focusOnElement(this.tabsButtonRefs[index].current);
										}
									},
									size,
									onClick: (event) => {
										event.preventDefault();
									},
									moverRef: this.moverRef,
								});
							})}
						</div>
						<AnimatedMover
							className="Animated_mover"
							style={{
								width: `${width}px`,
								left: `${left}px`,
							}}
							ref={this.moverRef}
						/>
					</Styled.TabScroll>
				</Styled.TabScrollContainer>
				{this.state.showScrollButtons && leftChevronActive ? (
					<Styled.ScrollButtonLeft
						tabIndex="-1"
						aria-label="previous"
						disabled={!leftChevronActive}
						onClick={() => {
							if (leftChevronActive) {
								this.handleScrollLeftClick();
							}
							return;
						}}
					>
						<Svg symbol={chevronLeftSolid} height="13px" />
					</Styled.ScrollButtonLeft>
				) : null}
				{this.state.showScrollButtons && rightChevronActive ? (
					<Styled.ScrollButtonRight
						disabled={!rightChevronActive}
						aria-label="next"
						tabIndex="-1"
						onClick={() => {
							if (rightChevronActive) {
								this.handleScrollRightClick();
							}
							return;
						}}
					>
						<Svg symbol={chevronRightSolid} height="13px" />
					</Styled.ScrollButtonRight>
				) : null}
			</Styled.TabsButtonGroupContainer>
		);
	}
}

// @ts-ignore
TabsButtonGroup.tabsRole = 'tabsButtonGroup';

/**
 * component
 */
export default TabsButtonGroup;
