import React from 'react';
import { Row as BootstrapRow, RowProps } from 'styled-bootstrap-grid';

import { UitkBaseProps } from '../../typings';

export class Row extends React.PureComponent<RowProps & UitkBaseProps, {}> {
	render() {
		const { children, className, forwardedRef, ...restProps } = this.props;
		return (
			<BootstrapRow className={className} ref={forwardedRef} {...restProps}>
				{children}
			</BootstrapRow>
		);
	}
}

export default Row;
