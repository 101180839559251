import React, { HTMLAttributes } from 'react';
import styled from 'styled-components';
import classnames from 'classnames';

import { UitkBaseProps } from '../../typings';

import componentStyle from './PlaceholderImage.style';
type PlaceholderImageProps = {
	/** The height of the image */
	height?: string | number;
	/** Keyword(s) to make the image search with comma separated string */
	keyword?: string;
	/** The width of the image */
	width?: string | number;
};
export const PlaceholderImage = ({
	children,
	className,
	forwardedRef,
	...restProps
}: PlaceholderImageProps & UitkBaseProps & HTMLAttributes<HTMLDivElement>) => (
	<div {...restProps} className={classnames('PlaceholderImage', className)} ref={forwardedRef}>
		{children}
	</div>
);

PlaceholderImage.defaultProps = {};

export default styled(PlaceholderImage)`
	${componentStyle}
`;
