import React from 'react';
import styled from 'styled-components';
import classnames from 'classnames';

import { UitkBaseProps } from '../../typings';

import componentStyle from './ButtonGroup.style';

type ButtonGroupProps = {
	align?: 'left' | 'right';
};

export const ButtonGroup = ({
	children,
	className,
	align = 'right',
	forwardedRef,
	...restProps
}: UitkBaseProps & ButtonGroupProps) => {
	return (
		<div
			className={classnames(
				{
					ButtonGroup: true,
					[`ButtonGroup--${align}`]: align,
				},
				className
			)}
			ref={forwardedRef}
			{...restProps}
		>
			{children}
		</div>
	);
};

export default styled(ButtonGroup)`
	${componentStyle};
`;
