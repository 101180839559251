import React from 'react';
import classnames from 'classnames';
import styled from 'styled-components';

import ToggleGroup from '../ToggleGroup/ToggleGroup';
import passRef from '../../hocs/passRef';
import { UitkBaseProps } from '../../typings';

import componentStyle from './CheckboxGroup.style';

type CheckboxGroupProps = {
	name?: string;
	isInvalid?: boolean;
	autoFocus?: boolean;
	onChange: (...args: any[]) => any;
	value?: any[];
};

export const CheckboxGroup = ({
	children,
	className,
	...restProps
}: CheckboxGroupProps &
	UitkBaseProps &
	// TODO import ToggleGroup props instead of any
	any &
	React.HTMLAttributes<HTMLDivElement>) => {
	return (
		<ToggleGroup role="group" className={classnames('CheckboxGroup', className)} {...restProps}>
			{children}
		</ToggleGroup>
	);
};

CheckboxGroup.defaultProps = {
	isInvalid: false,
	multiple: true,
};
CheckboxGroup.isGroupedFormField = true;

export default styled(passRef(CheckboxGroup))`
	${componentStyle};
`;
