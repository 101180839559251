/**
 * backspace
 * Don't modify this file. It is generated by scripts/build.js and is
 * ignored by git. The source svg file is located in the '../src' directory.
 */
import BrowserSpriteSymbol from 'svg-baker-runtime/browser-symbol';

const symbol = new BrowserSpriteSymbol({
  "id": "symbol-backspace-4179244958",
  "use": "symbol-backspace-4179244958-usage",
  "viewBox": "0 0 640 512",
  "content": "<symbol viewBox=\"0 0 640 512\" id=\"symbol-backspace-4179244958\"><path d=\"M469.65 181.65l-11.31-11.31c-6.25-6.25-16.38-6.25-22.63 0L384 222.06l-51.72-51.72c-6.25-6.25-16.38-6.25-22.63 0l-11.31 11.31c-6.25 6.25-6.25 16.38 0 22.63L350.06 256l-51.72 51.72c-6.25 6.25-6.25 16.38 0 22.63l11.31 11.31c6.25 6.25 16.38 6.25 22.63 0L384 289.94l51.72 51.72c6.25 6.25 16.38 6.25 22.63 0l11.31-11.31c6.25-6.25 6.25-16.38 0-22.63L417.94 256l51.72-51.72c6.24-6.25 6.24-16.38-.01-22.63zM576 64H205.26C188.28 64 172 70.74 160 82.74L9.37 233.37c-12.5 12.5-12.5 32.76 0 45.25L160 429.25c12 12 28.28 18.75 45.25 18.75H576c35.35 0 64-28.65 64-64V128c0-35.35-28.65-64-64-64zm16 320c0 8.82-7.18 16-16 16H205.26c-4.27 0-8.29-1.66-11.31-4.69L54.63 256l139.31-139.31c3.02-3.02 7.04-4.69 11.31-4.69H576c8.82 0 16 7.18 16 16v256z\" /></symbol>"
});


symbol.name = "backspace";

export default symbol;
