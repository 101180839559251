import React from 'react';
import styled from 'styled-components';

import { UitkBaseProps, Space, Border, BoxShadow } from '../../typings';

import componentStyle from './Card.style';

type CardProps = {
	el?: string | ((...args: any[]) => any);
	background?: string;
	backgroundImage?: string;
	backgroundPosition?: string;
	backgroundRepeat?: string;
	backgroundSize?: string;
	alignItems?: string;
	alignSelf?: string;
	alignContent?: string;
	flex?: string;
	flexBasis?: string;
	flexDirection?: string;
	justifyItems?: string;
	justifySelf?: string;
	justifyContent?: string;
	order?: number;
	backgroundColor?: string;
	border?: Border;
	borderTop?: Border;
	borderLeft?: Border;
	borderRight?: Border;
	borderBottom?: Border;
	borderColor?: string;
	borderRadius?: string;
	boxShadow?: BoxShadow;
	color?: string;
	containerSize?: 'narrow' | 'normal' | 'thick';
	display?: string | object;
	height?: string | object;
	margin?: Space;
	marginBottom?: Space;
	marginLeft?: Space;
	marginRight?: Space;
	marginTop?: Space;
	overflow?: string;
	padding?: Space;
	paddingBottom?: Space;
	paddingLeft?: Space;
	paddingRight?: Space;
	paddingTop?: Space;
	width?: string | object;
	textAlign?: string | object;
};

export const Card = ({
	el: El = 'div',
	containerSize,
	backgroundColor,
	border,
	borderColor,
	borderLeft,
	borderTop,
	borderRight,
	borderBottom,
	borderRadius,
	boxShadow,
	color,
	display,
	height,
	margin,
	marginBottom,
	marginLeft,
	marginRight,
	marginTop,
	padding,
	paddingBottom,
	paddingLeft,
	paddingRight,
	paddingTop,
	width,
	textAlign,
	background,
	backgroundImage,
	backgroundPosition,
	backgroundRepeat,
	backgroundSize,
	alignItems,
	alignSelf,
	alignContent,
	flex,
	flexBasis,
	flexDirection,
	justifyItems,
	justifySelf,
	justifyContent,
	order,
	overflow,
	...restProps
}: CardProps & UitkBaseProps & React.HTMLAttributes<HTMLDivElement>) => {
	return <El {...restProps} />;
};

export default styled(Card)`
	${componentStyle};
`;
