import { css } from 'styled-components';
import { themeGet } from 'styled-system';

export default () => css`
	position: relative;
	.Password__input {
		display: flex;
		position: relative;
	}
	.Password__PasswordRule--show {
		background: ${themeGet('colors.mlcWhite')};
		display: block;
		margin-top: -10px;
		position: absolute;
		z-index: 99;
		width: 100%;
	}
	.Password__PasswordRule--hide {
		display: none;
	}
	.Password__icon {
		position: absolute;
		right: ${themeGet('space.2')}px;
		top: 40%;
		transform: translateY(-50%);
		z-index: 1;
		height: 20px;
		width: 20px;
		border: none;
		background: none;
	}
	.Password__icon--hide {
		display: none;
	}
	.Password__icon--show {
		display: flex;
	}
`;
