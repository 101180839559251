import BrowserSprite from 'svg-baker-runtime/browser-sprite';
import { generate } from 'shortid';
import domready from 'domready';

const spriteNodeId = `S${generate()}`;
const spriteGlobalVarName = `V${generate()}`;

// eslint-disable-next-line import/no-mutable-exports
const customSprite = (targetNode) => {
	let sprite;

	if (typeof window !== `undefined`) {
		const spriteExists = !!window[spriteGlobalVarName];
		if (spriteExists) {
			sprite = window[spriteGlobalVarName];
		} else {
			sprite = new BrowserSprite({ attrs: { id: spriteNodeId } });
			window[spriteGlobalVarName] = sprite;
		}

		const loadSprite = () => {
			if (targetNode) {
				const existing = targetNode.querySelector(`#${spriteNodeId}`);
				if (existing) {
					sprite.attach(existing);
				} else {
					sprite.mount(targetNode, true);
				}
			} else {
				/**
				 * Check if page already contains sprite node
				 * If found - attach to and reuse it's content
				 * If not - render and mount the new sprite
				 */
				const existing = document.getElementById(spriteNodeId);

				if (existing) {
					sprite.attach(existing);
				} else {
					sprite.mount(document.body, true);
				}
			}
		};

		if (document.body) {
			loadSprite();
		} else {
			domready(loadSprite);
		}
	}

	return sprite;
};

export default customSprite;
