import styled, { css } from 'styled-components';
import { media } from 'styled-bootstrap-grid';

const normalSpace = () => css`
	margin-top: 60px;
	margin-bottom: 60px;
	${media.xs`
		margin-top: 30px;
		margin-bottom: 30px;
	`};
`;

const squishSpace = () => css`
	margin-top: 30px;
	margin-bottom: 60px;
	${media.xs`
		margin-top: 0;
		margin-bottom: 30px;
	`};
`;

export const Page = styled.div`
	min-height: 50vh;
	${({ squish }) => (squish ? squishSpace : normalSpace)}
`;

export default Page;
