/**
 * chevronDownSolid
 * Don't modify this file. It is generated by scripts/build.js and is
 * ignored by git. The source svg file is located in the '../src' directory.
 */
import BrowserSpriteSymbol from 'svg-baker-runtime/browser-symbol';

const symbol = new BrowserSpriteSymbol({
  "id": "symbol-chevronDownSolid-604520106",
  "use": "symbol-chevronDownSolid-604520106-usage",
  "viewBox": "0 0 448 512",
  "content": "<symbol viewBox=\"0 0 448 512\" id=\"symbol-chevronDownSolid-604520106\"><path d=\"M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z\" /></symbol>"
});


symbol.name = "chevronDownSolid";

export default symbol;
