import { css } from 'styled-components';
import { themeGet } from 'styled-system';

import { typography, textLink } from '../../utils/styleFunctions';

export default () => css`
    ${themeGet('global.base')}
    ${themeGet('global.paragraph')}

    ${textLink}
    ${typography}
`;
