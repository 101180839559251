import React from 'react';
import classnames from 'classnames';
import styled from 'styled-components';

import InputWrapper from '../InputWrapper/InputWrapper';
import passRef from '../../hocs/passRef';
import BareFormattedInput from '../BareFormattedInput/BareFormattedInput';
import Svg, { mlcSymbol } from '../Svg';
import LoadingIndicator from '../LoadingIndicator/LoadingIndicator';
import { UitkBaseProps, FieldWrapperClone } from '../../typings';

import componentStyle, { positionedSymbolStyle } from './Input.style';
const PositionedIcon = styled.div`
	${positionedSymbolStyle};
`;

type InputProps = {
	formNoValidate?: boolean;
	inputProps?: { [x: string]: any };
	size?: 'normal' | 'large';
	isInvalid?: boolean;
	onChange?: (...args: any[]) => any;
	resize?: 'none' | 'both' | 'horizontal' | 'vertical';
	type?:
		| 'date'
		| 'datetime-local'
		| 'email'
		| 'month'
		| 'number'
		| 'password'
		| 'search'
		| 'tel'
		| 'text'
		| 'textarea'
		| 'time'
		| 'url'
		| 'week';
	/**
	 * when this is passed in, onChange returns both the formatted and unformatted value in the form of { value, unformattedValue }
	 * see one of the formatted inputs e.g. DateInput, NumberInput for usage examples
	 */
	format?: {
		guide?: boolean;
		keepCharPositions?: boolean;
		mask?:
			| any[]
			| ((...args: any[]) => any)
			| boolean
			| {
					mask?: any[] | ((...args: any[]) => any);
					pipe?: (...args: any[]) => any;
			  };
		pipe?: (...args: any[]) => any;
		placeholderChar?: string;
		showMask?: boolean;
	};
	/** regex used to unmask formatted input e.g. /\D+/g */
	unformatMask?: any;
	value?: string | number;
	defaultValue?: string;
	required?: boolean;
	symbol?: mlcSymbol;
	symbolPosition?: 'left' | 'right';
	isLoading?: boolean;
};
export const Input = ({
	className,
	forwardedRef,
	onChange,
	type = 'text',
	size,
	resize,
	isInvalid,
	inputProps = {},
	formNoValidate = true,
	defaultValue,
	value,
	required,
	format,
	unformatMask,
	ariaDescribedby,
	ariaLabelledby,
	symbol,
	symbolPosition,
	isLoading,
	...restProps
}: InputProps &
	UitkBaseProps &
	React.HTMLAttributes<HTMLInputElement> &
	React.HTMLAttributes<HTMLTextAreaElement> &
	FieldWrapperClone) => {
	const isTextarea = type === 'textarea';
	if (isTextarea && size !== 'normal') {
		console.warn('Textarea does not apply the "size" prop'); // eslint-disable-line no-console
	}
	// Only apply resize to textareas that are enabled
	const resizeStyle = isTextarea ? resize : 'none';
	const Element = isTextarea ? 'textarea' : format ? BareFormattedInput : 'input';
	const handleChange = (event, values?) => {
		!onChange
			? null
			: onChange(
					event,
					values || {
						value: event.target.value,
					}
			  );
	};
	const controlledComponentProps =
		value || onChange
			? {
					onChange: handleChange,
					value: value || '',
			  }
			: {
					defaultValue,
			  };
	const unformatProps = Element === BareFormattedInput ? { unformatMask } : null;
	return (
		<InputWrapper isInvalid={isInvalid} size={size || 'normal'}>
			<Element
				aria-invalid={isInvalid}
				aria-describedby={ariaDescribedby}
				aria-labelledby={ariaLabelledby}
				required={required}
				format={format}
				{...restProps}
				{...inputProps}
				{...unformatProps}
				className={classnames(
					'Input',
					`Input--${Element}`,
					// The size prop should not affect Textarea components
					!isTextarea && size && `Input--${size}Size`,
					isInvalid && 'Input--isInvalid',
					className
				)}
				formNoValidate={formNoValidate}
				ref={forwardedRef}
				style={{
					...inputProps.style,
					resize: resizeStyle,
				}}
				type={isTextarea ? null : type}
				{...controlledComponentProps}
			/>
			{(symbol || isLoading) && (
				<PositionedIcon
					symbolPosition={symbolPosition}
					marginTop={
						inputProps.style !== undefined && inputProps.style.height !== undefined
							? `${Number(inputProps.style.height.replace(/\D/g, '')) / 2}px`
							: '22px'
					}
				>
					{isLoading ? (
						<LoadingIndicator variant="inline" colorTheme="dark" />
					) : (
						symbol && <Svg symbol={symbol} />
					)}
				</PositionedIcon>
			)}
		</InputWrapper>
	);
};

Input.isFormField = true;

export default styled(passRef(Input))`
	${componentStyle}
`;
