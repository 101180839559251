import React, { Component } from 'react';
import { isUndefined } from 'lodash';
import raf from 'raf';
import parseUnit from 'parse-unit';
import classnames from 'classnames';
import styled from 'styled-components';

import { UitkBaseProps } from '../../typings';

import sprite from './sprite';
import componentStyle from './Svg.style';

export type mlcSymbol = {
	content?: string;
	id?: string;
	viewBox?: string;
	isMounted?: boolean;
};

export type SvgProps = {
	color?: string;
	width?: string | number;
	height?: string | number;
	/** @ignore */
	focusable?: boolean;
	/** See https://developer.mozilla.org/en-US/docs/Web/SVG/Attribute/preserveAspectRatio */
	/** @ignore */
	preserveAspectRatio?: string;
	/** @ignore */
	style?: object;
	/**
	 * A `BrowserSpriteSymbol` (see
	 * [svg-baker](https://github.com/kisenka/svg-baker)), or generate
	 * automatically via
	 * [svg-sprite-loader](https://github.com/kisenka/svg-sprite-loader)
	 */
	symbol?: mlcSymbol;
	/** @ignore */
	symbolProps?: object;
	title?: string;
	/** Keep the original size of the svg, preserves aspect ratio */
	originalSize?: boolean;
	border?: 'mlcBorderThin' | 'mlcBorderThick';
	borderColor?: string;
	borderRadius?: string;
	display?: string | object;
	/** Choose from 0, 1, 2, 3, 4, 5, 6, 7, 8, 9 or 'auto'. Objects are used for responsive. */
	margin?: number | string;
	object?;
	/** Choose from 0, 1, 2, 3, 4, 5, 6, 7, 8, 9 or 'auto'. Objects are used for responsive. */
	marginBottom?: number | string | object;
	/** Choose from 0, 1, 2, 3, 4, 5, 6, 7, 8, 9 or 'auto'. Objects are used for responsive. */
	marginLeft?: number | string | object;
	/** Choose from 0, 1, 2, 3, 4, 5, 6, 7, 8, 9 or 'auto'. Objects are used for responsive. */
	marginRight?: number | string | object;
	/** Choose from 0, 1, 2, 3, 4, 5, 6, 7, 8, 9 or 'auto'. Objects are used for responsive. */
	marginTop?: number | string | object;
	/** Choose from 0, 1, 2, 3, 4, 5, 6, 7, 8, 9. Objects are used for responsive. */
	padding?: number | object;
	/** Choose from 0, 1, 2, 3, 4, 5, 6, 7, 8, 9. Objects are used for responsive. */
	paddingBottom?: number | object;
	/** Choose from 0, 1, 2, 3, 4, 5, 6, 7, 8, 9. Objects are used for responsive. */
	paddingLeft?: number | object;
	/** Choose from 0, 1, 2, 3, 4, 5, 6, 7, 8, 9. Objects are used for responsive. */
	paddingRight?: number | object;
	/** Choose from 0, 1, 2, 3, 4, 5, 6, 7, 8, 9. Objects are used for responsive. */
	paddingTop?: number | object;
	textAlign?: string | object;
	/** @ignore */
	textLink?: boolean;
	verticalAlign?: string;
};

export class Svg extends Component<SvgProps & UitkBaseProps> {
	public ref: any;

	static defaultProps = {
		/** See http://stackoverflow.com/questions/18646111/disable-onfocus-event-for-svg-element */
		focusable: false,
		preserveAspectRatio: 'xMidYMid meet',
		symbolProps: {
			height: '100%',
			overflow: 'visible',
			width: '100%',
		},
		originalSize: false,
	};

	constructor(props) {
		super(props);
		this.ref = {};
		this.ref.current = null;
	}

	componentDidMount() {
		const currentNode = this.ref;
		const targetSpriteMountingNode =
			currentNode && currentNode.closest ? currentNode.closest('.Container') : undefined;
		const customSprite = sprite(targetSpriteMountingNode);
		if (customSprite && !this.props.symbol.isMounted) {
			raf(() => {
				customSprite.add(this.props.symbol);
			});
		}
	}

	handleRef(input) {
		const forwardedRef = this.props.forwardedRef;
		if (input) {
			// adapt ref types
			if (typeof forwardedRef === 'function') {
				this.props.forwardedRef(input); // excecute a callback ref
			} else if (typeof forwardedRef === 'object' && forwardedRef !== null) {
				forwardedRef.current = input; // assign an object ref
			}
			// assign own ref
			if (this.ref.current == null) {
				this.ref.current = input;
			}
		}
	}

	render() {
		const {
			border,
			borderColor,
			borderRadius,
			children,
			className,
			color,
			display,
			focusable,
			forwardedRef,
			height,
			margin,
			marginBottom,
			marginLeft,
			marginRight,
			marginTop,
			originalSize,
			padding,
			paddingBottom,
			paddingLeft,
			paddingRight,
			paddingTop,
			style,
			symbol,
			symbolProps,
			textAlign,
			textLink,
			title,
			width,
			verticalAlign,
			...restProps
		} = this.props;

		// Determine dimensions to use if width and/or height are not supplied and to
		// calculate aspect ratio
		const defaultWidth = originalSize ? parseInt(symbol.viewBox.split(' ')[2]) : 16;
		const defaultHeight = originalSize ? parseInt(symbol.viewBox.split(' ')[3]) : 16;
		const svgDimensions = { width, height };
		// If width is not supplied, calculate based on aspect ratio of symbol
		// and css unit of height
		if (height && isUndefined(width)) {
			const [heightValue, heightUnit = 'px'] = parseUnit(height);
			svgDimensions.width = `${(defaultWidth / defaultHeight) * heightValue}${heightUnit}`;
		}

		// If height is not supplied, calculate based on aspect ratio of symbol
		// and css unit of width
		if (width && isUndefined(height)) {
			const [widthValue, widthUnit = 'px'] = parseUnit(width);
			svgDimensions.height = `${(defaultHeight / defaultWidth) * widthValue}${widthUnit}`;
		}

		svgDimensions.height = isUndefined(svgDimensions.height)
			? defaultHeight
			: svgDimensions.height;
		svgDimensions.width = isUndefined(svgDimensions.width) ? defaultWidth : svgDimensions.width;
		const { id, viewBox } = symbol;
		return (
			<svg
				aria-label={title}
				overflow="visible"
				role={title ? 'img' : 'presentation'}
				viewBox={viewBox}
				{...restProps}
				className={classnames('Svg', className)}
				focusable={focusable.toString()}
				ref={this.handleRef.bind(this)}
				height={height}
				style={{
					color: color,
					...svgDimensions,
					...style,
				}}
				width={width}
			>
				{title && <title>{title}</title>}
				{
					// `href` is preferred over `xlink:href`
					// (see https://developer.mozilla.org/en-US/docs/Web/SVG/Attribute/xlink:href)
					// but Safari does not yet support `href`
				}
				<use href={`#${id}`} xlinkHref={`#${id}`} {...symbolProps}>
					{children}
				</use>
				{
					// Force whitespace around <use> element to fix Safari tab/focus bug
					// (see http://jsbin.com/tuxaseyugo/1/edit?html,output)
					' '
				}
			</svg>
		);
	}
}

export default styled(Svg)`
	${componentStyle}
`;
