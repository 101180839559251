import React from 'react';
import styled from 'styled-components';
import { noop } from 'lodash';
import values from 'object.values';

import Input from '../Input';
import passRef from '../../hocs/passRef';
import { UitkBaseProps } from '../../typings';

import componentStyle from './Password.style';
import PasswordMaskToggler from './components/PasswordMaskToggler/PasswordMaskToggler';
import PasswordCriteria, {
	passwordValidator,
} from './components/PasswordCriteria/PasswordCriteria';

type PasswordProps = {
	isInvalid?: boolean;
	value?: string;
	/**
	 * Pass this function to execute the onBlur render prop alongwith the default actions of Password field
	 */
	onBlur?: (...args: any[]) => any;
	/**
	 * Pass this function to execute the onChange render prop alongwith the default actions of Password field
	 */
	onChange?: (...args: any[]) => any;
	/** @ignore */
	ariaDescribedby?: string;
	/** @ignore */
	ariaLabelledby?: string;
};
type PasswordState = {
	inputType: string;
	value: string;
	showPassword: boolean;
	showCriteria: boolean;
};

type Props = PasswordProps & UitkBaseProps;

export class Password extends React.Component<Props, PasswordState> {
	state = {
		inputType: 'password',
		value: '',
		showPassword: false,
		showCriteria: false,
	};
	showPasswordRef: React.RefObject<HTMLButtonElement>;
	hidePasswordRef: React.RefObject<HTMLButtonElement>;
	constructor(props) {
		super(props);
		this.onFocus = this.onFocus.bind(this);
		this.onBlur = this.onBlur.bind(this);
		this.onChange = this.onChange.bind(this);
		this.onTogglePasswordMask = this.onTogglePasswordMask.bind(this);
		this.showPasswordRef = React.createRef();
		this.hidePasswordRef = React.createRef();
	}
	onFocus = () => {
		this.setState({ showCriteria: true });
	};
	onBlur = (event) => {
		this.setState({ showCriteria: false });
		this.props.onBlur(event);
	};
	onChange = (event) => {
		const password = event.target.value;
		this.setState({ value: password });
		this.props.onChange(event, this.isPasswordCriteriaMet(password));
	};
	onTogglePasswordMask = () => {
		this.setState((state) => {
			const newState = !state.showPassword;
			return {
				showPassword: newState,
				inputType: newState ? 'text' : 'password',
			};
		}, this.updateToggleFocus);
	};
	updateToggleFocus = () => {
		if (this.state.showPassword) {
			this.hidePasswordRef.current.focus();
		} else {
			this.showPasswordRef.current.focus();
		}
	};
	/**
	 * Run the password validations and check if it meets all the
	 * criteria.
	 */

	isPasswordCriteriaMet = (password) => {
		if (!Object.values) {
			values.shim();
		}

		return Object.values(passwordValidator(password)).reduce(
			(result, value) => result && value,
			true
		);
	};
	render() {
		const {
			className,
			ariaDescribedby,
			ariaLabelledby,
			forwardedRef,
			isInvalid,
			...restProps
		} = this.props;
		return (
			<div className={className}>
				<div className="Password__input">
					<Input
						{...restProps}
						size="normal"
						type={this.state.inputType}
						value={this.state.value}
						onFocus={this.onFocus}
						onBlur={this.onBlur}
						onChange={this.onChange}
						isInvalid={isInvalid}
						ref={forwardedRef}
						ariaLabelledby={ariaLabelledby}
						ariaDescribedby={ariaDescribedby + ' passwordCriteria'}
					/>
					<PasswordMaskToggler
						showPassword={this.state.showPassword}
						onToggle={this.onTogglePasswordMask}
						showRef={this.showPasswordRef}
						hideRef={this.hidePasswordRef}
					/>
				</div>
				<PasswordCriteria show={this.state.showCriteria} value={this.state.value} />
			</div>
		);
	}

	static defaultProps = {
		value: '',
		onBlur: noop,
		onChange: noop,
	};

	static isFormField = true;
}

export default styled(passRef(Password))`
	${componentStyle}
`;
