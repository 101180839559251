import React, { HTMLAttributes } from 'react';
import styled from 'styled-components';

import { UitkBaseProps } from '../../typings';

import componentStyle from './Paragraph.style';
type ParagraphProps = {
	/**
	 * Choose from our design system sizes:
	 * disclaimer, standard, lead
	 */
	fontSize?: ('disclaimer' | 'standard' | 'lead') | object;
	/**
	 * Weight for the Text. Choice from our design system weights
	 */
	fontWeight?: 'regular' | 'semibold';
	backgroundColor?: string;
	border?: 'mlcBorderThin' | 'mlcBorderThick';
	borderColor?: string;
	borderRadius?: string;
	color?: string;
	display?: string | object;
	/** Choose from 0, 1, 2, 3, 4, 5, 6, 7, 8, 9 or 'auto'. Objects are used for responsive. */
	margin?: number | string | object;
	/** Choose from 0, 1, 2, 3, 4, 5, 6, 7, 8, 9 or 'auto'. Objects are used for responsive. */
	marginBottom?: number | string | object;
	/** Choose from 0, 1, 2, 3, 4, 5, 6, 7, 8, 9 or 'auto'. Objects are used for responsive. */
	marginLeft?: number | string | object;
	/** Choose from 0, 1, 2, 3, 4, 5, 6, 7, 8, 9 or 'auto'. Objects are used for responsive. */
	marginRight?: number | string | object;
	/** Choose from 0, 1, 2, 3, 4, 5, 6, 7, 8, 9 or 'auto'. Objects are used for responsive. */
	marginTop?: number | string | object;
	/** Choose from 0, 1, 2, 3, 4, 5, 6, 7, 8, 9. Objects are used for responsive. */
	padding?: number | object;
	/** Choose from 0, 1, 2, 3, 4, 5, 6, 7, 8, 9. Objects are used for responsive. */
	paddingBottom?: number | object;
	/** Choose from 0, 1, 2, 3, 4, 5, 6, 7, 8, 9. Objects are used for responsive. */
	paddingLeft?: number | object;
	/** Choose from 0, 1, 2, 3, 4, 5, 6, 7, 8, 9. Objects are used for responsive. */
	paddingRight?: number | object;
	/** Choose from 0, 1, 2, 3, 4, 5, 6, 7, 8, 9. Objects are used for responsive. */
	paddingTop?: number | object;
	textAlign?: string | object;
	/** @ignore */
	textLink?: boolean;
};
export const Paragraph = ({
	children,
	className,
	backgroundColor,
	border,
	borderColor,
	borderRadius,
	color,
	display,
	fontSize,
	fontWeight,
	margin,
	marginBottom,
	marginLeft,
	marginRight,
	marginTop,
	padding,
	paddingBottom,
	paddingLeft,
	paddingRight,
	paddingTop,
	textAlign,
	textLink,
	...restProps
}: ParagraphProps & UitkBaseProps & HTMLAttributes<HTMLParagraphElement>) => (
	<p className={className} {...restProps}>
		{children}
	</p>
);

export default styled(Paragraph)`
	${componentStyle};
`;
