import { css } from 'styled-components';
import { themeGet } from 'styled-system';
import { media } from 'styled-bootstrap-grid';

const fakeMargin = (color) => css`
	border-left: 5px solid ${color};
	border-right: 5px solid ${color};
`;

export default () => css`
	${themeGet('global.base')}

	.DayPickerInput__calendar {
		position: absolute;
		right: 10px;
		top: 22px;
		transform:translateY(-50%);
		z-index: 1;
		cursor: pointer;
		pointer-events: none;
		line-height: 0;

		.DayPickerInput-Overlay__banner__calendar {
			visibility: visible;
			position: relative;
		}
	}

	.DatePickerInput__icon {
		color: ${themeGet('colors.mlcVeryDarkGrey')};
		position: absolute;
		right: 0;
		padding: 15px 30px;
	}
	/* OVERLAY */
	.DayPickerInput-OverlayWrapper {
		position: relative;
		margin-top: 10px;
	}

	.DayPickerInput-Overlay {
		background-color: ${themeGet('colors.mlcWhite')};
		box-shadow: 0 0 6px 0 ${themeGet('colors.mlcWallaby')};
		position: absolute;
		z-index: 2;

		/* center on mobile */
		left: 50%;
		transform: translate(-50%);

		${media.md`
			left: 0;
			transform: none;
		`};

		/* TRIANGLE */
		.DayPickerInput-Overlay__triangle {
			box-shadow: 0 0 6px 0 ${themeGet('colors.mlcWallaby')};
			background-color: ${themeGet('colors.mlcWhite')};
			position: absolute;
			height: 20px;
			width: 20px;
			left: 0;
			right: 0;
			top: -5px;
			margin-right: auto;
			margin-left: auto;
			transform: rotate(45deg);
			z-index: -1;
		}

		/* BANNER */
		.DayPickerInput-Overlay__banner {
			padding: 9px;
			text-align: center;
			background-color: ${themeGet('colors.mlcWhite')};
			display: flex;
			align-items: center;
			justify-content: center;

			.DayPickerInput-Overlay__banner__close {
				position: absolute;
				right: 10px;
				cursor: pointer;
			}
		}

		/* KEY */
		.DayPickerInput-Overlay__key {
			padding: 20px;

			.DayPickerInput-Overlay__key__today__icon,
			.DayPickerInput-Overlay__key__selected__icon {
				display: inline-block;
				margin-right: 10px;
				width: 16px;
				height: 16px;
				vertical-align: text-top;
			}

			.DayPickerInput-Overlay__key__today__icon {
				border-radius: 50%;
				border: thin solid ${themeGet('colors.mlcWallaby')};
			}

			.DayPickerInput-Overlay__key__selected {
				float: right;
				.DayPickerInput-Overlay__key__selected__icon {
					background-color: ${themeGet('colors.brandSecondaryLight')};
				}
			}
		}
	}

	/* NAVBAR */
	.DayPicker-NavBar {
		.DayPicker-NavBar__left, .DayPicker-NavBar__right {
			color: ${themeGet('colors.mlcWhite')};
			display: inline;
			position: absolute;
			cursor: pointer;
			top: 22px;
		}
		.DayPicker-NavBar__left {
			left: 10px;

		}
		.DayPicker-NavBar__right {
			right: 10px;
		}
	}

	/* CALENDAR */
	.DayPicker {
		display: inline-block;
		box-sizing: border-box;

		.DayPicker-wrapper {
			position: relative;
			flex-direction: row;
			user-select: none;

			.DayPicker-Months {
				display: flex;
				flex-wrap: wrap;
				justify-content: center;
				.DayPicker-Month {
					display: table;
					table-layout: fixed;
					user-select: none;
					width: 320px;
				}

				.DayPicker-Caption {
					background-color: ${themeGet('colors.brandPrimaryLight')};
					${fakeMargin(themeGet('colors.brandPrimaryLight'))}

					color: ${themeGet('colors.mlcWhite')};
					display: table-caption;
					font-weight: bold;
					vertical-align: middle;
					text-align: center;

					> div {
						padding: 22px;
					}
				}

				.DayPicker-Weekdays {
					background-color: ${themeGet('colors.brandPrimaryLight')};
					${fakeMargin(themeGet('colors.brandPrimaryLight'))}

					color: ${themeGet('colors.mlcWhite')};
					display: table-header-group;
					.DayPicker-WeekdaysRow {
						display: table-row;
					}
					.DayPicker-Weekday {
						vertical-align: middle;
						display: table-cell;
						text-align: center;
						padding: 10px;
					}
					.DayPicker-Weekday abbr[title] {
						text-decoration: none;
					}
				}

				.DayPicker-Body {
					display: table-row-group;
					${fakeMargin(themeGet('colors.mlcWhite'))}

					.DayPicker-Week {
					display: table-row;
					border-bottom: thin solid ${themeGet('colors.mlcDove')};
					}
					.DayPicker-Day {
						width: ${(1 / 7) * 100}%;
						font-weight: bold;
						padding: 10px;
						display: table-cell;
						overflow: hidden;
						text-align: center;
						vertical-align: middle;
						cursor: pointer;
					}
					.DayPicker-Day:hover {
						text-decoration: underline;
						/* TODO: Add this color to palette if it is official. It is 20% of mlcAqua */
						background-color: #CCE6E8;
					}
					.DayPicker-Footer {
						padding-top: 5px;
					}

					/* MODIFIERS */
					.DayPicker-Day--today {
						background-color: ${themeGet('colors.mlcWhite')};
						border: thin solid ${themeGet('colors.mlcWallaby')};
						-moz-border-radius: 50%;
						-webkit-border-radius: 50%;
						border-radius: 50%;
						color: ${themeGet('colors.mlcVeryDarkGrey')};
						overflow: hidden;
					}

					.DayPicker-Day--outside {
						font-weight: normal;
						cursor: default;
					}

					.DayPicker-Day--isInvalid {
						background-color: ${themeGet('colors.mlcRed')};
						color: ${themeGet('colors.mlcWhite')};
					}

					.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
						background-color: ${themeGet('colors.brandSecondaryLight')};
						color: ${themeGet('colors.mlcWhite')};
						position: relative;
						&.DayPicker-Day--today {
							box-shadow: none;
							border-radius: 0;
						}
					}
				}
			}
		}
	}
`;
