import { css } from 'styled-components';
import { themeGet } from 'styled-system';

export default ({ help, isInvalid, error }) => css`
	${themeGet('global.base')}
	border: none;
	padding: 0;
	margin: 0;
	margin-bottom: ${!!help || (isInvalid && !!error)
		? themeGet('space.6')
		: themeGet('space.4')}px;

	@media print {
		page-break-inside: avoid;
	}

	.FieldWrapper__body {
		/* In most cases use of descendant selectors that affect child components
		is avoided. Here is an exception where we want to ensure the child
		component is laid out correctly */
		& > :first-child {
			width: 100%;
		}

		.FieldWrapper__error {
			color: ${themeGet('colors.mlcError')};
			.FieldWrapper__error__icon-errormsg {
				margin-right: ${themeGet('space.1')}px;
				vertical-align: top;
			}
		}

		.FieldWrapper__help,
		.FieldWrapper__error {
			margin: 0 0 ${themeGet('space.2')}px 0;
			font-size: ${themeGet('fontSizes.disclaimer')}px;
			line-height: ${themeGet('lineHeights.disclaimer')};
			&:focus {
				outline-style: none;
			}
		}
	}

	.FieldWrapper__required-label,
	.FieldWrapper__sub-label {
		color: ${themeGet('colors.mlcDarkGrey')};
	}
`;
