import { css } from 'styled-components';
import { verticalAlign } from 'styled-system';

import { typography, textLink } from '../../utils/styleFunctions';

export default css`
	display: inline-block;
	fill: currentColor;
	max-width: none;
	box-sizing: border-box;
	margin: 0;
	&.Svg {
		overflow: visible;
	}
	${textLink}
	${verticalAlign}
	${typography}
`;
