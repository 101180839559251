import React from 'react';
import classnames from 'classnames';
import styled from 'styled-components';

import ToggleGroup, { ToggleGroupProps } from '../ToggleGroup/ToggleGroup';
import passRef from '../../hocs/passRef';
import { UitkBaseProps } from '../../typings';

import componentStyle from './RadioGroup.style';

type RadioGroupProps = {
	name?: string;
	isInvalid?: boolean;
	autoFocus?: boolean;
	onChange: (...args: any[]) => any;
	stacked?: boolean;
	value?: boolean | number | string;
};

export const RadioGroup = ({
	children,
	className,
	stacked = false,
	isInvalid = false,
	...restProps
}: RadioGroupProps & UitkBaseProps & ToggleGroupProps) => {
	return (
		<ToggleGroup
			role="radiogroup"
			className={classnames(
				{
					RadioGroup: true,
					'RadioGroup--stacked': stacked,
				},
				className
			)}
			multiple={false}
			isInvalid={isInvalid}
			{...restProps}
		>
			{children}
		</ToggleGroup>
	);
};

RadioGroup.isGroupedFormField = true;

export default styled(passRef(RadioGroup))`
	${componentStyle};
`;
