import { css } from 'styled-components';
import { themeGet } from 'styled-system';
const styles = () => css`
	font-size: ${themeGet('fontSizes.xsmall')}px;
	line-height: ${themeGet('lineHeights.xsmall')};

	color: ${themeGet('colors.mlcVeryDarkGrey')};
	background-color: ${themeGet('colors.mlcShark')};
	padding: 20px;
	margin-bottom: 30px;
	display: flex;

	p,
	h1,
	h2,
	h3,
	h4,
	h5 {
		color: ${themeGet('colors.mlcVeryDarkGrey')};
		font-family: ${themeGet('fonts.fontFamily')};
		font-size: ${themeGet('fontSizes.xsmall')}px;
		line-height: ${themeGet('lineHeights.xsmall')};
		margin-top: 0;
	}

	.InformationBox__Wrapper {
		& > :first-child {
			margin-top: 0;
		}
		& > :last-child {
			margin-bottom: 0;
		}
		* > :last-child {
			margin-bottom: 0;
		}
	}

	.InformationBox__info {
		color: ${themeGet('colors.brandPrimaryLight')};
		margin-right: 15px;
	}
`;

export default styles;
