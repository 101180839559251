import React from 'react';
import styled from 'styled-components';

import { UitkBaseProps } from '../../typings';

import componentStyle from './Divider.style';
type DividerProps = {
	color?: string;
	marginBottom?: number | string | object;
	marginTop?: number | string | object;
	width?: string | object;
};
export const Divider = ({
	color,
	marginBottom,
	marginTop,
	width,
	...restProps
}: DividerProps & UitkBaseProps & React.HTMLAttributes<HTMLDivElement>) => {
	return <div role="presentation" {...restProps} />;
};

export default styled(Divider)`
	${componentStyle};
`;
