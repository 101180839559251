import React, { createContext, HTMLAttributes } from 'react';
import ReactModal from 'react-modal';
import styled from 'styled-components';
import classnames from 'classnames';
import { timesCircleSolid } from '@mlc/symbols';

import { UitkBaseProps } from '../../typings';
import Divider from '../Divider/Divider';

import ModalHeader from './components/ModalHeader/ModalHeader';
import ModalFooter from './components/ModalFooter/ModalFooter';
import componentStyle, { ModalContentContainer } from './Modal.style';

export const ModalContext = createContext(false);
const ReactModalAdapter = ({ className, ...props }) => (
	<ReactModal
		portalClassName={`${className}__portal`}
		className={`${className}__reactModal`}
		overlayClassName={`${className}__overlay`}
		bodyOpenClassName={`${className}__bodyOpen`}
		htmlOpenClassName={`${className}__htmlOpen`}
		{...props}
	/>
);
type ModalProps = {
	/**
	 * Content is center aligned, unless otherwise stated
	 */
	align?: 'left' | 'right' | 'center';
	/**
	 * Additional aria attributes (optional).
	 */
	aria?: object;
	/**
	 * If set to true, the footer buttons won't be rendered.
	 */
	hideFooterButtons?: boolean;
	/**
	 * Array of button events and content, for either one or two buttons
	 * Can also specify button type if there are two buttons - primary, secondary or tertiary
	 */
	footerButtons?: any[];
	/** Number indicating the milliseconds to wait before closing the modal. */
	closeTimeoutMS?: number;
	/** The theme for the modal. */
	colorTheme?: 'mlcOrange' | 'mlcAqua' | 'mlcRubine' | 'mlcWhite' | 'brandColor';
	/** String indicating how the content container should be announced to screenreaders */
	contentLabel?: string;
	/**
	 * Sets the state of the modal. Should be controlled by your application state.
	 */
	isOpen?: boolean;
	/** Function that will be run after the modal has opened. */
	onAfterOpen?: (...args: any[]) => any;
	/**
	 * Function that will be run when the modal is requested to be closed
	 * (either by clicking on overlay or pressing ESC)
	 *
	 * *Note*: It is not called if isOpen is changed by other means.
	 */
	onRequestClose: (...args: any[]) => any;
	/**
	 * Function that will be called to get the parent element that the modal will be attached to.
	 */
	parentSelector?: (...args: any[]) => any;
	/**
	 * String indicating the role of the modal, allowing the 'dialog' role to be applied if desired.
	 */
	role?: string;
	/**
	 * Boolean indicating if the modal should be focused after render
	 */
	shouldFocusAfterRender?: boolean;
	/** Boolean indicating if the overlay should close the modal */
	shouldCloseOnOverlayClick?: boolean;
	/**
	 * Content of the modal header
	 */
	title: string;
	/**
	 * Set the CSS `z-index` of the portal element. If possible, as per docs above,
	 * render your application in a Container component rather than use this
	 * prop. If you find yourself in a z-index war with a badly behaved
	 * application or component then use with discretion
	 */
	zIndex?: number;
	/**
	 * Icon that will be displayed in header
	 */
	headerIcon?: object;
	/**
	 * Close icon that will be displayed on the top right
	 */
	symbol: {
		content?: string;
		id?: string;
		viewBox?: string;
		isMounted?: boolean;
	};
	/**
	 * String indicating whether modal will use wide or standard width.
	 */
	widthType?: 'wide' | 'standard';
	/**
	 * Custom props for the close icon
	 */
	symbolProps?: object;
};
export const Modal = ({
	aria,
	hideFooterButtons,
	footerButtons,
	children,
	className,
	closeTimeoutMS,
	colorTheme,
	contentLabel,
	forwardedRef,
	isOpen,
	onAfterOpen,
	onRequestClose,
	parentSelector,
	role,
	shouldCloseOnOverlayClick,
	shouldFocusAfterRender,
	title,
	headerIcon,
	symbol,
	symbolProps,
	zIndex,
	align,
	widthType,
	...restProps
}: UitkBaseProps & ModalProps & HTMLAttributes<HTMLDivElement>) => {
	const modalHeaderProps = {
		headerIcon,
		symbol,
		symbolProps,
		onRequestClose,
		colorTheme,
	};
	const modalFooterProps = {
		hideFooterButtons,
		footerButtons,
		onRequestClose,
		colorTheme,
	};

	const containerClass = colorTheme === 'mlcWhite' ? '--mlcWhite' : '';
	return (
		<ModalContext.Provider value={true}>
			<ReactModalAdapter
				className={classnames(widthType, className)}
				aria={aria}
				closeTimeoutMS={closeTimeoutMS}
				colorTheme={colorTheme}
				contentLabel={contentLabel || title}
				isOpen={isOpen}
				onAfterOpen={onAfterOpen}
				onRequestClose={onRequestClose}
				parentSelector={parentSelector}
				ref={forwardedRef}
				role={role}
				shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
				shouldFocusAfterRender={shouldFocusAfterRender}
			>
				<div {...restProps} className={classnames('modal__body', className)}>
					<ModalHeader {...modalHeaderProps}>{title}</ModalHeader>
					<ModalContentContainer
						className={classnames(`modalContentContainer${containerClass}`)}
						align={align}
					>
						{children}
					</ModalContentContainer>
					{!hideFooterButtons && <Divider color="mlcAlmostWhite" />}
					<ModalFooter {...modalFooterProps} />
				</div>
			</ReactModalAdapter>
		</ModalContext.Provider>
	);
};
Modal.defaultProps = {
	isOpen: false,
	role: 'dialog',
	zIndex: 1,
	colorTheme: 'brandColor',
	hideFooterButtons: false,
	symbol: timesCircleSolid,
	align: 'center',
	widthType: 'standard',
};
Modal.setAppElement = ReactModal.setAppElement;

export default styled(Modal)`
	${componentStyle}
`;
