import { css } from 'styled-components';
import { themeGet } from 'styled-system';

export default (props) => {
	return css`
		tr {
			:nth-child(even) {
				background-color: ${props.isZebraStripe ? themeGet('colors.mlcAlmostWhite') : ''};
			}

			:nth-child(odd) {
				background-color: ${props.isZebraStripe ? themeGet('colors.mlcWhite') : ''};
			}
		}
	`;
};
