import { v4 as uuIdv4 } from 'uuid';
import { DafEnvironments, getEnvironmentConfig, SuccessResponse, DafSdkError } from '@nab/daf-common';
import { generateCodesAndState } from './helpers';

export interface IdmRequestOAuthAuthorizationConfig {
    client_id: string;
    response_type?: 'code' | 'code id_token';
    scope: string;
    environment?: DafEnvironments;
    redirect_uri: string;
}

export interface IdmRequestOAuthZGeneratedValues {
    code_verifier: string;
    state: string;
    code_challenge: string;
}

export type IdmRequestOAuthAuthorizationResponse = SuccessResponse<{
    url: string;
    codeVerifier: string;
    codeChallenge: string;
    state: string;
}>;

const DEFAULT_CODE_CHALLENGE_METHOD = 'S256';

export default function idmRequestOAuthAuthorization(config: IdmRequestOAuthAuthorizationConfig): Promise<IdmRequestOAuthAuthorizationResponse> {
    try {
        const { environment = DafEnvironments.PROD } = config;
        const { kongStaffGatewayBaseUrl } = getEnvironmentConfig(environment);
        const url = new URL('/v1/idm/oauth/authorise', kongStaffGatewayBaseUrl);
        const codesAndState = generateCodesAndState();

        Object.keys(config).forEach((optionKey: keyof IdmRequestOAuthAuthorizationConfig) => {
            if (optionKey === 'environment') {
                return;
            }
            url.searchParams.set(optionKey, config[optionKey]);
        });

        Object.keys(codesAndState).forEach((key: keyof IdmRequestOAuthZGeneratedValues) => {
            url.searchParams.set(key, codesAndState[key]);
        });

        url.searchParams.set('code_challenge_method', DEFAULT_CODE_CHALLENGE_METHOD);

        if (url.searchParams.get('scope').includes('openid')) {
            url.searchParams.set('nonce', uuIdv4());
        }

        return Promise.resolve({
            data: {
                url: url.toString(),
                codeChallenge: codesAndState.code_challenge,
                codeVerifier: codesAndState.code_verifier,
                state: codesAndState.state,
            },
        });
    } catch (e) {
        return Promise.reject(DafSdkError.GENERAL_ERROR);
    }
}
