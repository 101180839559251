import React from 'react';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import MomentLocaleUtils from 'react-day-picker/moment';
import { DayPickerInputProps, Modifier } from 'react-day-picker';
import styled from 'styled-components';
import classnames from 'classnames';
import { chevronLeft, chevronRight, times, calendarAlt } from '@mlc/symbols';
import 'moment/locale/en-au';

import Text from '../Text/Text';
import Svg from '../Svg/Svg';
import DateInput from '../DateInput/DateInput';
import passRef from '../../hocs/passRef';
import { UitkBaseProps } from '../../typings';

import componentStyle from './DatePicker.style';

const { parseDate, formatDate } = MomentLocaleUtils;

/**
 * CustomOverlay extends the default DayPicker to include the top triangle,
 * close button, instructional banner and footer legend
 */
const CustomOverlay = ({ classNames, children, input, selectedDay, ...props }) => {
	return (
		<div className={classNames.overlayWrapper} {...props}>
			<div className={classNames.overlay}>
				<span className="DayPickerInput-Overlay__triangle" />
				<div className="DayPickerInput-Overlay__banner">
					<Text>Pick a date</Text>
					<Svg
						className="DayPickerInput-Overlay__banner__close"
						symbol={times}
						onClick={() => input.blur()}
					/>
				</div>
				{children}
				<div className="DayPickerInput-Overlay__key">
					<span className="DayPickerInput-Overlay__key__today">
						<span className="DayPickerInput-Overlay__key__today__icon"> </span>
						<Text>{"Today's Date"}</Text>
					</span>
					<span className="DayPickerInput-Overlay__key__selected">
						<span className="DayPickerInput-Overlay__key__selected__icon"> </span>
						<Text>Selected Date</Text>
					</span>
				</div>
			</div>
		</div>
	);
};

/**
 * CustomNavBar extends the default DayPicker to add custom icons as the month controls
 */
const CustomNavBar = ({
	nextMonth,
	previousMonth,
	onPreviousClick,
	onNextClick,
	className,
	localeUtils,
}) => {
	const months = localeUtils.getMonths();
	const prev = months[previousMonth.getMonth()];
	const next = months[nextMonth.getMonth()];
	return (
		<div className={className}>
			<Svg
				className="DayPicker-NavBar__left"
				symbol={chevronLeft}
				onClick={() => onPreviousClick()}
			>
				{prev.slice(0, 3)}{' '}
			</Svg>
			<Svg
				className="DayPicker-NavBar__right"
				symbol={chevronRight}
				onClick={() => onNextClick()}
			>
				{next.slice(0, 3)}{' '}
			</Svg>
		</div>
	);
};

type DatePickerProps = {
	value?: any | string;
	onDayChange?: (...args: any[]) => any;
	selectedDays?: Modifier;
	invalidDays?: Modifier;
	fromMonth?: Date;
	toMonth?: Date;
	month?: Date;
};

export const DatePicker = ({
	className,
	onDayChange,
	selectedDays,
	invalidDays,
	fromMonth,
	toMonth,
	month,
	forwardedRef,
	value,
	...restProps
}: UitkBaseProps &
	React.HTMLAttributes<HTMLInputElement> &
	DayPickerInputProps &
	DatePickerProps) => {
	const modifiers = {
		isInvalid: invalidDays,
	};
	return (
		<div className={classnames('DatePicker', className)}>
			<DayPickerInput
				ref={(input: any) => {
					if (forwardedRef && input) {
						forwardedRef.current = input.input;
					}
				}}
				value={value}
				component={DateInput}
				inputProps={{
					autoComplete: 'off',
					symbol: calendarAlt,
					...restProps,
				}}
				onDayChange={onDayChange}
				overlayComponent={CustomOverlay}
				placeholder="DD/MM/YYYY"
				formatDate={formatDate}
				/** Make time consistent when date is typed in the input field */
				parseDate={(date) => {
					const newDate: Date = parseDate(date, 'DD/MM/YYYY');
					if (newDate) newDate.setHours(12, 0, 0);
					return newDate;
				}}
				dayPickerProps={{
					modifiers: modifiers,
					navbarElement: CustomNavBar,
					showOutsideDays: true,
					selectedDays: selectedDays,
					locale: 'en-au',
					localeUtils: MomentLocaleUtils,
					fromMonth: fromMonth,
					toMonth: toMonth,
					month: month,
				}}
			/>
		</div>
	);
};

DatePicker.isFormField = true;

export default styled(passRef(DatePicker))`
	${componentStyle};
`;
