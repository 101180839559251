import React from 'react';
import classnames from 'classnames';
import styled from 'styled-components';

import { UitkBaseProps } from '../../typings';

import TableBodyStyles from './TableBody.style';
type TableBodyProps = {
	/** **Deprecated**: Use the variant API on Table instead */
	isZebraStripe?: boolean;
};
export const TableBody = ({
	children,
	className,
	forwardedRef,
	isZebraStripe,
	...restProps
}: UitkBaseProps & TableBodyProps) => (
	<tbody {...restProps} className={classnames('TableBody', className)} ref={forwardedRef}>
		{children}
	</tbody>
);
export default styled(TableBody)`
	${TableBodyStyles};
`;
