import React from 'react';
import styled from 'styled-components';

import { isFireFox } from '../../../../utils/UserAgent';
import { UitkBaseProps } from '../../../../typings';

import componentStyle from './FieldWrapperFieldset.style';
// Firefox has a 9 year old bug around printing fieldsets so it gets a div
// @see https://bugzilla.mozilla.org/show_bug.cgi?id=471015
const FieldsetElement = isFireFox() ? 'div' : 'fieldset';

type FieldsetProps = {
	legend: any;
};

export const Fieldset = ({
	children,
	className,
	forwardedRef,
	legend,
	...restProps
}: FieldsetProps & UitkBaseProps) => {
	return (
		<FieldsetElement ref={forwardedRef} className={className} {...restProps}>
			{children}
		</FieldsetElement>
	);
};

/** @component */
export default styled(Fieldset)`
	${componentStyle};
`;
