import styled from 'styled-components';

export const TabList = styled.div`
	display: block;
	overflow-x: hidden;
	overflow-y: unset;
	width: 100%;
	max-width: 100%;
`;

export const TabContent = styled.div`
	outline: 0;
`;
