import React, { ButtonHTMLAttributes } from 'react';

import Button, { ButtonProps } from '../Button/Button';
import passRef from '../../hocs/passRef';
import { UitkBaseProps } from '../../typings';

export type SecondaryButtonProps = {
	href?: string;
	isLoading?: boolean;
	colorTheme?: 'mlcOrange' | 'mlcAqua' | 'mlcRubine' | 'mlcWhite' | 'mlcText' | 'brandColor';
	/** Only for mlcWhite colorTheme: text color on hover */
	hoverColor?: 'mlcOrange' | 'mlcAqua' | 'mlcRubine';
};

export const SecondaryButton = ({
	children,
	forwardedRef,
	...restProps
}: SecondaryButtonProps &
	ButtonProps &
	UitkBaseProps &
	ButtonHTMLAttributes<HTMLButtonElement>) => (
	<Button {...restProps} variant="secondary" ref={forwardedRef}>
		{children}
	</Button>
);

SecondaryButton.defaultProps = {
	isLoading: false,
};

export default passRef(SecondaryButton);
