/**
 * chevronRight
 * Don't modify this file. It is generated by scripts/build.js and is
 * ignored by git. The source svg file is located in the '../src' directory.
 */
import BrowserSpriteSymbol from 'svg-baker-runtime/browser-symbol';

const symbol = new BrowserSpriteSymbol({
  "id": "symbol-chevronRight-3347997917",
  "use": "symbol-chevronRight-3347997917-usage",
  "viewBox": "0 0 256 512",
  "content": "<symbol viewBox=\"0 0 256 512\" id=\"symbol-chevronRight-3347997917\"><path d=\"M24.707 38.101L4.908 57.899c-4.686 4.686-4.686 12.284 0 16.971L185.607 256 4.908 437.13c-4.686 4.686-4.686 12.284 0 16.971L24.707 473.9c4.686 4.686 12.284 4.686 16.971 0l209.414-209.414c4.686-4.686 4.686-12.284 0-16.971L41.678 38.101c-4.687-4.687-12.285-4.687-16.971 0z\" /></symbol>"
});


symbol.name = "chevronRight";

export default symbol;
