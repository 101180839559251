import styled from 'styled-components';
import React from 'react';
import { exclamationTriangle, infoCircle, timesCircleSolid, check } from '@mlc/symbols';

import passRef from '../../hocs/passRef';
import Svg from '../Svg';
import Text from '../Text';
import TertiaryButton from '../TertiaryButton';
import { UitkBaseProps } from '../../typings';

import toastStyle from './Toast.style';

type ToastProps = {
	onClose?: (...args: any[]) => any;
	variant?: 'error' | 'warning' | 'success';
	isVisible?: boolean;
	symbol?: {
		content?: string;
		id?: string;
		viewBox?: string;
		isMounted?: boolean;
	};
	message: string;
};

/**
 * @component
 */
export const Toast = ({
	className,
	forwardedRef,
	onClose,
	variant = 'success',
	isVisible = false,
	symbol,
	message,
	...restProps
}: ToastProps & UitkBaseProps) => {
	const variantMap = {
		success: {
			defaultSymbol: check,
			alt: 'Success',
			textColor: 'mlcWhite',
			role: '',
		},
		warning: {
			defaultSymbol: infoCircle,
			alt: 'Warning',
			textColor: 'mlcVeryDarkGrey',
			role: 'alert',
		},
		error: {
			defaultSymbol: exclamationTriangle,
			alt: 'Error',
			textColor: 'mlcWhite',
			role: 'alert',
		},
	};
	if (!isVisible) return null;
	return (
		<div
			className={className}
			ref={forwardedRef}
			role={variantMap[variant].role}
			{...restProps}
			key={'toast-' + variant}
		>
			<span className="Toast__Wrapper">
				<div className="Toast__Message">
					<Svg
						style={{ height: '28px', width: '28px' }}
						symbol={symbol ? symbol : variantMap[variant].defaultSymbol}
						className="Toast__Icon"
						alt={variantMap[variant].alt}
						color={variantMap[variant].textColor}
					/>

					<Text color={variantMap[variant].textColor}>{message}</Text>
				</div>

				<TertiaryButton
					className="Toast__CloseContainer"
					onClick={onClose}
					aria-label={'Close notification'}
					colorTheme={'warning' === variant ? 'mlcText' : 'mlcWhite'}
				>
					Close
					<Svg symbol={timesCircleSolid} className="Toast__Close" alt={'Close'} />
				</TertiaryButton>
			</span>
		</div>
	);
};

export default styled(passRef(Toast))`
	${toastStyle}
`;
