import { DafEnvironments, DafSdkError, GrantTypes, DafSdkErrorCodes, getEnvironmentConfig, SuccessResponse, ApiError } from '@nab/daf-common';
import Axios from 'axios';

export interface IdmRequestOAuthTokenConfig {
    environment?: DafEnvironments;
    client_id: string;
    scope?: string;
    grant_type: GrantTypes;
    code?: string;
    code_verifier?: string;
    redirect_uri?: string;

    client_secret?: string;
    client_assertion_type?: string;
    client_assertion?: string;
}

export interface ApiResponse {
    access_token: string;
    token_type: string;
    expires_in: number;
    scope?: string;
    id_token?: string;
    lastLoginTime?: string;
    idleTimeout?: number;
    bearerToken?: string;
    issued_token_type?: string;
}

export interface ApiErrorResponse extends ApiError {
    error: 'invalid_request' | 'invalid_client' | 'invalid_grant' | 'unauthorized_client' | 'unsupported_grant_type' | 'invalid_scope';
}

export type IdmRequestOAuthTokenResponse = SuccessResponse<{
    accessToken: string;
    tokenType: string;
    expiresIn: number;
    scope?: string;
    idToken?: string;
    lastLoginTime?: string;
    idleTimeout?: number;
    bearerToken?: string;
    issuedTokenType?: string;
}>;

const supportedGrantTypes = new Set<GrantTypes>([GrantTypes.Anonymous, GrantTypes.AuthorizationCode]);

function validateArgument(option: IdmRequestOAuthTokenConfig): boolean {
    if (!supportedGrantTypes.has(option.grant_type)) {
        return false;
    }

    return true;
}

export default async function idmRequestOAuthToken({
    environment = DafEnvironments.PROD,
    ...config
}: IdmRequestOAuthTokenConfig): Promise<IdmRequestOAuthTokenResponse> {
    if (!validateArgument(config)) {
        return Promise.reject(new DafSdkError(DafSdkErrorCodes.IllegalArgument, 'Illegal argument, un-supported grant type or missing mandatory fields'));
    }

    try {
        const { kongStaffGatewayBaseUrl } = getEnvironmentConfig(environment);
        const { data } = await Axios.post<ApiResponse>(`${kongStaffGatewayBaseUrl}/v1/idm/oauth/token`, config);

        return {
            data: {
                accessToken: data.access_token,
                tokenType: data.token_type,
                expiresIn: data.expires_in,
                scope: data.scope,
                idToken: data.id_token,
                lastLoginTime: data.lastLoginTime,
                idleTimeout: data.idleTimeout,
                bearerToken: data.bearerToken,
                issuedTokenType: data.issued_token_type,
            },
        };
    } catch (error) {
        const errorResponseData = error.response?.data as ApiErrorResponse;

        if (!errorResponseData?.error) {
            return Promise.reject(DafSdkError.mapFromHttpResponse(error.response?.status, error.toString()));
        }

        return Promise.reject(new DafSdkError(errorResponseData.error, errorResponseData.error_description));
    }
}
