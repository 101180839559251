import { css } from 'styled-components';
import { themeGet } from 'styled-system';

export const resetFieldset = () => css`
	${themeGet('global.base')}
	border: none;
	margin: 0;
	padding: 0;
	min-width: 0;
`;
