import React, { Component, HTMLAttributes } from 'react';
import styled from 'styled-components';

import LoadingIndicator from '../LoadingIndicator/LoadingIndicator';

import componentStyle from './AEMContent.style';

export const BaseAEMContent = styled.div`
	${componentStyle}
`;

type AEMContentProps = {
	id?: string;
	/** @ignore */
	baseUrl: string;
	/** this is the extension path for AEM rest api. Do not provide it manually
	 * @ignore
	 * */
	aemRestApiExtension?: string;
	/**
	 * AEM content path
	 */
	path: string;
	/**
	 * A callback function that will be executed in case of a retrieval error
	 */
	onError?: (...args: any[]) => any;
	/**
	 * Only use this if your content snippet is compliant content where you would
	 * like to fails the whole transaction on failure of retrieval, this will redirect
	 * the page to /common/404
	 */
	redirectTo404OnError?: boolean;
	/**
	 * Used for managing specific content styles. Have a look at the examples on how to
	 * use this effectively
	 */
	variant?: 'normal' | 'disclaimer';
	/**
	 * What should display in case of a retrieval error
	 */
	errorMessage?: ((...args: any[]) => any) | string;
	/**
	 * What should display while isLoading is true, defaults to spinner
	 */
	loadingMessage?: ((...args: any[]) => any) | string;
	/** * Force loading state. Mostly used for testing */
	isLoading?: boolean;
	/**
	 * Should response be wrapped in useful default CSS
	 */
	noStyle?: boolean;
	/**
	 * Should content be formatted with extra styles
	 */
	editorial?: boolean;
	/**
	 * A callback function that manipulates dynamic AEM content
	 */
	contentFormatter?: (s: string) => string;
};
type AEMContentState = {
	isLoading: boolean;
	content: string;
	error: boolean;
};

export class AEMContent extends Component<
	AEMContentProps,
	AEMContentState,
	HTMLAttributes<HTMLDivElement>
> {
	static defaultProps = {
		baseUrl: '',
		aemRestApiExtension: '/_jcr_content/par?excludePersonalizationClientLibs=true',
		redirectTo404OnError: false,
		noStyle: false,
	};

	constructor(props: AEMContentProps) {
		super(props);
		this.state = {
			content: '',
			isLoading: true,
			error: false,
		};
	}

	componentDidMount() {
		const contentPath = this.props.baseUrl + this.props.path + this.props.aemRestApiExtension;
		return fetch(contentPath, { credentials: 'same-origin' })
			.then((response) => {
				if (!response.ok) {
					throw response;
				}
				return response.text();
			})
			.then((contentText) => {
				this.setState({
					isLoading: false,
					content:
						this.props.contentFormatter !== undefined
							? this.props.contentFormatter(contentText)
							: contentText,
				});
			})
			.catch((error) => {
				this.setState({ error: true });
				if (this.props.redirectTo404OnError) {
					window.location.replace('/common/404');
				}
				if (this.props.onError) {
					this.props.onError(error);
				}
			});
	}

	render() {
		const {
			loadingMessage,
			errorMessage,
			isLoading: forceLoad,
			noStyle,
			baseUrl,
			aemRestApiExtension,
			redirectTo404OnError,
			path,
			...restProps
		} = this.props;
		const { error, content, isLoading } = this.state;
		const ShowLoading = forceLoad || (isLoading && error === false);
		// loading component or spinner
		if (ShowLoading && (typeof loadingMessage === 'function' || loadingMessage === undefined)) {
			// @ts-ignore
			return loadingMessage ? <>{loadingMessage}</> : <LoadingIndicator colorTheme="dark" />;
		} else if (error && typeof errorMessage === 'function') {
			return <>{errorMessage}</>;
		} else {
			// errorMessage > loadingMessage > success response
			const message =
				error && errorMessage
					? errorMessage
					: ShowLoading && loadingMessage
					? loadingMessage
					: content;
			const AEMContainer = noStyle ? 'div' : BaseAEMContent;
			return <AEMContainer {...restProps} dangerouslySetInnerHTML={{ __html: message }} />;
		}
	}
}

export default AEMContent;
