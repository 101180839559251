import React from 'react';
import styled from 'styled-components';

import PrimaryButton from '../../../PrimaryButton/PrimaryButton';
import Button from '../../../Button/Button';
import ScreenReaderText from '../../../ScreenReaderText/ScreenReaderText';
import { UitkBaseProps } from '../../../../typings';

import componentStyle from './ModalFooter.style';
type ModalFooterProps = {
	onRequestClose: (...args: any[]) => any;
	footerButtons?: any[];
	hideFooterButtons?: boolean;
	colorTheme?: 'mlcOrange' | 'mlcAqua' | 'mlcRubine' | 'mlcWhite' | 'brandColor';
	align?: 'left' | 'center' | 'right';
};
/**
 * Act as a container to hold components that will be rendered as footer in modal window.
 */
export const ModalFooter = styled(
	({
		onRequestClose,
		className,
		forwardedRef,
		hideFooterButtons,
		footerButtons,
		colorTheme,
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		align,
	}: ModalFooterProps & UitkBaseProps) => {
		const color = colorTheme === 'mlcWhite' ? 'mlcOrange' : colorTheme;
		if (hideFooterButtons || (footerButtons && footerButtons.length === 0)) {
			return null;
		}
		return (
			<div className={className} ref={forwardedRef}>
				{!footerButtons ? (
					<PrimaryButton colorTheme={color} onClick={onRequestClose}>
						Close
					</PrimaryButton>
				) : footerButtons.length === 2 ? (
					<>
						<Button
							colorTheme={
								footerButtons[0].buttonType &&
								footerButtons[0].buttonType !== 'tertiary'
									? color
									: 'mlcText'
							}
							className="modalFooterLeftButton"
							onClick={footerButtons[0].onClick}
							variant={footerButtons[0].buttonType || 'tertiary'}
							isLoading={footerButtons[0].isLoading}
						>
							{footerButtons[0].content}
						</Button>
						<Button
							colorTheme={
								footerButtons[1].buttonType &&
								footerButtons[1].buttonType === 'tertiary'
									? 'mlcText'
									: color
							}
							className="modalFooterRightButton"
							onClick={footerButtons[1].onClick}
							variant={footerButtons[1].buttonType || 'primary'}
							isLoading={footerButtons[1].isLoading}
						>
							{footerButtons[1].content}
						</Button>
					</>
				) : (
					<>
						<PrimaryButton
							colorTheme={color}
							onClick={footerButtons[0].onClick}
							isLoading={footerButtons[0].isLoading}
						>
							{footerButtons[0].content}
						</PrimaryButton>
					</>
				)}
				<ScreenReaderText>end of modal.</ScreenReaderText>
			</div>
		);
	}
)`
	${componentStyle};
`;
/**
 * @component
 */
export default ModalFooter;
