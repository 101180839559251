import { css } from 'styled-components';
import { themeGet } from 'styled-system';

export default (props) => css`
	${themeGet('global.base')}
	min-height: 100px;
	padding: 15px 0px;
	.PageHeader__title {
		margin-top: 0;
		padding-top: 15px;
		margin-bottom: 10px;
		color: ${themeGet('colors.' + props.color)};
	}
	.PageHeader__description {
		margin-top: 0;
		color: ${themeGet('colors.' + props.color)};
	}
`;
