import React, { createContext } from 'react';
import { ThemeProvider } from 'styled-components';
import { GridThemeProvider } from 'styled-bootstrap-grid';

import themes from '../../themes';
import { UitkBaseProps } from '../../typings';
import { ScreenReaderLiveRegionProvider } from '../ScreenReaderLiveRegion';

export const Theme = createContext(themes['mlc']);
type ContextProps = {
	themeName?:
		| 'mlc'
		| 'dpm'
		| 'blueprint'
		| 'portfolio-focus'
		| 'premium-choice'
		| 'fc-one'
		| 'enevita'
		| 'st-andrews'
		| 'plum';
};
export const Context = ({ children, themeName }: ContextProps & UitkBaseProps) => (
	<ThemeProvider theme={themes[themeName]}>
		<GridThemeProvider gridTheme={themes[themeName].gridTheme}>
			<ScreenReaderLiveRegionProvider>{children}</ScreenReaderLiveRegionProvider>
		</GridThemeProvider>
	</ThemeProvider>
);
Context.defaultProps = {
	themeName: 'mlc',
};

export default Context;
