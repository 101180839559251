import React, { useContext, useEffect, useState } from 'react';

import { LiveRegionContext, Announcement } from '../ScreenReaderLiveRegion';

const ScreenReaderMessenger = ({ children, ...ariaConfig }: Announcement) => {
	const { announce } = useContext(LiveRegionContext);

	const [currentMessage, storeMessage] = useState({});

	useEffect(() => {
		if (currentMessage !== children) {
			storeMessage(children);
			announce({ children, ...ariaConfig });
		}
	}, [announce, ariaConfig, children, currentMessage]);

	return <>{children}</>;
};

ScreenReaderMessenger.defaultProps = {
	'aria-live': 'polite',
	'aria-atomic': 'true',
};

export default ScreenReaderMessenger;
