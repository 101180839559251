import styled from 'styled-components';
import { themeGet } from 'styled-system';

export const TabScroll = styled.div`
	background-color: ${themeGet('colors.mlcWhite')};
	flex-grow: 0;
	flex-shrink: 0;
	overflow-x: auto;
	display: flex;
	position: relative;
	justify-content: flex-start;
`;

export const ScrollButtonRight = styled.button`
	background: white;
	border: thin transparent solid;
	color: ${themeGet('colors.brandPrimaryLight')};
	display: flex;
	align-items: center;
	padding-left: 10px;
	padding-right: 10px;
	transition: all 0.15s linear;
	outline: none;
	position: absolute;
	right: 0;
	top: 1px;
	bottom: 1px;
	&:hover {
		color: ${themeGet('colors.brandPrimaryDark')};
	}
	&:focus {
		border: thin ${themeGet('colors.brandPrimaryDark')} solid;
		color: ${themeGet('colors.brandPrimaryDark')};
	}

	&:disabled {
		color: ${themeGet('colors.mlcLightGrey')};
		border: thin transparent solid;
	}
`;

export const ScrollButtonLeft = styled.button`
	background: white;
	border: thin transparent solid;
	color: ${themeGet('colors.brandPrimaryLight')};
	display: flex;
	align-items: center;
	padding-left: 10px;
	padding-right: 10px;
	transition: all 0.15s linear;
	outline: none;
	position: absolute;
	left: 0;
	top: 1px;
	bottom: 1px;
	&:hover {
		color: ${themeGet('colors.brandPrimaryDark')};
	}
	&:focus {
		border: thin ${themeGet('colors.brandPrimaryDark')} solid;
		color: ${themeGet('colors.brandPrimaryDark')};
	}

	&:disabled {
		color: ${themeGet('colors.mlcLightGrey')};
		border: thin transparent solid;
	}
`;

export const TabsButtonGroupContainer = styled.div`
	display: flex;
	width: 100%;
	flex: 1;
	position: relative;
	background-color: ${themeGet('colors.mlcWhite')};
	justify-content: space-between;
`;

export const TabScrollContainer = styled.div`
	flex: 1;
	display: flex;
	overflow: hidden;
`;
