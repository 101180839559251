import { css } from 'styled-components';
import { themeGet } from 'styled-system';

export default ({ rowColor, bottomLine }) => {
	return css`
		margin: 20px 0px;
		background-color: ${rowColor ? themeGet('colors.' + rowColor) : 'inherit'};
		border-bottom: ${bottomLine ? 'thin solid' : 'none'};
		border-color: ${themeGet('colors.mlcLightGrey')};
	`;
};
