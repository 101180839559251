import React from 'react';
import classnames from 'classnames';
import { eye, eyeSlash } from '@mlc/symbols';

import Svg from '../../../Svg/Svg';
import ScreenReaderText from '../../../ScreenReaderText/ScreenReaderText';
import { UitkBaseProps } from '../../../../typings';
type PasswordMaskTogglerProps = {
	showPassword?: boolean;
	onToggle?: (...args: any[]) => any;
	hideRef?: {
		current: any;
	};
	showRef?: {
		current: any;
	};
};
export const PasswordMaskToggler = ({
	showPassword,
	onToggle,
	showRef,
	hideRef,
	className,
}: PasswordMaskTogglerProps & UitkBaseProps) => (
	<div>
		<button
			className={classnames(
				className,
				'Password__icon',
				showPassword ? 'Password__icon--hide' : 'Password__icon--show'
			)}
			onClick={onToggle}
			aria-label={'show password'}
			aria-describedby={'passwordShowHide'}
			ref={showRef}
			type="button"
		>
			<Svg symbol={eyeSlash} />
		</button>
		<button
			className={classnames(
				className,
				'Password__icon',
				!showPassword ? 'Password__icon--hide' : 'Password__icon--show'
			)}
			onClick={onToggle}
			aria-label={'hide password'}
			aria-describedby={'passwordShowHide'}
			ref={hideRef}
			type="button"
		>
			<Svg symbol={eye} />
		</button>
		<ScreenReaderText id="passwordShowHide">
			{`The password field is currently ${showPassword ? 'readable.' : 'secure.'}`}
		</ScreenReaderText>
	</div>
);
PasswordMaskToggler.defaultProps = {
	showPassword: false,
};
export default PasswordMaskToggler;
