import React from 'react';
import { debounce } from 'lodash';

import passRef from '../../hocs/passRef';
import Text from '../Text';
import { UitkBaseProps, PassRefProps } from '../../typings';

import * as Styled from './TabsButton.style';

export type TabsButtonProps = {
	/** This can be a string as well as a component. This will not be ovverridden */
	children?: JSX.Element | string;
	/** activeTabIndex will be overriden when used inside TabsButtonGroup  */
	activeTabIndex?: number;
	/** handleActiveTabChange will be overriden when used inside TabsButtonGroup  */
	handleActiveTabChange?: (index: number) => void;
	/** index will be overriden when used inside TabsButtonGroup  */
	index?: number;
	/** onKeyPress will be overriden when used inside TabsButtonGroup  */
	onKeyPress?: (index: number) => void;
	/** The size would determinse the padding to add to the sides of the tab buttons. This will be overriden by the TabsButtonGroup size property*/
	size?: 'small' | 'medium';
	moverRef?: any;
};

type TabsButtonState = {
	width: number;
};

type Props = PassRefProps & UitkBaseProps & TabsButtonProps;

class TabsButton extends React.Component<Props, TabsButtonState> {
	constructor(props: Props) {
		super(props);
		this.updateActiveElement = debounce(this.updateActiveElement.bind(this), 100);
		this.handleResize = this.handleResize.bind(this);
		this.state = {
			width: -1,
		};
	}

	// remove transitions for resizing period to stop strange position/width adjustment animations on Safari on zoom
	handleResize = () => {
		const mover = this.props.moverRef.current;
		if (mover) {
			mover.style.transition = 'none';
		}
		this.updateActiveElement.bind(this);
		setTimeout(() => {
			if (mover) {
				mover.style.transition = 'all 0.3s ease-in-out';
			}
		}, 500);
	};

	componentDidUpdate() {
		const currentWidth = this.getWidth();

		if (this.hasTheWidthChanged(currentWidth)) {
			this.setState({ width: currentWidth });
		}
	}
	componentDidMount() {
		this.updateActiveElement();
		window.addEventListener('resize', this.handleResize);
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.handleResize);
	}

	isActive = () => {
		const { index, activeTabIndex } = this.props;
		const isActive = index >= 0 && index === activeTabIndex;

		return isActive;
	};

	updateActiveElement = () => {
		const { handleActiveTabChange, index } = this.props;

		if (this.isActive()) {
			const width = this.getWidth();
			this.setState(
				{
					width,
				},
				() => {
					handleActiveTabChange && handleActiveTabChange(index);
				}
			);
		}
	};

	getWidth = () => {
		const { forwardedRef } = this.props;
		if (forwardedRef && forwardedRef.current) {
			const dimensions = forwardedRef.current.getBoundingClientRect();
			return Math.floor(dimensions.width);
		}
		return -1;
	};

	hasTheWidthChanged(currentWidth) {
		return this.state.width > -1 && Math.floor(this.state.width) !== Math.floor(currentWidth);
	}

	render() {
		const {
			children,
			handleActiveTabChange,
			forwardedRef,
			index,
			onKeyPress,
			size = 'medium',
		} = this.props;

		const isActive = this.isActive();
		return (
			<Styled.Button
				ref={forwardedRef}
				onKeyUp={onKeyPress}
				onClick={() => {
					handleActiveTabChange && handleActiveTabChange(index);
					if (forwardedRef && forwardedRef.current) {
						forwardedRef.current.focus();
					}
				}}
				className={`Tabs_button ${isActive ? 'active' : ''}`}
				tabIndex={isActive ? 0 : -1}
				role="tab"
				aria-selected={isActive}
				aria-controls={`tabpanel-${index}`}
				id={`tabs-${index}`}
				size={size}
			>
				<Text>{children}</Text>
			</Styled.Button>
		);
	}
}

// @ts-ignore
TabsButton.tabsRole = 'tabsButton';

/**
 * @component
 */
export default passRef(TabsButton);

export { TabsButton };
