import React, { ButtonHTMLAttributes } from 'react';

import Button, { ButtonProps } from '../Button/Button';
import passRef from '../../hocs/passRef';
import { UitkBaseProps } from '../../typings';

type TertiaryButtonProps = {
	href?: string;
	isLoading?: boolean;
	colorTheme?: 'mlcOrange' | 'mlcAqua' | 'mlcRubine' | 'mlcWhite' | 'mlcText' | 'brandColor';
};
export const TertiaryButton = ({
	children,
	forwardedRef,
	isLoading = false,
	...restProps
}: UitkBaseProps & TertiaryButtonProps & ButtonProps & ButtonHTMLAttributes<HTMLButtonElement>) => (
	<Button ref={forwardedRef} {...restProps} variant="tertiary" isLoading={isLoading}>
		{children}
	</Button>
);

export default passRef(TertiaryButton);
