import React from 'react';
import styled from 'styled-components';

import { isFireFox } from '../../utils/UserAgent';
import passRef from '../../hocs/passRef';
import { UitkBaseProps } from '../../typings';

import { resetFieldset } from './Fieldset.style';
// Firefox has a 9 year old bug around printing fieldsets so it gets a div
// @see https://bugzilla.mozilla.org/show_bug.cgi?id=471015
const FieldsetElement = isFireFox() ? 'div' : 'fieldset';
const StyledFieldset = styled(FieldsetElement)`
	${resetFieldset};
`;
export const Fieldset = ({
	children,
	className,
	forwardedRef,
	...restProps
}: UitkBaseProps & React.HTMLAttributes<HTMLFieldSetElement | HTMLDivElement>) => {
	return (
		<StyledFieldset ref={forwardedRef} className={className} {...restProps}>
			{children}
		</StyledFieldset>
	);
};
/** @component */
export default passRef(Fieldset);
