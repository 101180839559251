import React, { HTMLAttributes } from 'react';
import styled from 'styled-components';

import passRef from '../../hocs/passRef';
import Grid from '../Grid/Grid';
import Card from '../Card';
import { UitkBaseProps } from '../../typings';

import componentStyle from './ProgressBar.style';
type ProgressBarProps = {
	/** Text color describes the ProgressBar text */
	color?: string;
	/** Background color describes the ProgressBar background */
	backgroundColor?: string;
	/** Bar base color describes the ProgressBar base color */
	barBaseColor?: string;
	/** Determine whether the last page should be a confirmation page. Changes how the step numbers are displayed. */
	includeConfirmationPage?: boolean;
	/** The config array which describes the ProgressBar config */
	progressBarConfig?: any[];
};
export const ProgressBar = ({
	className,
	progressBarConfig,
	forwardedRef,
	color,
	backgroundColor,
	barBaseColor,
	includeConfirmationPage,
	...restProps
}: ProgressBarProps & UitkBaseProps & HTMLAttributes<HTMLDivElement>) => {
	const totalStepIndex = includeConfirmationPage
			? progressBarConfig.length - 1
			: progressBarConfig.length,
		currentStepLabel = progressBarConfig.find((item) => {
			return item.active === true;
		})['value'],
		currentStepIndex = progressBarConfig.findIndex((item) => {
			return item.active === true;
		}),
		currentStepValue = currentStepIndex + 1;
	return (
		<Card backgroundColor={backgroundColor} className={className} {...restProps} paddingTop={8}>
			<Grid>
				<progress
					value={currentStepValue}
					max={progressBarConfig.length}
					className="ProgressBar__bar"
					data-testid="ProgressBar__bar"
				/>
				<Card
					className="ProgressBar__info"
					justifyContent="space-between"
					display="flex"
					color={color}
				>
					<span className="ProgressBar__label">{currentStepLabel}</span>
					<span className="ProgressBar__step" data-testid="ProgressBar__step">
						<span className="ProgressBar__step-label">Step </span>
						{`${Math.min(currentStepValue, totalStepIndex)} of 
								${totalStepIndex}`}
					</span>
				</Card>
			</Grid>
		</Card>
	);
};

ProgressBar.defaultProps = {
	color: 'mlcWhite',
	backgroundColor: 'mlcOrange',
	barBaseColor: 'mlcVeryDarkGrey80',
	includeConfirmationPage: true,
};

export default styled(passRef(ProgressBar))`
	${componentStyle}
`;
