export const calculateOffset = (currentPage, lastPage) => {
	switch (currentPage) {
		case 0:
			return 1;
		case 1:
			return 0;
		case lastPage - 1:
			return -2;
		case lastPage:
			return -3;
		default:
			return -1;
	}
};
