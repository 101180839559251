import { css } from 'styled-components';

export default () => css`
	display: block;

	&.RadioGroup--stacked {
		.Radio {
			display: block;
			margin-right: 0;
		}
	}
`;
