import { css } from 'styled-components';
import { themeGet } from 'styled-system';

export default () => css`
	display: block;
	overflow: visible;
	position: relative;
	width: 100%;

	/* The input's 'border' element is absolutely positioned. For labelUnderline variant inputs the border extends the full width of its containing FieldWrapper so set position to 'static' */
	.InputWrapper--labelUnderlineVariant {
		position: static;
		width: 100%;
	}

	.InputWrapper--normalSize {
		font-size: ${themeGet('fontSizes.medium')}px;
		line-height: ${themeGet('lineHeights.medium')};
	}

	.InputWrapper--largeSize {
		font-size: ${themeGet('fontSizes.large')}px;
		line-height: ${themeGet('lineHeights.large')};
	}
`;
