import React, { HTMLAttributes, SelectHTMLAttributes } from 'react';
import styled from 'styled-components';
import classnames from 'classnames';
import { chevronDown } from '@mlc/symbols';

import { Svg } from '../Svg/Svg';
import passRef from '../../hocs/passRef';
import LoadingIndicator from '../LoadingIndicator/LoadingIndicator';
import { UitkBaseProps, FieldWrapperClone, UitkStatics } from '../../typings';

import { selectStyle } from './Select.style';
type SelectProps = {
	isInvalid?: boolean;
	onChange?: (...args: any[]) => any;
	placeholder?: string;
	value?: string | any[];
	options?: Array<
		| string
		| {
				label?: string;
				value?: string;
		  }
	>;
	width?: string | object;
	variant?: 'block' | 'inline';
	isLoading?: boolean;
};
export const Select = ({
	id,
	placeholder,
	name,
	value,
	options,
	isInvalid,
	onChange,
	ariaLabelledby,
	ariaDescribedby,
	isFormField,
	className,
	variant,
	width,
	forwardedRef,
	isLoading,
	...restProps
}: UitkBaseProps &
	SelectProps &
	HTMLAttributes<HTMLSelectElement> &
	SelectHTMLAttributes<HTMLSelectElement> &
	FieldWrapperClone &
	UitkStatics) => {
	return (
		<div className={className}>
			<select
				aria-describedby={ariaDescribedby}
				aria-labelledby={ariaLabelledby}
				aria-invalid={isInvalid}
				onChange={onChange}
				id={id}
				name={name}
				value={value || ''}
				{...restProps}
				className={classnames('Select', isInvalid ? 'Select--isInvalid' : '')}
				ref={forwardedRef}
			>
				<option value="">{placeholder}</option>

				{options &&
					options.map((item, index) => {
						const label =
							typeof item === 'string' ? item : item.label ? item.label : null;
						const value =
							typeof item === 'string' ? item : item.value ? item.value : null;
						return (
							<option key={value + index} value={value}>
								{label}
							</option>
						);
					})}
			</select>
			{isLoading ? (
				<div className="Select__icon">
					<LoadingIndicator variant="inline" colorTheme="dark" />
				</div>
			) : (
				<Svg className="Select__icon" symbol={chevronDown} />
			)}
		</div>
	);
};

Select.defaultProps = {
	placeholder: 'Please select',
};
Select.isFormField = true;

export default styled(passRef(Select))`
	${selectStyle};
`;
