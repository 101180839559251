import React from 'react';
import { UnmountClosed } from 'react-collapse';
import classnames from 'classnames';
import { plus, minus } from '@mlc/symbols';
import * as palette from '@mlc/mlc-palette';
import { generate } from 'shortid';

import { UitkBaseProps, PassRefProps } from '../../typings';
import Label from '../Label';
import Svg from '../Svg';
import Text from '../Text';
import Card from '../Card';

import { AccordionBox, AccordionButton } from './Accordion.style';

export type IconProps = {
	content: string;
	id?: string;
	viewBox: string;
	isMounted: boolean;
};

export type AccordionProps = {
	el?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'p';
	id?: string;
	onChange?: (...args: any[]) => any;
	isOpen?: boolean;
	subTitle?: string;
	title?: string | JSX.Element;
	openSymbol?: IconProps;
	closeSymbol?: IconProps;
	noBorder?: boolean;
	customButtonComponent?: (isOpen: boolean) => JSX.Element;
	/* customButtom component defaults to 20px, set this prop for no margin **/
	noMarginBottom?: boolean;
	borderStyle?: 'emphasis' | 'normal' | 'none';
	borderEmphasisColor?: string;
	backgroundColor?: string;
};

type AccordionStateProps = {
	isOpen: boolean;
};

type Props = PassRefProps & UitkBaseProps & AccordionProps;
export class Accordion extends React.Component<Props, AccordionStateProps> {
	constructor(props: Props) {
		super(props);

		this.toggleOpen = this.toggleOpen.bind(this);

		this.state = {
			isOpen: this.props.isOpen !== undefined ? this.props.isOpen : false,
		};
	}

	/* Accordion controls its own open state, but it is possible to override via props */
	toggleOpen() {
		if (this.props.onChange) {
			this.props.onChange();
		} else {
			this.setState((prevState: AccordionStateProps) => {
				return { isOpen: !prevState.isOpen };
			});
		}
	}

	renderDefaultHeader = (isOpen: boolean) => {
		const { title, el, subTitle, openSymbol = plus, closeSymbol = minus } = this.props;
		return (
			<>
				{typeof title === 'string' ? (
					<Label display="block" el={el}>
						{title}
					</Label>
				) : (
					<>{title}</>
				)}
				{subTitle && <Text>{subTitle}</Text>}
				<span hidden={isOpen} className="Accordion__icon">
					<Svg symbol={openSymbol} />
				</span>
				<span hidden={!isOpen} className="Accordion__icon">
					<Svg symbol={closeSymbol} />
				</span>
			</>
		);
	};

	render() {
		const {
			children,
			className,
			forwardedRef,
			el,
			title,
			subTitle,
			id,
			customButtonComponent,
			noMarginBottom,
			isOpen: isOpenProp,
			onChange: onChangeProp,
			noBorder,
			backgroundColor,
			borderEmphasisColor,
			borderStyle = 'normal',
			...restProps
		} = this.props;

		const ariaId = id ? id : generate();
		const sectionId = ariaId + '-section';
		const onChange = onChangeProp ? onChangeProp : this.toggleOpen;
		const isOpen = onChangeProp ? isOpenProp : this.state.isOpen;

		let borderType;
		if (noBorder === true) {
			borderType = 'none';
		} else if (noBorder === false) {
			borderType = 'normal';
		} else {
			borderType = borderStyle;
		}

		return (
			<AccordionBox
				className={classnames([isOpen && 'Accordion--isOpen', className])}
				isOpen={isOpen}
				hasCustomHeader={!!customButtonComponent}
				noMarginBottom={noMarginBottom ?? false}
				backgroundColor={backgroundColor}
				borderEmphasisColor={borderEmphasisColor}
				borderStyle={borderType}
			>
				{/* IE 11 needs flex */}
				<Card position="relative" display="flex">
					<AccordionButton
						aria-controls={sectionId}
						aria-expanded={isOpen}
						isOpen={isOpen}
						hasCustomHeader={!!customButtonComponent}
						backgroundColor={backgroundColor}
						borderEmphasisColor={borderEmphasisColor}
						borderStyle={borderType}
						id={ariaId}
						onClick={onChange}
						ref={forwardedRef}
						type="button"
						{...restProps}
					>
						{typeof customButtonComponent === 'function'
							? customButtonComponent(isOpen)
							: this.renderDefaultHeader(isOpen)}
					</AccordionButton>
				</Card>
				<UnmountClosed isOpened={isOpen}>
					<Card
						backgroundColor={palette.mlcWhite}
						id={sectionId}
						aria-hidden={!isOpen}
						aria-labelledby={ariaId}
						overflow="visible"
					>
						{children}
					</Card>
				</UnmountClosed>
			</AccordionBox>
		);
	}
}

/** @component */
export default Accordion;
