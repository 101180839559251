import { EmployeeSsoState } from '../employeeSsoAuthenticate';
import { Persist } from './persists';

export interface GeneratedCodes {
    codeVerifier: string;
    state: string;
    codeChallenge: string;
}

export interface DisplayStrategy {
    authorize(url: string): Promise<EmployeeSsoState | void>;
    onAuthorized(authState: EmployeeSsoState): Promise<void>;
    getPersist(): Persist<GeneratedCodes>;
    strategyName: string;
    shouldBreak(authState: EmployeeSsoState | void): boolean;
    toString(): string;
}

export default abstract class BaseDisplayStrategy implements DisplayStrategy {
    private readonly persist: Persist<GeneratedCodes>;

    public readonly strategyName: string = 'base';

    constructor(persist: Persist<GeneratedCodes>) {
        this.persist = persist;
    }

    abstract authorize(url: string): Promise<void | EmployeeSsoState>;

    abstract onAuthorized(authState: EmployeeSsoState): Promise<void>;

    getPersist(): Persist<GeneratedCodes> {
        return this.persist;
    }

    abstract shouldBreak(authState: EmployeeSsoState): boolean;

    public toString(): string {
        return this.strategyName;
    }
}
