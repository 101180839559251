/**
 * plus
 * Don't modify this file. It is generated by scripts/build.js and is
 * ignored by git. The source svg file is located in the '../src' directory.
 */
import BrowserSpriteSymbol from 'svg-baker-runtime/browser-symbol';

const symbol = new BrowserSpriteSymbol({
  "id": "symbol-plus-3795531600",
  "use": "symbol-plus-3795531600-usage",
  "viewBox": "0 0 384 512",
  "content": "<symbol viewBox=\"0 0 384 512\" id=\"symbol-plus-3795531600\"><path d=\"M368 224H224V80c0-8.84-7.16-16-16-16h-32c-8.84 0-16 7.16-16 16v144H16c-8.84 0-16 7.16-16 16v32c0 8.84 7.16 16 16 16h144v144c0 8.84 7.16 16 16 16h32c8.84 0 16-7.16 16-16V288h144c8.84 0 16-7.16 16-16v-32c0-8.84-7.16-16-16-16z\" /></symbol>"
});


symbol.name = "plus";

export default symbol;
