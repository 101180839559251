import React from 'react';
import styled, { css } from 'styled-components';
import { themeGet } from 'styled-system';

import Text from '../Text';

const linkSpacing = css`
	a:not(:last-of-type) {
		margin: 0 20px 0 0;
	}
`;

export const SecondaryNav = styled.nav`
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	margin-bottom: 35px;

	@media screen and (min-width: ${themeGet('gridTheme.breakpoints.md')}px) {
		${linkSpacing}
		flex-direction: row;
	}
`;

export const FooterSecondary = styled.nav`
	${linkSpacing}
	@media screen and (min-width: ${themeGet('gridTheme.breakpoints.md')}px) {
		text-align: right;
	}
`;

export const FooterLink = styled.a`
	color: ${themeGet('colors.mlcAlmostWhite')};
	text-decoration: underline;

	&:hover,
	&:focus {
		color: ${themeGet('colors.mlcLightGrey')};
	}

	&:hover,
	&:focus {
		text-decoration: none;
	}
	&:focus {
		outline: 1px solid ${themeGet('colors.mlcLightGrey')};
	}
`;

export const FooterText = ({ ...props }) => (
	<Text color="mlcAlmostWhite" fontSize="disclaimer" {...props} />
);

export const InjectedFooterText = styled.div`
	p {
		${themeGet('global.base')}
		color: ${themeGet('colors.mlcAlmostWhite')};
		margin: 0;
		padding: 0 0 10px 0;
		font-size: ${themeGet('fontSizes.disclaimer')}px;
		line-height: ${themeGet('lineHeights.disclaimer')};

		@media screen and (min-width: ${themeGet('gridTheme.breakpoints.md')}px) {
			padding: 0;
		}
	}
`;
