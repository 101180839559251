import React, { HTMLAttributes, AnchorHTMLAttributes } from 'react';
import styled from 'styled-components';
import classnames from 'classnames';
import { chevronRight, chevronLeft } from '@mlc/symbols';

import Svg from '../../Svg/Svg';
import ScreenReaderText from '../../ScreenReaderText/ScreenReaderText';
import { UitkBaseProps } from '../../../typings';

import componentStyle, { PaginationLink } from './PaginatorListItem.style';
export const PREV_NEXT_VARIANT = {
	NEXT: 'Next',
	PREV: 'Previous',
};
const firstItem = '1';
const PREV_BUTTON_ID = 'PaginatorPreviousButton';
const NEXT_BUTTON_ID = 'PaginatorNextButton';
type PaginatorListItemProps = {
	isSelectedItem?: boolean;
	isVisible?: boolean;
	variant?: string;
	totalPages?: number;
};
export const PaginatorListItem = styled(
	({
		className,
		children,
		isSelectedItem,
		onClick,
		variant,
		isVisible,
		totalPages,
		...restProps
	}: PaginatorListItemProps &
		UitkBaseProps &
		AnchorHTMLAttributes<HTMLAnchorElement> &
		HTMLAttributes<HTMLParagraphElement>) => (
		<React.Fragment {...restProps}>
			{variant ? (
				<PaginationLink
					onClick={(e: any) => {
						e.preventDefault();
						onClick(e);
					}}
					href="#"
					key={variant}
					className={classnames(
						`${variant}__Button`,
						`PrevNext__${isVisible}`,
						'PrevNext__Button',
						isVisible ? '' : 'is-invisible',
						className
					)}
					aria-label={`Go to page ${children}`}
					data-id={variant === PREV_NEXT_VARIANT.PREV ? PREV_BUTTON_ID : NEXT_BUTTON_ID}
				>
					{variant === PREV_NEXT_VARIANT.PREV && (
						<Svg verticalAlign="middle" symbol={chevronLeft} marginRight={1} />
					)}
					{variant}
					{variant === PREV_NEXT_VARIANT.NEXT && (
						<Svg verticalAlign="middle" symbol={chevronRight} marginLeft={1} />
					)}
				</PaginationLink>
			) : isSelectedItem ? (
				<PaginationLink
					href="#"
					onClick={(e: any) => e.preventDefault()}
					key={variant}
					aria-label={`Page ${children} of ${totalPages}`}
					aria-current="page"
				>
					<p
						className={classnames('Selected__Item', className)}
						{...restProps}
						style={{
							marginLeft: children === firstItem ? 0 : 'inherited',
						}}
					>
						{children}
					</p>
				</PaginationLink>
			) : (
				<PaginationLink
					href="#"
					className={classnames('Button__Item', className)}
					onClick={(e: any) => {
						e.preventDefault();
						onClick(e);
					}}
					aria-label={`Page ${children} of ${totalPages}`}
					{...restProps}
				>
					<ScreenReaderText>page</ScreenReaderText>
					{children}
				</PaginationLink>
			)}
		</React.Fragment>
	)
)`
	${componentStyle};
`;
PaginatorListItem.defaultProps = {
	isSelectedItem: false,
	isVisible: true,
	variant: '',
};
export default PaginatorListItem;
