import { css } from 'styled-components';
import { themeGet } from 'styled-system';

export default () => css`
	position: relative;
	text-align: center;
	margin: -1px -1px 0px -1px;

	&.modalHeader--brandColor {
		background-color: ${themeGet('colors.brandPrimaryLight')};
	}
	
    &.modalHeader--mlcOrange {
		background-color: ${themeGet('colors.mlcOrange')};
	}

	&.modalHeader--mlcAqua {
		background-color: ${themeGet('colors.mlcAqua')};
	}

	&.modalHeader--mlcRubine {
		background-color: ${themeGet('colors.mlcRubine')};
	}

	&.modalHeader--plain {
		background-color: ${themeGet('colors.mlcWhite')};
		margin: 0;
	}

	.headerIconContainer {
		margin-top: 20px;	
	}

	button {
		background-color: transparent;
		border: none;
		padding: 0px;
		cursor: pointer;
	}

	.closeIconContainer {
		height: 24px;
		margin-top: 5px;
		margin-right: 5px;
		position: absolute;
		right: 0;
		width: 24px;
		z-index: 1;
	}

	.closeIcon {
		&.darkCloseIcon {
			color: ${themeGet('colors.mlcMediumGrey')};
			:hover {
				color: ${themeGet('colors.mlcVeryDarkGrey')};
			}
		}

		&.lightCloseIcon {
			color: ${themeGet('colors.mlcWhite')};
			:hover {
				color: ${themeGet('colors.mlcMediumGrey')};
		}
	}
`;
