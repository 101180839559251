import styled from 'styled-components';
import { themeGet } from 'styled-system';

export const Nav = styled.nav`
	position: relative;
	width: 100%;
	@media (max-width: ${themeGet('gridTheme.breakpoints.lg')}px) {
		z-index: 1;
	}

	.Navigation__list {
		list-style: none;
		padding: 0;
		margin: 0;
		@media (min-width: ${themeGet('gridTheme.breakpoints.lg')}px) {
			display: flex;
			justify-content: flex-end;
			align-items: center;
		}

		@media (max-width: ${themeGet('gridTheme.breakpoints.lg')}px) {
			display: block;
			overflow: hidden;
		}
	}
`;
