import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { withTheme } from 'styled-components';
import { themeGet } from 'styled-system';

import Dropdowns from '../Dropdowns/Dropdowns';
import { UitkBaseProps } from '../../../../typings';

import { UserNav } from './User.style';
type UserProps = {
	userConfig?: {
		key?: string;
		pageurl?: string;
		subpages?: any[];
		title?: string;
		url?: string;
	}[];
	isLoggedIn?: boolean;
	colorTheme?: 'adviser' | 'investor';
	loginLink?: string;
	onClick?: (...args: any[]) => any;
	isDesktop?: string;
	/** @ignore */
	theme?: any;
};
const User = withTheme(
	({
		userConfig,
		isLoggedIn,
		colorTheme,
		loginLink,
		onClick,
		...restProps
	}: UserProps & UitkBaseProps) => {
		const breakpints = themeGet('breakpoints.lg')(restProps);
		const isDesktop = useMediaQuery({
			query: `(min-width: ${breakpints}px)`,
		});
		return (
			<UserNav
				className="user"
				role="navigation"
				colorTheme={colorTheme}
				data-uitk="user"
				aria-label="MLC user panel"
			>
				<ul className="User-list">
					{isLoggedIn ? (
						userConfig.map((menu) => (
							<Dropdowns
								navigationType="user"
								key={menu.key}
								menu={menu}
								colorTheme={colorTheme}
								onClick={onClick}
								isDesktop={isDesktop}
							/>
						))
					) : (
						<Dropdowns
							navigationType="user"
							colorTheme={colorTheme}
							loginLink={loginLink}
							onClick={onClick}
							isDesktop={isDesktop}
						/>
					)}
				</ul>
			</UserNav>
		);
	}
);
/**
 * @component
 */
export default User;
