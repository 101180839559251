import styled, { css } from 'styled-components';
import { media } from 'styled-bootstrap-grid';
import { themeGet } from 'styled-system';

import { isIE11 } from '../../utils/UserAgent';

export default () => css`
	&__htmlOpen {
		z-index: 1;
		overflow: hidden;
		min-height: 100vh;
		height: ${isIE11() ? '100vh' : null};
	}

	&__bodyOpen {
		position: static;
		overflow-y: scroll;
	}

	&__reactModal {
		margin-left: 15px;
		margin-right: 15px;
		width: 320px;
		max-width: 320px;

		${media.md`
			top: 20vh;
			outline-style: none;
			position: ${isIE11() ? 'static' : 'absolute'};
			width: 600px;
			max-width: 600px;
		`}

		&.wide {
			${media.lg`
				width: 970px;
				max-width: 970px;
			`}
		}

		max-height: 70vh;
		overflow-y: auto;
		box-shadow: none;
		box-sizing: border-box;
		background-color: ${themeGet('colors.mlcWhite')};
	}

	&__overlay {
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		display: flex;
		position: fixed;
		align-items: center;
		justify-content: center;
		background-color: rgba(0, 0, 0, 0.6);
	}

	&__portal {
		position: fixed;
		z-index: 3;
	}

	&.modal__body {
		z-index: 1;
		display: flex;
		max-height: calc(100vh - 20px);
		flex-direction: column;
		border: thin solid #c8c6c2;
		border-bottom: none;
		justify-content: space-between;
	}
`;

export const ModalContentContainer = styled.div`
	&.modalContentContainer {
		margin: 30px;
	}

	&.modalContentContainer--mlcWhite {
		margin: 10px 30px 30px 30px;
	}

	text-align: ${(props) => props.align};
`;
