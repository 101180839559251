import 'element-closest-polyfill';

import { bars, mlcLogo, times } from '@mlc/symbols';
import { toUpper } from 'lodash';
import React from 'react';
import classnames from 'classnames';
import { themeGet } from 'styled-system';
import { withTheme } from 'styled-components';

import Grid from '../Grid';
import Col from '../Col';
import Card from '../Card';
import TextLink from '../TextLink';
import Svg from '../Svg';
import ScreenReaderText from '../ScreenReaderText';
import Row from '../Row';
import { SvgProps } from '../Svg/Svg';
import { UitkBaseProps } from '../../typings';
import NewTabMsg from '../NewTabMsg/NewTabMsg';

import Navigation from './components/Navigation/Navigation';
import User from './components/User/User';
import { HeaderButton, HeaderLink, HeaderStyle, HeaderText } from './Header.style';

type LogoProps = {
	size?: string;
	/** @ignore */
	theme?: any;
};

const Logo = withTheme(
	({ size, width, height, preserveAspectRatio, ...restProps }: LogoProps & SvgProps) => {
		const logo = themeGet('logo')(restProps) ? themeGet('logo')(restProps) : mlcLogo;
		const themeName = themeGet('name')(restProps) ? themeGet('name')(restProps) : 'mlc';

		return (
			<>
				<ScreenReaderText>{`${toUpper(themeName)} Homepage`}</ScreenReaderText>
				<Svg
					symbol={logo}
					width={width || size || '100%'}
					height={height || size || '60'}
					preserveAspectRatio={preserveAspectRatio}
					alt={`${themeName} homepage`}
					data-uitk={'Header__logo-' + themeName}
				/>
			</>
		);
	}
);

type DisplayMobileNavProps = {
	open?: boolean;
	mobileRef?: React.RefObject<HTMLButtonElement>;
	onClick?: (...args: any[]) => any;
	homepageLink?: string;
	label?: string;
	ariaLabel?: string;
};

const DisplayMobileNav = ({
	children,
	open,
	mobileRef,
	onClick,
	homepageLink,
	label,
	ariaLabel,
}: DisplayMobileNavProps & UitkBaseProps) => {
	return (
		<Card className={classnames('Header__mobile-header', open ? 'is-close' : 'is-open')}>
			<TextLink href={homepageLink} className="Header__mobile-logo">
				<Logo size="40" />
			</TextLink>
			<HeaderButton
				onClick={onClick}
				aria-label={ariaLabel}
				ref={mobileRef}
				className={`Header__mobile-btn--${label}`}
				type="button"
				data-uitk={`Header__mobile-btn--${label}`}
			>
				{label} {children}
			</HeaderButton>
		</Card>
	);
};

type HeaderProps = {
	/** array of object to render top navigation links */
	topNavLinks?: {
		title: string;
		pageurl: string;
		openInNewTab?: boolean;
	}[];
	/** set the logo's link to the homepage url */
	homepageLink?: string;
	/** set if user is logged in or not */
	isLoggedIn?: boolean;
	/** set log in link in user panel */
	loginLink?: string;
	/** set active item from the main navigation, when page loads, NOTE: The item must match the key, and can be separated by a colon (:) to specify sub menu item, E.g: 'Reports:Transactions' */
	activeItem?: string;
	/** choose the color theme from adviser or investor, NOTE: this is for MLC themeName ONLY */
	colorTheme?: 'adviser' | 'investor';
	/** highlight the main navigation's last item */
	highlightLast?: boolean;

	/** array of main navigation to render main navigation list */
	menuConfig: {
		key?: string;
		pageurl?: string;
		subpages?: any[];
		title?: string;
		url?: string;
	}[];
	/** array of user to render user navigation list */
	userConfig: {
		key?: string;
		pageurl?: string;
		subpages?: any[];
		title?: string;
	}[];
};

type Props = HeaderProps & UitkBaseProps;
type State = {
	open: boolean;
};

export class Header extends React.Component<Props, State> {
	openRefMenu: React.RefObject<HTMLButtonElement>;
	closeRefMenu: React.RefObject<HTMLButtonElement>;
	constructor(props) {
		super(props);
		this.state = {
			open: false,
		};
		this.openRefMenu = React.createRef();
		this.closeRefMenu = React.createRef();
	}

	handleMenuOpen = () => {
		this.setState({ open: true }, () => {
			this.closeRefMenu.current.focus();
		});
		document.querySelector('body').style.overflow = 'hidden';
	};

	handleMenuClose = () => {
		this.setState({ open: false }, () => {
			this.openRefMenu.current.focus();
		});
		document.querySelector('body').style.overflow = 'auto';
	};

	handleCloseMainNav = () => {
		document.querySelector('body').style.overflow = 'auto';
	};

	handleEscapeKeyDown = (e) => {
		if (e.key === 'Escape') {
			this.handleMenuClose();
		}
	};

	handleOnLinkClick = () => {
		this.handleMenuClose();
	};

	componentDidMount() {
		document.addEventListener('keydown', this.handleEscapeKeyDown);
		window.addEventListener('resize', this.handleCloseMainNav);
	}

	componentWillUnmount() {
		document.removeEventListener('keydown', this.handleEscapeKeyDown);
		window.removeEventListener('resize', this.handleCloseMainNav);
	}

	render() {
		const {
			className,
			topNavLinks,
			menuConfig,
			userConfig,
			activeItem,
			isLoggedIn,
			colorTheme,
			highlightLast,
			homepageLink,
			loginLink,
			...restProps
		} = this.props;

		const { open } = this.state;
		const iconWidth = '16px';

		return (
			<HeaderStyle
				data-uitk="Header"
				className={classnames(open ? 'is-open' : '', `colorTheme-${colorTheme}`)}
				{...restProps}
			>
				<Grid>
					<Row>
						<Col>
							<Card className="header__wrapper">
								<DisplayMobileNav
									open={open}
									mobileRef={this.openRefMenu}
									onClick={this.handleMenuOpen}
									homepageLink={homepageLink}
									aria-expanded={true}
									label="Menu"
									className="Header__mobile-btn--open"
								>
									<Svg
										className="Header__mobile-icon"
										symbol={bars}
										width={iconWidth}
									/>
								</DisplayMobileNav>

								<Card className="Header__mobile-nav">
									<Card className="Header__mobile-header-wrapper">
										<DisplayMobileNav
											open={open}
											mobileRef={this.closeRefMenu}
											onClick={this.handleMenuClose}
											homepageLink={homepageLink}
											label="Close"
											className="Header__mobile-btn--close"
											ariaLabel="close menu"
											aria-expanded={false}
										>
											<Svg
												className="Header__mobile-icon"
												symbol={times}
												width={iconWidth}
											/>
										</DisplayMobileNav>
									</Card>

									<Card className="Header__panel">
										<Card className="Header__links">
											{topNavLinks &&
												topNavLinks.map(
													({ pageurl, title, openInNewTab }, index) => (
														<HeaderLink
															href={pageurl}
															{...(openInNewTab && {
																target: '_blank',
															})}
															key={pageurl + index}
															data-uitk="Header__links"
														>
															<HeaderText color="inherit">
																{title}
															</HeaderText>
															{openInNewTab && <NewTabMsg />}
														</HeaderLink>
													)
												)}
										</Card>

										<Card className="Header__user">
											<User
												colorTheme={colorTheme}
												isLoggedIn={isLoggedIn}
												userConfig={userConfig}
												loginLink={loginLink}
												onClick={this.handleOnLinkClick}
											/>
										</Card>
									</Card>

									<Card className={classnames('Header__panel')}>
										<TextLink
											href={homepageLink}
											className="Header__logo"
											data-uitk="Header__logo"
										>
											<Logo
												width={'100%'}
												height={'60'}
												preserveAspectRatio="xMinYMax meet"
											/>
										</TextLink>

										<Navigation
											topNavLinks={topNavLinks}
											menuConfig={menuConfig}
											activeItems={activeItem.split(':')}
											colorTheme={colorTheme}
											highlightLast={highlightLast}
											onClick={this.handleOnLinkClick}
										/>
									</Card>
								</Card>
							</Card>
						</Col>
					</Row>
				</Grid>
			</HeaderStyle>
		);
	}

	static defaultProps = {
		highlightLast: false,
		isLoggedIn: true,
		loginLink: '/login',
		homepageLink: '/',
		activeItem: '',
	};
}

/**
 * @component
 */
export default Header;
