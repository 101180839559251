import React from 'react';
import styled from 'styled-components';
import classnames from 'classnames';
import { times } from '@mlc/symbols';

import Svg from '../../../Svg/Svg';
import Heading from '../../../Heading/Heading';
import { UitkBaseProps } from '../../../../typings';

import componentStyle from './ModalHeader.style';
type ModalHeaderProps = {
	onRequestClose: (...args: any[]) => any;
	headerIcon?: object;
	symbol: {
		content?: string;
		id?: string;
		viewBox?: string;
		isMounted?: boolean;
	};
	symbolProps?: object;
	colorTheme?: 'mlcOrange' | 'mlcAqua' | 'mlcRubine' | 'mlcWhite' | 'mlcText' | 'brandColor';
	align?: 'left' | 'center' | 'right';
};
/**
 * Act as a container for elements in the header of a Modal window, including
 * header text and icon, and the 'close' icon.
 */
export const ModalHeader = styled(
	({
		children,
		symbol,
		symbolProps,
		headerIcon,
		onRequestClose,
		className,
		colorTheme,
		align,
	}: ModalHeaderProps & UitkBaseProps) => {
		const light = colorTheme !== 'mlcWhite' && !headerIcon;
		const customCloseIcon = symbol || times;
		return (
			<div
				className={classnames(
					headerIcon ? `modalHeader--plain` : `modalHeader--${colorTheme}`,
					className
				)}
			>
				<button onClick={onRequestClose} aria-label="close" className="closeIconContainer">
					<Svg
						className={classnames(
							'closeIcon',
							light ? 'lightCloseIcon' : 'darkCloseIcon'
						)}
						symbol={customCloseIcon}
						aria-hidden="true"
						height="16px"
						{...symbolProps}
					/>
				</button>
				{headerIcon && <div className="headerIconContainer">{headerIcon}</div>}
				<Heading
					el="h1"
					fontSize="regular"
					paddingLeft={6}
					paddingRight={6}
					paddingTop={light ? 4 : 5}
					paddingBottom={light ? 4 : 0}
					color={light ? 'mlcWhite' : 'mlcVeryDarkGrey'}
					textAlign={align}
				>
					{children}
				</Heading>
			</div>
		);
	}
)`
	${componentStyle};
`;
ModalHeader.defaultProps = {
	symbolProps: { width: '100%' },
};
export default ModalHeader;
