import mlc from './mlc';
import blueprint from './blueprint';
import enevita from './enevita';
import fcOne from './fc-one';
import portfolioFocus from './portfolio-focus';
import premiumChoice from './premium-choice';
import stAndrews from './st-andrews';
import dpm from './dpm';
import plum from './plum';

export default {
	mlc: mlc,
	dpm: dpm,
	enevita: enevita,
	blueprint: blueprint,
	['fc-one']: fcOne,
	['portfolio-focus']: portfolioFocus,
	['premium-choice']: premiumChoice,
	['st-andrews']: stAndrews,
	plum: plum,
};
