import React, { HTMLAttributes } from 'react';
import Highlighter from 'react-highlight-words';
import styled from 'styled-components';
import classnames from 'classnames';

import { UitkBaseProps } from '../../typings';

import componentStyle from './TextHighlighter.style';
const StyledHighlighter = styled(({ className, ...props }) => (
	<Highlighter
		className={classnames('TextHighlighter', className)}
		activeClassName={`__active`}
		highlightClassName={`__textHighlighter`}
		{...props}
	/>
))`
	${componentStyle};
`;
type TextHighlighterProps = {
	activeIndex?: number;
	autoEscape?: boolean;
	highlightclassName?: string | object | any[];
	highlightStyle?: object;
	highlightTag?: string;
	sanitize?: (...args: any[]) => any;
	searchWords?: any[];
	unhighlightStyle?: object;
};
export const TextHighlighter = ({
	activeIndex,
	autoEscape = true,
	children,
	className,
	forwardedRef,
	highlightStyle,
	highlightTag,
	sanitize,
	searchWords = [],
	unhighlightStyle,
	...restProps
}: TextHighlighterProps & UitkBaseProps & HTMLAttributes<HTMLSpanElement>) => {
	const highlighterProps = {
		activeIndex,
		autoEscape,
		highlightStyle,
		highlightTag,
		sanitize,
		searchWords,
		textToHighlight: children,
		unhighlightStyle,
	};
	return (
		<span {...restProps} className={className} ref={forwardedRef}>
			<StyledHighlighter {...highlighterProps} />
		</span>
	);
};
export default TextHighlighter;
