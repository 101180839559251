import React from 'react';
import classnames from 'classnames';

import { UitkBaseProps } from '../../typings';

import { THead } from './TableHead.style.js';
export const TableHead = ({ children, className, forwardedRef, ...restProps }: UitkBaseProps) => (
	<THead {...restProps} className={classnames('TableHead', className)} ref={forwardedRef}>
		{children}
	</THead>
);
export default TableHead;
