/**
 * volumeDown
 * Don't modify this file. It is generated by scripts/build.js and is
 * ignored by git. The source svg file is located in the '../src' directory.
 */
import BrowserSpriteSymbol from 'svg-baker-runtime/browser-symbol';

const symbol = new BrowserSpriteSymbol({
  "id": "symbol-volumeDown-2572467759",
  "use": "symbol-volumeDown-2572467759-usage",
  "viewBox": "0 0 384 512",
  "content": "<symbol viewBox=\"0 0 384 512\" id=\"symbol-volumeDown-2572467759\"><path d=\"M338.23 179.13c-11.58-6.33-26.19-2.16-32.61 9.45-6.39 11.61-2.16 26.2 9.45 32.61C327.98 228.28 336 241.63 336 256s-8.02 27.72-20.92 34.81c-11.61 6.41-15.84 21-9.45 32.61 6.43 11.66 21.05 15.8 32.61 9.45 28.23-15.55 45.77-45 45.77-76.87s-17.54-61.33-45.78-76.87zM231.81 64c-5.91 0-11.92 2.18-16.78 7.05L126.06 160H24c-13.26 0-24 10.74-24 24v144c0 13.25 10.74 24 24 24h102.06l88.97 88.95c4.87 4.87 10.88 7.05 16.78 7.05 12.33 0 24.19-9.52 24.19-24.02V88.02C256 73.51 244.13 64 231.81 64zM208 366.05L145.94 304H48v-96h97.94L208 145.95v220.1z\" /></symbol>"
});


symbol.name = "volumeDown";

export default symbol;
