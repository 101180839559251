import React from 'react';
import classnames from 'classnames';
import styled from 'styled-components';

import { UitkBaseProps } from '../../typings';

import TableHeaderCellStyle from './TableHeaderCell.style';
type TableHeaderCellProps = {
	align?: 'left' | 'right' | 'center';
	scope?: 'row' | 'col' | 'rowgroup' | 'colgroup' | '';
};
export const TableHeaderCell = ({
	align,
	children,
	className,
	forwardedRef,
	scope,
	...restProps
}: UitkBaseProps & TableHeaderCellProps) => (
	<th
		{...restProps}
		className={classnames('TableHeaderCell', `TableHeaderCell`, className)}
		ref={forwardedRef}
		scope={scope}
	>
		{children}
	</th>
);

TableHeaderCell.defaultProps = {
	align: 'left',
	scope: 'col',
};
export default styled(TableHeaderCell)`
	${TableHeaderCellStyle};
`;
