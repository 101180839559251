import React from 'react';
import { check, times } from '@mlc/symbols';
import styled from 'styled-components';
import classnames from 'classnames';

import ScreenReaderText from '../../../ScreenReaderText/ScreenReaderText';
import Svg from '../../../Svg/Svg';
import { UitkBaseProps } from '../../../../typings';

import componentStyle from './PasswordRule.style';
type PasswordRuleProps = {
	valid?: boolean;
	rule?: string;
	idx?: any;
};
export const PasswordRule = styled(
	({ valid, rule, className, idx }: PasswordRuleProps & UitkBaseProps) => (
		<div id={`password_criteria-${idx}`} className={classnames('PasswordRule', className)}>
			<Svg
				className={classnames(
					valid ? 'PasswordRule__icon--show' : 'PasswordRule__icon--hide',
					'PasswordRule--valid'
				)}
				symbol={check}
				height="12px"
			/>
			<Svg
				className={classnames(
					valid ? 'PasswordRule__icon--hide' : 'PasswordRule__icon--show',
					'PasswordRule--invalid'
				)}
				symbol={times}
				height="12px"
			/>
			<span className="PasswordRule__rule"> {rule}</span>
			<ScreenReaderText>{valid ? ', supplied,' : ', not supplied, '}</ScreenReaderText>
		</div>
	)
)`
	${componentStyle};
`;
PasswordRule.defaultProps = {
	valid: false,
};
export default PasswordRule;
