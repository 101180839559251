import { css } from 'styled-components';
import { themeGet } from 'styled-system';

export default () => css`
	border: thin solid ${themeGet('colors.mlcDove')};
	padding: ${themeGet('space.2')}px ${themeGet('space.2')}px ${themeGet('space.2')}px
		${themeGet('space.4')}px;

	.PasswordRule--valid {
		color: ${themeGet('colors.mlcGreen')};
	}
	.PasswordRule--invalid {
		color: ${themeGet('colors.mlcRed')};
	}
	.PasswordRule__icon--hide {
		display: none;
	}
	.PasswordRule__icon--show {
		display: inline;
	}
`;
