import { DafSdkError } from '@nab/daf-common';
import { createInMemoryPersistence } from './persists';
import { EmployeeSsoState } from '../employeeSsoAuthenticate';
import BaseDisplayStrategy from './BaseDisplayStrategy';
import { EmployeeSsoErrorCodes } from '../constants';

declare global {
    interface Window {
        __daf_employee_sso_window_name__: string;
    }
}

const controlledByTestCafe = !!window.localStorage.nativeStorageKey?.startsWith('hammerhead');

export default class PopupStrategy extends BaseDisplayStrategy {
    private static readonly AUTH_COMPLETE_CB_KEY = '__daf_employee_sso_oauth_complete_cb__';

    private static readonly PARENT_WINDOW_IDENTITY = '__daf_employee_sso_secret__';

    private static readonly PARENT_WINDOW_IDENTITY_KEY = '__daf_employee_sso_opener_window__';

    public readonly strategyName = 'popup';

    private readonly popupBlockedError = new DafSdkError(EmployeeSsoErrorCodes.PopupBlocked, 'Popup blocked');

    private readonly popupClosedError = new DafSdkError(EmployeeSsoErrorCodes.PopupClosed, 'Popup closed');

    constructor() {
        super(createInMemoryPersistence());
    }

    private static cleanUp(intervalId): void {
        clearInterval(intervalId);
        delete window[PopupStrategy.PARENT_WINDOW_IDENTITY_KEY];
        delete window[PopupStrategy.AUTH_COMPLETE_CB_KEY];
    }

    public authorize(url: string): Promise<EmployeeSsoState | void> {
        return new Promise((resolve, reject) => {
            window[PopupStrategy.PARENT_WINDOW_IDENTITY_KEY] = PopupStrategy.PARENT_WINDOW_IDENTITY;
            const popupWindow = window.open(url, '_blank', 'location=yes,height=640,width=600,scrollbars=yes,status=yes');

            if (popupWindow) {
                const intervalId = setInterval(() => {
                    if (popupWindow.closed) {
                        PopupStrategy.cleanUp(intervalId);
                        reject(this.popupClosedError);
                    }
                }, 500);

                const onMessage = (e) => {
                    if (e?.data?.type === PopupStrategy.AUTH_COMPLETE_CB_KEY && e?.origin === window.location.origin) {
                        resolve({
                            code: e?.data?.code,
                            state: e?.data?.state,
                        });
                        PopupStrategy.cleanUp(intervalId);
                        window.removeEventListener('message', onMessage);
                    }
                };

                window.addEventListener('message', onMessage);
            } else {
                reject(this.popupBlockedError);
            }
        });
    }

    public shouldBreak(): boolean {
        return PopupStrategy.isOnAuthWindowPopup();
    }

    static isOnAuthWindowPopup(): boolean {
        return (
            window.opener &&
            (controlledByTestCafe ||
                (PopupStrategy.getOpenerOrigin() === window.location.origin &&
                    window.opener[PopupStrategy.PARENT_WINDOW_IDENTITY_KEY] === PopupStrategy.PARENT_WINDOW_IDENTITY))
        );
    }

    static getOpenerOrigin(): string {
        try {
            return window.opener?.location.origin;
        } catch (e) {
            // in case of opener window is on a difference domain, error will be thrown and we return null
            /* istanbul ignore next */
            return null;
        }
    }

    public onAuthorized(authState: EmployeeSsoState): Promise<void> {
        return new Promise((resolve, reject) => {
            if (PopupStrategy.isOnAuthWindowPopup()) {
                window.opener.postMessage(
                    {
                        type: PopupStrategy.AUTH_COMPLETE_CB_KEY,
                        code: authState.code,
                        state: authState.state,
                        error: authState.error,
                    },
                    PopupStrategy.getOpenerOrigin(),
                );
                setImmediate(() => {
                    window.close();
                    resolve();
                });
                return;
            }

            resolve();
        });
    }
}
