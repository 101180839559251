import styled from 'styled-components';
import React from 'react';
import { infoCircle } from '@mlc/symbols';

import passRef from '../../hocs/passRef';
import Svg from '../Svg/Svg';
import { UitkBaseProps } from '../../typings';

import componentStyles from './InformationBox.style';

export const InformationBox = ({
	className,
	forwardedRef,
	children,
	...restProps
}: React.HTMLAttributes<HTMLDivElement> & UitkBaseProps) => {
	return (
		<div className={className} ref={forwardedRef} {...restProps}>
			<span className="InformationBox__info">
				<Svg symbol={infoCircle} />
			</span>
			<div className="InformationBox__Wrapper">{children}</div>
		</div>
	);
};

export default styled(passRef(InformationBox))`
	${componentStyles};
`;
