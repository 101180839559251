/**
 * dpmLogo
 * Don't modify this file. It is generated by scripts/build.js and is
 * ignored by git. The source svg file is located in the '../src' directory.
 */
import BrowserSpriteSymbol from 'svg-baker-runtime/browser-symbol';

const symbol = new BrowserSpriteSymbol({
  "id": "symbol-dpmLogo-600160431",
  "use": "symbol-dpmLogo-600160431-usage",
  "viewBox": "0 0 84 87",
  "content": "<symbol viewBox=\"0 0 84 87\" id=\"symbol-dpmLogo-600160431\"><g id=\"symbol-dpmLogo-600160431_New\" stroke=\"none\" stroke-width=\"1\" fill=\"none\" fill-rule=\"evenodd\"><g id=\"symbol-dpmLogo-600160431_AdviserOnline_Wealth_Alliances\" transform=\"translate(-444 -337)\" fill=\"#067861\"><g id=\"symbol-dpmLogo-600160431_Group-11-Copy\" transform=\"translate(444 335)\"><g id=\"symbol-dpmLogo-600160431_DPM_logo\" transform=\"translate(0 2)\"><path d=\"M65.38 42.943h-2.296v-3.867h2.236c1.163 0 2.358.552 2.358 1.949 0 1.427-1.149 1.918-2.297 1.918zm-.183-4.788h-3.124V49.02h1.011v-5.156h2.343c1.7 0 3.261-.875 3.261-2.84 0-2.27-1.96-2.87-3.49-2.87zm17.364 0l-3.95 9.115-3.952-9.115h-1.515V49.02h1.01v-9.576h.03l4.197 9.576h.444l4.133-9.576h.031v9.576H84V38.155h-1.44zM56.41 43.587c0-3.115-2.236-4.511-5.115-4.511h-2.082v9.023h2.082c2.88 0 5.115-1.397 5.115-4.512zm-8.208-5.432h3.354c5.634 0 5.864 4.634 5.864 5.432s-.23 5.433-5.864 5.433h-3.354V38.155zM16.877 0H0v87h16.877c23.973 0 43.406-19.477 43.406-43.498C60.283 19.477 40.85 0 16.877 0z\" id=\"symbol-dpmLogo-600160431_Fill-1\" /></g></g></g></g></symbol>"
});


symbol.name = "dpmLogo";

export default symbol;
