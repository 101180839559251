import React, { HTMLAttributes } from 'react';
import styled from 'styled-components';

import { UitkBaseProps, Space } from '../../typings';

import componentStyle from './Label.style';

type LabelProps = {
	backgroundColor?: string;
	border?: 'mlcBorderThin' | 'mlcBorderThick';
	borderColor?: string;
	borderRadius?: string;
	color?: string;
	display?: string | object;
	el?: string;
	fontSize?: 'label' | 'caption' | 'button';
	fontWeight?: 'regular' | 'medium' | 'semibold';
	htmlFor?: string;
	margin?: Space;
	marginBottom?: Space;
	marginLeft?: Space;
	marginRight?: Space;
	marginTop?: Space;
	padding?: Space;
	paddingBottom?: Space;
	paddingLeft?: Space;
	paddingRight?: Space;
	paddingTop?: Space;
	textAlign?: string | object;
	variant?: 'normal' | 'caption' | 'button';
	textLink?: boolean;
};
export const Label = ({
	htmlFor,
	el,
	backgroundColor,
	border,
	borderColor,
	borderRadius,
	color,
	display,
	fontSize,
	fontWeight,
	margin,
	marginBottom,
	marginLeft,
	marginRight,
	marginTop,
	padding,
	paddingBottom,
	paddingLeft,
	paddingRight,
	paddingTop,
	textAlign,
	textLink,
	...restProps
}: LabelProps & UitkBaseProps & HTMLAttributes<HTMLSpanElement | HTMLLabelElement>) => {
	const El: any = htmlFor ? 'label' : el || 'span';
	return <El htmlFor={htmlFor} {...restProps} />;
};

export default styled(Label)`
	${componentStyle};
`;
