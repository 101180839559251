import React, { Children, cloneElement, createContext } from 'react';
import withId from '@mlc/with-id';
import styled from 'styled-components';
import classnames from 'classnames';
import { exclamationTriangle } from '@mlc/symbols';

import passRef from '../../hocs/passRef';
import Counter from '../Counter/Counter';
import Svg from '../Svg/Svg';
import Label from '../Label/Label';
import { UitkBaseProps } from '../../typings';
import ScreenReaderText from '../ScreenReaderText/ScreenReaderText';

import componentStyle from './FieldWrapper.style';
import Fieldset from './components/FieldWrapperFieldset';
import Legend from './components/FieldWrapperLegend';
export const FieldWrapperContext = createContext(false);

type FieldWrapperProps = {
	'aria-describedby'?: string;
	disabled?: boolean;
	error?: React.ReactNode;
	errorProps?: {
		[x: string]: any;
	};
	help?: React.ReactNode;
	helpProps?: {
		[x: string]: any;
	};
	id?: string;
	isInvalid?: boolean;
	label?: React.ReactNode;
	labelProps?: {
		[x: string]: any;
	};
	subLabel?: string;
	screenReaderLabel?: string;
	counterMax?: number;
	counterCurrent?: number;
	required?: boolean;
	noModifyLabel?: boolean;
};

export const FieldWrapper = ({
	children,
	className,
	disabled,
	error,
	errorProps = {},
	forwardedRef,
	help,
	helpProps = {},
	id,
	'aria-describedby': additionalHelpId,
	isInvalid,
	label,
	labelProps = {},
	subLabel,
	screenReaderLabel,
	counterMax = 2500,
	counterCurrent,
	required = true,
	noModifyLabel,
	...restProps
}: FieldWrapperProps & UitkBaseProps) => {
	let field = Children.only<any>(children);
	/**
	 * Set up ids for connecting the relevant parts of the DOM using `aria-*`
	 * attributes.
	 */
	const { id: fieldId = `field-${id}` } = field.props;
	const { id: helpId = `help-${id}` } = helpProps;
	const { id: labelId = `label-${id}` } = labelProps;
	const { id: errorId = `error-${id}` } = errorProps;
	const isInvalidCounter = counterCurrent > counterMax;
	isInvalid = isInvalid || isInvalidCounter;
	const isErrorMessageShown = disabled !== true && isInvalid && !!error;
	const isHelpMessageShown = !!help;
	/**
	 * Pass down relevant props and generate `aria-*` props if `field` has a
	 * static indicating that this will be acceptable
	 */
	const isFormField = !!field.type.isFormField;
	const isGroupedFormField = !!field.type.isGroupedFormField;
	if (isFormField || isGroupedFormField) {
		/* get string of "<errorId> <helpId>" for ariaDescribedby otherwise null */
		const ariaDescribedby = (() => {
			if (isErrorMessageShown || isHelpMessageShown || additionalHelpId) {
				return [
					isErrorMessageShown ? errorId : null,
					isHelpMessageShown ? helpId : null,
					additionalHelpId ? additionalHelpId : null,
				]
					.filter(Boolean)
					.join(' ');
			} else {
				return null;
			}
		})();
		const ariaLabelledby = label ? labelId : null;
		field = cloneElement(field, {
			...field.props,
			ariaDescribedby,
			ariaLabelledby,
			disabled,
			id: fieldId,
			isInvalid,
			required,
		});
	}
	const [WrapperEl, LabelEl] = isGroupedFormField ? [Fieldset, Legend] : ['div', Label];
	return (
		<FieldWrapperContext.Provider value={true}>
			<WrapperEl
				{...restProps}
				className={classnames(
					{
						FieldWrapper: true,
						'FieldWrapper--isInvalid': isInvalid,
						'FieldWrapper--isDisabled': disabled,
					},
					className
				)}
				id={id}
				ref={forwardedRef}
			>
				{label && (
					<LabelEl
						htmlFor={isGroupedFormField ? null : fieldId}
						// Required here and below by IE to make element 'accessible'
						// https://www.w3.org/TR/using-aria/#label-support
						// https://msdn.microsoft.com/library/gg701963(v=vs.85).aspx#Accessible_HTML_Elements
						tabIndex={-1}
						{...labelProps}
						id={labelId}
					>
						{label}
						{subLabel && (
							<span className="FieldWrapper__sub-label">{` ${subLabel}`}</span>
						)}
						{screenReaderLabel && (
							<ScreenReaderText>{` ${screenReaderLabel}`}</ScreenReaderText>
						)}
						{required || noModifyLabel ? null : (
							<span className="FieldWrapper__required-label">{` (Optional)`}</span>
						)}
					</LabelEl>
				)}
				<div className="FieldWrapper__body">
					{field}
					{isErrorMessageShown && (
						<p
							className="FieldWrapper__error"
							tabIndex={-1}
							id={errorId}
							{...errorProps}
						>
							<Svg
								className="FieldWrapper__error__icon-errormsg"
								symbol={exclamationTriangle}
							/>
							{error}
						</p>
					)}
					{isHelpMessageShown && (
						<p className="FieldWrapper__help" tabIndex={-1} id={helpId} {...helpProps}>
							{help}
							{counterCurrent >= 0 && (
								<Counter counterMax={counterMax} counterCurrent={counterCurrent} />
							)}
						</p>
					)}
				</div>
			</WrapperEl>
		</FieldWrapperContext.Provider>
	);
};

export default styled(passRef(withId(FieldWrapper)))`
	${componentStyle}
`;
