import React from 'react';
import styled from 'styled-components';
import { Container as Con } from 'styled-bootstrap-grid';
import classnames from 'classnames';

import { UitkBaseProps } from '../../typings';

import componentStyle from './Grid.style';
const GridContainer = styled(Con)`
	${componentStyle};
`;
type GridProps = {
	fluid?: boolean;
	noGutter?: boolean;
};
export class Grid extends React.PureComponent<
	GridProps & UitkBaseProps & React.HTMLAttributes<HTMLDivElement>,
	{}
> {
	render() {
		const {
			children,
			className,
			forwardedRef,
			fluid = false,
			noGutter = false,
			...restProps
		} = this.props;
		return (
			<GridContainer
				className={classnames({
					// eslint-disable-next-line
					Grid__container: true,
					'Grid--no-gutter': noGutter,
					className,
				})}
				ref={forwardedRef}
				fluid={fluid}
				{...restProps}
			>
				{children}
			</GridContainer>
		);
	}
}

export default Grid;
