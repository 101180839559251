import { css } from 'styled-components';
import { themeGet } from 'styled-system';

export default () => {
	return css`
		${themeGet('global.base')};

		display: flex;
	`;
};
