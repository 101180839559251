import { css } from 'styled-components';
import { themeGet } from 'styled-system';

import { typography, textLink } from '../../utils/styleFunctions';

export default () => css`
	${themeGet('global.base')}
	${themeGet('global.list')}
	background-color: inherit;
	color: inherit;
	font-size: inherit;
	font-weight: inherit;
	text-align: inherit;
	text-transform: inherit;

	${textLink}
	${typography}
`;
