import palette from '@mlc/mlc-palette';
import { plumLogo } from '@mlc/symbols';

import { core as mlcCore, bundledStyles as mlcBundledStyles } from './mlc';
// sections marked deprecated are from < version 10.0.0 which are should not cause breaking changes, but are no longer being supported //

const themeColors = {
	brandPrimaryLight: '#572381',
	brandPrimaryDark: '#572381',
	brandSecondaryLight: '#572381',
	brandSecondaryDark: '#572381',
	textColor: palette.mlcText,
	primary: palette.mlcBlack,
	background: palette.mlcWhite,
	bg: palette.mlcWhite,
	errorColor: palette.mlcRed,
	readOnlyColor: palette.mlcGreen,
	hoverColor: palette.mlcAqua,
	focusColor: palette.mlcAqua,
	disabledColor: '#49fb35',
	dontUse: '#49fb35',
	...palette,
};

export const core = {
	...mlcCore,
	name: 'plum',
	fonts: {
		default: 'CorpidC1s-Light, Helvetica, Arial, sans-serif',
		headings: 'CorpidC1s-Bold, Helvetica, Arial, sans-serif',
		buttons: 'CorpidC1s-Bold, Helvetica, Arial, sans-serif',

		// -- deprecated -- //
		fontFamily: '"Helvetica Neue LT Std Roman 55", Helvetica, Arial, sans-serif',
	},
};
const bundledStyles = {
	...mlcBundledStyles(core),
	global: {
		...mlcBundledStyles(core).global,
		link: (props) => `
			color: ${themeColors.brandSecondaryLight};
			text-decoration: underline;
			cursor: pointer;
			&:hover,
			&:focus {
				color: ${themeColors.brandSecondaryDark};
				text-decoration: none;
			}
			
			&:focus {
				/* To overwrite AEM link focus style */
				outline: 1px dotted ${palette.mlcVeryDarkGrey} !important;
			}

			&[href^='tel:'],
			&[href^='sms:'] {
				@media screen and (min-width: ${core.gridTheme.breakpoints.md}px) {
					color: ${palette.mlcVeryDarkGrey};
					pointer-events: none;
					&:hover, &:focus {
						color: ${palette.mlcVeryDarkGrey};
						text-decoration: none;
					}
				}
			}

			@media print {
				&::after {
					content: ${props.href ? ' (+' + props.href + ')' : ''};
					font-size: 0.875em;
					text-decoration: none;
				}
				/* Some links don't make sense to display with generated content */
				&[href^='#']::after,
				&[href^='mailto:']::after,
				&[href^='tel:']::after,
				&[href^='javascript:']::after {
					content: '';
				}
				/* This used to target a .text class by name before the refactor to styled-components */
				& > span {
					text-decoration: underline;
				}
			}
  		`,
		textLink: `
			color: ${themeColors.brandSecondaryLight};
			text-decoration: inherit;
		`,
	},
};

// composeTheme :: (Object, Object, Function) -> Object
export const composeTheme = (colors, base, global) => {
	const bp = base.gridTheme.breakpoints;
	const breakpoints = [bp.xs, bp.sm, bp.md, bp.lg, bp.xl];
	breakpoints.xs = bp.xs;
	breakpoints.sm = bp.md;
	breakpoints.md = bp.md;
	breakpoints.lg = bp.lg;
	breakpoints.xl = bp.xl;

	return {
		...base,
		breakpoints: breakpoints,
		...global,
		colors: {
			...colors,
		},
		logo: plumLogo,
	};
};

export default composeTheme(themeColors, core, bundledStyles);
