import { css } from 'styled-components';
import { themeGet } from 'styled-system';

const buttonSpace = 15;

export default () => css`
	text-decoration: none;
	position: relative;
	color: ${themeGet('colors.mlcText')};
	text-align: left;
	display: flex;
	align-items: center;
	padding: 0 ${buttonSpace}px;
	border: 0;
	width: 100%;
	height: 50px;
	background-color: ${themeGet('colors.mlcWhite')};
	justify-content: space-between;
	cursor: pointer;
	font-family: ${themeGet('fonts.default')};
	font-weight: ${themeGet('fontWeights.medium')};
	font-size: ${themeGet('fontSizes.default')}px;

	&:hover,
	&:focus {
		text-decoration: underline;
		outline: 1px solid transparent;
	}

	&.is-main {
		width: 100%;
		justify-content: space-between;

		@media (min-width: ${themeGet('gridTheme.breakpoints.lg')}px) {
			font-size: ${themeGet('fontSizes.button')}px;
			font-weight: ${themeGet('fontWeights.semibold')};
			font-family: ${themeGet('fonts.buttons')};
		}

		&.is-active {
			text-decoration: underline;

			&:hover,
			&:focus {
				text-decoration: none;
			}
		}
	}

	/* navigation item is actived */
	&.is-sub {
		&.is-active {
			@media (min-width: ${themeGet('gridTheme.breakpoints.lg')}px) {
				z-index: 3;
				position: relative;

				&:after {
					content: '';
					position: absolute;
					left: 0;
					width: medium;
					top: 0;
					bottom: 0;
				}
			}
		}
	}
`;
