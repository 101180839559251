import { css } from 'styled-components';
import { themeGet } from 'styled-system';

export default () => css`
	${themeGet('global.base')}

	.Grid {
		padding-left: ${themeGet('space.2')}px;
		padding-right: ${themeGet('space.2')}px;
	}

	.Grid--no-gutter {
		padding-left: 0;
		padding-right: 0;
	}
`;
