import React from 'react';
import { facebookF, twitter, linkedinIn, youtube } from '@mlc/symbols';

import Card from '../Card';
import Svg from '../Svg';
import Grid from '../Grid';
import Row from '../Row';
import Col from '../Col';
import { UitkBaseProps } from '../../typings';
import NewTabMsg from '../NewTabMsg/NewTabMsg';

import {
	SecondaryNav,
	FooterLink,
	FooterText,
	FooterSecondary,
	InjectedFooterText,
} from './Footer.style';

const socialMedia = [
	{
		name: 'Facebook MLC Australia',
		href: 'https://www.facebook.com/MLCAustralia',
		icon: facebookF,
	},
	{
		name: 'Twitter MLC Australia',
		href: 'https://twitter.com/MLC_Australia',
		icon: twitter,
	},
	{
		name: 'LinkedIn MLC Australia',
		href: 'https://www.linkedin.com/company/mlcaustralia',
		icon: linkedinIn,
	},
	{
		name: 'Youtube MLC Australia',
		href: 'https://www.youtube.com/c/MLCAustralia',
		icon: youtube,
	},
];

export interface FooterProps {
	/** array of object to render links */
	links: {
		title: string;
		pageurl: string;
		openInNewTab?: boolean;
	}[];
	/** optionally replace social media with string */
	variant?: 'lastLogin' | 'socialMedia' | 'none';
	/** value to display if variant is lastLogin */
	lastLoginDate?: string;
	/** component to use as legal content such as AEMContent or simple html */
	legal?: any;
}

export const Footer = ({
	className,
	links,
	variant = 'socialMedia',
	lastLoginDate,
	legal: Legal,
	...restProps
}: UitkBaseProps & FooterProps) => {
	return (
		<footer>
			<Card
				className={className}
				backgroundColor="mlcVeryDarkGrey"
				data-uitk="footer"
				{...restProps}
			>
				<Grid>
					<Row>
						<Col>
							<Card paddingTop={4} paddingBottom={5}>
								<SecondaryNav>
									{links &&
										links.map(({ pageurl, title, openInNewTab }, index) => (
											<FooterLink
												href={pageurl}
												{...(openInNewTab && {
													target: '_blank',
													rel: 'noopener',
												})}
												key={pageurl + index}
												data-uitk="footer-links"
											>
												<FooterText color="inherit">{title}</FooterText>
												{openInNewTab && <NewTabMsg />}
											</FooterLink>
										))}
								</SecondaryNav>
								<Card data-uitk="footer-secondary">
									<FooterSecondary>
										{variant === 'socialMedia' && (
											<>
												<FooterText display="block" marginBottom={2}>
													Connect with us
												</FooterText>
												{socialMedia.map(({ name, href, icon }, index) => (
													<FooterLink
														href={href}
														key={name + index}
														target="_blank"
														rel="noopener"
													>
														<Svg color="inherit" symbol={icon} />
														<NewTabMsg text={name} />
													</FooterLink>
												))}
											</>
										)}
										{variant === 'lastLogin' && lastLoginDate && (
											<FooterText>{lastLoginDate}</FooterText>
										)}
									</FooterSecondary>
								</Card>
							</Card>
						</Col>
					</Row>
				</Grid>
			</Card>
			{Legal && (
				<Card backgroundColor="mlcBlack" data-uitk="footer-legal">
					<Grid>
						<Row>
							<Col>
								<Card paddingTop={4} paddingBottom={6}>
									<InjectedFooterText>
										<Legal />
									</InjectedFooterText>
								</Card>
							</Col>
						</Row>
					</Grid>
				</Card>
			)}
		</footer>
	);
};

export default Footer;
