import { DafSdkError } from '@nab/daf-common';

export interface Persist<T> {
    get(): T;
    set(value: T): void;
    clear(): void;
}

export interface GeneratedCodes {
    codeVerifier: string;
    state: string;
    codeChallenge: string;
}

export function createLocalStoragePersistence(key: string): Persist<GeneratedCodes> {
    return {
        get() {
            try {
                const value = localStorage.getItem(key);
                return value ? JSON.parse(value) : undefined;
            } catch {
                throw DafSdkError.customGeneralError(`Invalid JSON for key: "${key}"`);
            }
        },
        set(value: GeneratedCodes) {
            localStorage.setItem(key, JSON.stringify(value));
        },
        clear() {
            localStorage.removeItem(key);
        },
    };
}

export function createInMemoryPersistence(): Persist<GeneratedCodes> {
    let state: string;
    return {
        get() {
            return state ? JSON.parse(state) : undefined;
        },
        set(value: GeneratedCodes) {
            state = JSON.stringify(value);
        },
        clear() {
            state = undefined;
        },
    };
}
