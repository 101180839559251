export const isMultipleValueOptionSelected = (selectedValue, optionValue) =>
	(selectedValue || []).indexOf(optionValue) !== -1;

export const isSingleValueOptionSelected = (selectedValue, optionValue) =>
	selectedValue === optionValue;

export const singleValueHandleChange = (currentValue, optionValue, onChange) => (event) => {
	onChange(event, {
		checked: event.target.checked,
		value: optionValue,
	});
};

export const multipleValueHandleChange = (currentValue, optionValue, onChange) => (event) => {
	const checked = event.target.checked;
	const tempSet = new Set(currentValue);
	tempSet[checked ? 'add' : 'delete'](optionValue);
	const value = [...tempSet];

	onChange(event, {
		checked,
		value,
	});
};
