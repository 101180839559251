import React, { HTMLAttributes } from 'react';
import styled from 'styled-components';

import Svg from '../Svg/Svg';
import Card from '../Card/Card';
import { UitkBaseProps } from '../../typings';

import componentStyle from './IconText.style';
type IconTextProps = {
	/** Symbol to display, select from @mlc/symbols */
	symbol: {
		content?: string;
		id?: string;
		viewBox?: string;
		isMounted?: boolean;
	};
	/** Props to pass to wrapped Svg if more custom styling needed */
	symbolProps?: object;
	/** Display symbol before or after the text */
	symbolPosition: 'front' | 'end';
	/**
	 * Changes font styles to inherit. To be used when placing inside a something like a button
	 * and other containers where font family is different
	 */
	inheritFontFamily?: boolean;
};
export const IconText = ({
	symbol,
	symbolPosition,
	symbolProps,
	inheritFontFamily,
	children,
	...restProps
}: IconTextProps & UitkBaseProps & HTMLAttributes<HTMLDivElement>) => {
	const inheritStyle = {
		fontFamily: 'inherit',
		lineHeight: 'inherit',
	};
	return (
		<Card
			display="flex"
			{...restProps}
			style={inheritFontFamily ? { ...inheritStyle } : {}}
			alignItems="center"
		>
			{symbolPosition === 'front' && <Svg symbol={symbol} marginRight={2} {...symbolProps} />}

			{children}

			{symbolPosition === 'end' && <Svg symbol={symbol} marginLeft={2} {...symbolProps} />}
		</Card>
	);
};

IconText.defaultProps = {
	inheritFontFamily: false,
	symbolPosition: 'front',
};

export default styled(IconText)`
	${componentStyle}
`;
