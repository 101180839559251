/**
 * minus
 * Don't modify this file. It is generated by scripts/build.js and is
 * ignored by git. The source svg file is located in the '../src' directory.
 */
import BrowserSpriteSymbol from 'svg-baker-runtime/browser-symbol';

const symbol = new BrowserSpriteSymbol({
  "id": "symbol-minus-2975338628",
  "use": "symbol-minus-2975338628-usage",
  "viewBox": "0 0 384 512",
  "content": "<symbol viewBox=\"0 0 384 512\" id=\"symbol-minus-2975338628\"><path d=\"M368 224H16c-8.84 0-16 7.16-16 16v32c0 8.84 7.16 16 16 16h352c8.84 0 16-7.16 16-16v-32c0-8.84-7.16-16-16-16z\" /></symbol>"
});


symbol.name = "minus";

export default symbol;
