import { css } from 'styled-components';
import { themeGet } from 'styled-system';

const bubbleSize = 24;

const themeColorGet = (colorVariant) =>
	colorVariant === 'primary'
		? themeGet('colors.brandPrimaryLight')
		: themeGet('colors.brandSecondaryLight');

const themeShadowGet = (colorVariant) =>
	colorVariant === 'primary'
		? themeGet('shadows.mlcPrimarySelected')
		: themeGet('shadows.mlcSecondarySelected');

export default ({ colorVariant }) => css`
	${themeGet('global.base')}
	display: inline-block;
	margin-bottom: ${themeGet('space.2')}px;

	&:not(:last-child) {
		margin-right: ${themeGet('space.9')}px;
	}

	&.Radio--boxed {
		background-color: ${themeGet('colors.mlcWhite')};
		&:not(:last-child) {
			margin-right: ${themeGet('space.6')}px;
		}

		.Radio__input:checked + label {
			border-color: ${themeColorGet(colorVariant)};
			box-shadow: ${themeShadowGet(colorVariant)};
		}
		.Radio__input:not(:checked) + label {
			border-color: ${themeGet('colors.mlcMediumGrey60')};
		}

		.Radio__input + label {
			height: auto;
			width: 100%;
			align-items: center;
			padding-left: calc(${bubbleSize}px + ${themeGet('space.4')}px);
			padding-right: ${themeGet('space.4')}px;
			padding-top: ${themeGet('space.2')}px;
			padding-bottom: ${themeGet('space.2')}px;
			border: thin solid ${themeGet('colors.mlcMediumGrey60')};

			/* Outer circle styles */
			&::before {
				left: ${themeGet('space.2')}px;
				top: 22px;
				transform: translateY(-50%);
			}
		}

		/* Invalid Radio styles */
		&.Radio--isInvalid {
			.Radio__input:not(:hover):not(:focus) + label {
				border-color: ${themeGet('colors.mlcError')};
				box-shadow: ${themeGet('shadows.mlcGlowError')};
			}
		}

		/* hover and focus styles */
		.Radio__input:hover + label:before,
		.Radio__input:focus + label:before {
			outline: none;
			box-shadow: 0 0 0 5px ${themeGet('colors.mlcWhite')} inset,
				0 0 6px ${themeColorGet(colorVariant)};
		}
	}

	/* hide the browser's default input */
	.Radio__input {
		/* IE needs this */
		opacity: 0;
		margin: 0;
		padding: 0;
		position: absolute;
		cursor: none;

		/* Safari needs this */
		width: 1px;
		height: 1px;

		/* Remote test software needs this */
		z-index: 1;
	}

	/* Radio Label styles */
	.Radio__label {
		display: inline-flex;
	}
	&.Radio--normal .Radio__label {
		border-left: none !important; /* AEM Counter - has 4 levels of specificty so important here is vital */
	}

	.Radio__input + label {
		position: relative;
		padding-left: calc(${bubbleSize}px + ${themeGet('space.2')}px);
		min-height: ${bubbleSize}px;
		line-height: ${bubbleSize}px;
		color: ${themeGet('colors.mlcVeryDarkGrey')};
		cursor: pointer;
		margin-bottom: 0;

		&::before {
			width: ${bubbleSize}px;
			height: ${bubbleSize}px;
			cursor: pointer;
			content: '';
			left: 0;
			position: absolute;
			border-radius: 50%;
		}

		/* Outer circle styles */
		&::before {
			border: thin solid ${themeGet('colors.mlcMediumGrey60')};
			background-color: ${themeGet('colors.mlcWhite')};
		}
	}

	/* state styles */

	/* checked circle styles */
	.Radio__input:checked + label::before {
		border-color: ${themeColorGet(colorVariant)};
		background-color: ${themeColorGet(colorVariant)};
		box-shadow: 0 0 0 5px ${themeGet('colors.mlcWhite')} inset;
	}

	/* hover and focus styles */
	.Radio__input:hover + label:before,
	.Radio__input:focus + label:before {
		outline: none;
		border-color: ${themeColorGet(colorVariant)};
		box-shadow: 0 0 0 5px ${themeGet('colors.mlcWhite')} inset,
			0 0 6px ${themeColorGet(colorVariant)};
	}

	/* Invalid Radio styles */
	&.Radio--isInvalid {
		.Radio__input:not(:hover):not(:focus) + label:before {
			border-color: ${themeGet('colors.mlcError')};
		}

		.Radio__input:not(:hover):not(:focus):checked + label:before {
			background-color: ${themeGet('colors.mlcError')};
		}
	}

	@media print {
		.Radio__input:checked + label::before {
			border: 7px solid ${themeColorGet(colorVariant)};
		}
	}
`;
