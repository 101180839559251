import React, { HTMLAttributes, useEffect, useState } from 'react';
import classnames from 'classnames';

import Label from '../Label';
import Card from '../Card';
import ScreenReaderText from '../ScreenReaderText';
import ScreenReaderMessenger from '../ScreenReaderMessenger';

import {
	LoadingSpinner,
	LoadingWrapper,
	Overlay,
	PercentageWrapper,
	PercentagePosition,
} from './LoadingIndicator.style';

const NormalLoading = ({ message, variant, isUserAction, ...restProps }) => {
	const THIRTY_SECONDS = 30000;
	const [timedMessage, setTimedMessage] = useState(message);

	useEffect(() => {
		setInterval(() => {
			setTimedMessage('');
			setTimedMessage(timedMessage);
		}, THIRTY_SECONDS);
	}, [message, timedMessage]);

	return (
		<LoadingWrapper data-uitk="LoadingIndicator" variant={variant}>
			{isUserAction ? (
				<ScreenReaderText>
					<ScreenReaderMessenger>{timedMessage}</ScreenReaderMessenger>
				</ScreenReaderText>
			) : (
				<ScreenReaderText>{message}</ScreenReaderText>
			)}

			<Card position="relative">
				<LoadingSpinner variant={variant} aria-hidden={true} {...restProps} />
			</Card>
		</LoadingWrapper>
	);
};

const FullscreenLoading = ({
	className,
	colorTheme,
	isOpen,
	message,
	percent,
	variant,
	...restProps
}) => {
	const overlayClassName = `${className}__overlay`;
	const fgColor = colorTheme === 'dark' ? '' : 'mlcWhite';

	return (
		<Overlay
			className={classnames('LoadingIndicator', className)}
			overlayClassName={overlayClassName}
			isOpen={isOpen}
			colorTheme={colorTheme}
		>
			<Card textAlign="center">
				<ScreenReaderMessenger>
					<LoadingWrapper variant={variant}>
						<Card position="relative">
							<LoadingSpinner
								variant={variant}
								colorTheme={colorTheme}
								aria-hidden={true}
								{...restProps}
							/>
							{percent !== undefined && (
								<PercentageWrapper>
									<PercentagePosition>
										<Label variant="caption" color="mlcWhite">
											{percent}%
										</Label>
									</PercentagePosition>
								</PercentageWrapper>
							)}
						</Card>
					</LoadingWrapper>
					<Label variant="caption" color={fgColor} fontSize="tiny">
						{message}
					</Label>
				</ScreenReaderMessenger>
			</Card>
		</Overlay>
	);
};

type LoadingIndicatorProps = {
	/** primary colors of the indicator */
	colorTheme: 'light' | 'dark';
	/** fullscreen only, control the modal state */
	isOpen: boolean;
	/** Should be passed as true when this spinner is rendered as a result of user activity. Determines the screen reader activity for the spinner. */
	isUserAction: boolean;
	/**
	 * * inline is used for buttons, form controls and text
	 * * scaled takes on the dimensions of its parent
	 * * fullscreen creates a modal and renders across the entire page
	 */
	variant: 'inline' | 'scaled' | 'fullscreen';
	/** controls fullscreen text on fullscreen and aria text across variants */
	message: string;
};

export const LoadingIndicator = ({
	variant,
	message,
	...restProps
}: LoadingIndicatorProps & HTMLAttributes<HTMLDivElement> & HTMLAttributes<HTMLSpanElement>) => {
	const Component = variant === 'fullscreen' ? FullscreenLoading : NormalLoading;
	// @ts-ignore
	return <Component variant={variant} message={message} {...restProps} />;
};

LoadingIndicator.defaultProps = {
	colorTheme: 'light',
	isOpen: false,
	message: 'Loading...',
	variant: 'inline',
	isUserAction: false,
};

export default LoadingIndicator;
