import styled, { css } from 'styled-components';
import { themeGet } from 'styled-system';

const MenuAlignment = ({ alignMenu, menuMarginTop }) => {
	let left = 'auto',
		right = 'auto',
		translateX = '';

	if (alignMenu === 'left') {
		right = 0;
	} else if (alignMenu === 'right') {
		left = 0;
	} else if (alignMenu === 'center') {
		left = '50%';
		translateX = '-50%';
	}

	return css`
		.Menu__list {
			left: ${left};
			right: ${right};
			transform: translateX(${translateX});

			&.is-main {
				margin-top: ${menuMarginTop};
			}
		}
	`;
};

export const MenuContainer = styled.div`
	position: relative;
	display: inline;
	${MenuAlignment}
	.Menu__list {
		display: inline-block;
		margin-top: 0;
		list-style: none;
		padding: 0;
		background-color: ${themeGet('colors.mlcWhite')};

		&.is-main {
			transition: all ease-in-out 0.1s;
			visibility: hidden;
			opacity: 0;
			z-index: 1;
			position: absolute;
			min-width: 185px;
			border: thin solid ${themeGet('colors.mlcMediumGrey60')};
			box-shadow: 2px 2px 10px 4px rgba(0, 0, 0, 0.1);
			&.is-open {
				visibility: visible;
				opacity: 1;
				transition: all 0.1s ease-in-out;
			}
		}
	}
`;
