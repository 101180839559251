import { DafEnvironments, DafSdkErrorCodes } from './enums';
import { DafApiErrorDetail, NabApiErrorResponse, DafEnvironmentConfig, LegacyDafApiErrorResponse } from './types';

const environmentConfigMapping: Record<DafEnvironments, Partial<DafEnvironmentConfig>> = {
    [DafEnvironments.LOCAL]: {
        kongCustomerGatewayBaseUrl: 'https://localhost:7777',
        kongStaffGatewayBaseUrl: 'https://localhost:7777',
        axwayBaseUrl: 'https://localhost:7777',
    },
    [DafEnvironments.DOCKER]: {
        kongCustomerGatewayBaseUrl: 'https://localhost:7777',
        kongStaffGatewayBaseUrl: 'https://localhost:7777',
        axwayBaseUrl: 'https://localhost:7777',
    },
    [DafEnvironments.JENKINS]: {
        kongCustomerGatewayBaseUrl: 'https://reference-shell-mocks:7777',
        kongStaffGatewayBaseUrl: 'https://reference-shell-mocks:7777',
        axwayBaseUrl: 'https://reference-shell-mocks:7777',
    },
    [DafEnvironments.SIT1]: {
        kongCustomerGatewayBaseUrl: 'https://customer-sit1.api.extnp.nab.com.au',
        kongStaffGatewayBaseUrl: 'https://sit1.staff.api.extnp.national.com.au',
        axwayBaseUrl: 'https://sit1.api.extnp.nab.com.au',
    },
    [DafEnvironments.SIT4]: {
        kongCustomerGatewayBaseUrl: 'https://customer-sit4.api.extnp.nab.com.au',
        kongStaffGatewayBaseUrl: 'https://sit4.staff.api.extnp.national.com.au',
        axwayBaseUrl: 'https://sit4.api.extnp.nab.com.au',
    },
    [DafEnvironments.SIT5]: {
        kongCustomerGatewayBaseUrl: 'https://customer-sit5.api.extnp.nab.com.au',
        kongStaffGatewayBaseUrl: 'https://sit5.staff.api.extnp.national.com.au',
        axwayBaseUrl: 'https://sit5.api.extnp.nab.com.au',
    },
    [DafEnvironments.PPTE]: {
        kongCustomerGatewayBaseUrl: 'https://customer.api-ppte.nabtest.com.au',
        kongStaffGatewayBaseUrl: 'https://ppte.staff.api.extnp.national.com.au',
        axwayBaseUrl: 'https://ppte.api.extnp.nab.com.au',
    },
    [DafEnvironments.STAGING]: {
        kongCustomerGatewayBaseUrl: 'https://customer.api.nab.com.au',
        kongStaffGatewayBaseUrl: 'https://pilot.staff.api.ext.national.com.au',
        axwayBaseUrl: 'https://api.nab.com.au',
    },
    [DafEnvironments.PROD]: {
        kongCustomerGatewayBaseUrl: 'https://customer.api.nab.com.au',
        kongStaffGatewayBaseUrl: 'https://prod.staff.api.ext.national.com.au',
        axwayBaseUrl: 'https://api.nab.com.au',
    },
    [DafEnvironments.NFT]: {
        kongCustomerGatewayBaseUrl: 'https://customer-nft.api.extnp.nab.com.au',
        kongStaffGatewayBaseUrl: 'https://nft.staff.api.extnp.national.com.au',
        axwayBaseUrl: 'https://api-nft.nabtest.com.au/',
    },
};

export function getEnvironmentConfig(
    environment: DafEnvironments = DafEnvironments.PROD,
    appSpecificEnvironmentMapping?: Partial<Record<DafEnvironments.LOCAL | DafEnvironments.JENKINS | DafEnvironments.DOCKER, Partial<DafEnvironmentConfig>>>,
): Partial<DafEnvironmentConfig> {
    if (appSpecificEnvironmentMapping && appSpecificEnvironmentMapping[environment]) {
        return appSpecificEnvironmentMapping[environment];
    }

    return environmentConfigMapping[environment];
}

export const resolveAuthorizationHeader = (token: string) => ({
    Authorization: `Bearer ${token}`,
});
export class DafSdkError<T extends string = DafSdkErrorCodes, R = unknown> extends Error {
    public readonly errorCode: T;

    public readonly errorData: R;

    public static readonly GENERAL_ERROR = DafSdkError.customGeneralError('Something wrong happened');

    public static readonly MISSING_ACCESS_TOKEN_ERROR = new DafSdkError(DafSdkErrorCodes.IllegalArgument, 'Access token is missing');

    public static readonly ERROR_NAME = 'DafSdkError';

    constructor(errorCode: T, message: string, errorData: R = null) {
        super(message);
        this.errorCode = errorCode;
        this.errorData = errorData;
        this.name = DafSdkError.ERROR_NAME;
    }

    public static customGeneralError(message: string, stack?: string): DafSdkError {
        const sdkError = new DafSdkError(DafSdkErrorCodes.GeneralError, message);
        sdkError.stack = stack || sdkError.stack;
        return sdkError;
    }

    public static mapFromHttpResponse(httpCode: number | string, errorMessage): DafSdkError {
        const dafErrorCodeMap = {
            '401': DafSdkErrorCodes.Unauthenticated,
            '403': DafSdkErrorCodes.Unauthorised,
            '500': DafSdkErrorCodes.InternalServerError,
            '429': DafSdkErrorCodes.TpsLimitReach,
            '400': DafSdkErrorCodes.BadRequest,
        };

        return new DafSdkError(dafErrorCodeMap[httpCode] || DafSdkErrorCodes.GeneralError, errorMessage);
    }

    public static mapFromNabApiErrorResponse({ errorId, message, details }: NabApiErrorResponse): DafSdkError<string, DafApiErrorDetail> {
        return new DafSdkError(errorId, message, details);
    }

    public static mapFromLegacyNabApiErrorResponse({ error, error_description }: LegacyDafApiErrorResponse): DafSdkError<string> {
        return new DafSdkError(error, error_description);
    }

    public static isNabApiErrorResponse(data: NabApiErrorResponse) {
        return !!(data?.errorId && data?.message);
    }

    public static isLegacyDafApiErrorResponse(data: LegacyDafApiErrorResponse) {
        return !!(data?.error && data?.error_description);
    }

    public static handleApiError(error: any) {
        if (error.isAxiosError) {
            const { status, data } = error?.response;
            if (DafSdkError.isNabApiErrorResponse(data)) {
                throw DafSdkError.mapFromNabApiErrorResponse(data);
            }

            if (DafSdkError.isLegacyDafApiErrorResponse(data)) {
                throw DafSdkError.mapFromLegacyNabApiErrorResponse(data);
            }

            throw DafSdkError.mapFromHttpResponse(status, error?.message);
        } else {
            throw DafSdkError.customGeneralError(error?.message, error?.stack);
        }
    }

    public static isDafSdkError(e: Error) {
        return e.name === DafSdkError.ERROR_NAME;
    }
}
