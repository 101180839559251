import React from 'react';
import classnames from 'classnames';
import styled from 'styled-components';

import { UitkBaseProps } from '../../typings';

import TableCellStyle from './TableCell.style';
type TableCellProps = {
	align?: 'left' | 'right' | 'center';
	el?: 'td' | 'th';
};
export const TableCell = ({
	align,
	children,
	className,
	forwardedRef,
	el,
	...restProps
}: UitkBaseProps & TableCellProps) => {
	const El = el;
	return (
		<El
			{...restProps}
			className={classnames(`TableCell--${align}`, className)}
			ref={forwardedRef}
		>
			{children}
		</El>
	);
};
TableCell.defaultProps = {
	align: 'left',
	el: 'td',
};

export default styled(TableCell)`
	${TableCellStyle};
`;
