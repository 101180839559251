import { css } from 'styled-components';
import { themeGet } from 'styled-system';

export default (props) => css`
	background-color: transparent;
	border: none;
	padding: 0;
	${themeGet('global.base')};
	${themeGet('global.link')};
	text-align: ${props.textAlign};
`;
