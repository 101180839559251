import styled, { css } from 'styled-components';
import { themeGet } from 'styled-system';

const DropdownTheme = ({ hoverColor, hoverDecoration, textDecoration }) => {
	const color = hoverColor ? hoverColor : 'brandPrimaryLight';

	return css`
		/* navigation item is open */
		&.is-open {
			.Dropdowns__link.is-main {
				color: ${themeGet(`colors.${color}`)};

				&:hover,
				&:active,
				&:focus {
					color: ${themeGet(`colors.${color}`)};
				}
			}
		}

		/* set the dropdown item color theme */
		.Dropdowns__link {
			text-decoration: ${textDecoration};
			&:hover,
			&:active,
			&:focus {
				text-decoration: ${hoverDecoration};
				color: ${themeGet(`colors.${color}`)};
			}

			&:focus {
				outline: 1px solid transparent;
			}

			&:after {
				background-color: ${themeGet(`colors.${color}`)};
			}
		}
	`;
};

export const Item = styled.li`
	${DropdownTheme}
	position: relative;
	&.is-main {
		border: thin solid transparent;
		&.is-open {
			.Menu__sublist.is-main {
				visibility: visible;
				opacity: 1;
				transition: all 0.1s ease-in-out;
			}
		}
	}

	&.is-sub {
		border-bottom: thin solid ${themeGet('colors.mlcMediumGrey60')};

		&:last-child {
			border-bottom: none;
		}

		&.is-open {
			margin: 0 !important;
			.Menu__sublist {
				visibility: visible;
				opacity: 1;
				height: 100%;

				.Dropdowns__item {
					&:last-child {
						border-bottom: 0;
					}
				}
			}
		}

		.Menu__sublist {
			.Dropdowns__item {
				&:first-child {
					border-top: thin solid ${themeGet('colors.mlcMediumGrey60')};
				}
			}
		}
	}
	.Dropdowns__icon-wrapper--desktop {
		margin-left: 10px;
		display: block;
	}

	.Dropdowns__link.is-ButtonStyle {
		&:after {
			display: none !important;
		}
	}

	.Menu__sublist {
		margin-top: 0;
		list-style: none;
		padding: 0;
		background-color: ${themeGet('colors.mlcWhite')};

		&.is-main {
			transition: all ease-in-out 0.1s;
			visibility: hidden;
			opacity: 0;
			z-index: 1;
			position: absolute;
			min-width: 185px;
			border: thin solid ${themeGet('colors.mlcMediumGrey60')};
			box-shadow: 2px 2px 10px 4px rgba(0, 0, 0, 0.1);
		}
		&.is-sub {
			position: relative;
			left: initial;
			transform: translateX(0);
			box-shadow: none;
			margin-left: 0;
			margin-right: 0;
			background-color: ${themeGet('colors.mlcCore')};
			visibility: hidden;
			height: 0;
			opacity: 0;
			transition: all ease-in-out 0.01s;

			.Dropdowns__link {
				background-color: ${themeGet('colors.mlcAlmostWhite')};
			}

			&:after {
				display: none;
			}
		}
	}
	&:after {
		display: none !important;
	}
`;
