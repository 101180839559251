/**
 * plumLogo
 * Don't modify this file. It is generated by scripts/build.js and is
 * ignored by git. The source svg file is located in the '../src' directory.
 */
import BrowserSpriteSymbol from 'svg-baker-runtime/browser-symbol';

const symbol = new BrowserSpriteSymbol({
  "id": "symbol-plumLogo-2456831060",
  "use": "symbol-plumLogo-2456831060-usage",
  "viewBox": "0 0 263.21 151.27",
  "content": "<symbol viewBox=\"0 0 263.21 151.27\" id=\"symbol-plumLogo-2456831060\"><style>#symbol-plumLogo-2456831060 .st2{fill-rule:evenodd;clip-rule:evenodd;fill:#fff}</style><path d=\"M6.98 6.82c0 66.74 54.1 120.85 120.84 120.85 66.74 0 120.85-54.1 120.85-120.85H6.98z\" fill=\"#fff\" /><path d=\"M16.96 6.82c0 61.22 49.63 110.86 110.86 110.86 61.22 0 110.86-49.63 110.86-110.86H16.96z\" fill=\"#572381\" /><path class=\"st2\" d=\"M101.89 65.14h-3.43v2.89h13.95v-2.89h-3.39v-35.2H98.46v2.85h3.43v32.35zM130.34 64.39c-1.17 2.12-3.27 3.62-5.67 4-1.31.21-2.66.14-3.97-.01-1.13-.13-2.26-.51-3.19-1.19-2.18-1.61-2.39-4.69-2.4-7.17V45.6h-3.44v-2.83h10.5V58.6c0 1.43-.04 1.42.08 2.57.12 1.18.49 2.52 1.65 3.1 1.01.5 2.33.39 3.39.08 2.73-.8 2.96-4.55 3.02-6.86V42.78h7.11v25.06h-7.06v-3.45M150.94 46.22v-3.45h-10.52v2.83h3.39l-.02 22.24h7.16V52.95c.12-1.74.27-6.94 4.19-6.94 3.15 0 3.28 2.46 3.37 5.02v16.81h7.11v-14.4c.1-2.17.26-7.43 4.19-7.43 3.15 0 3.29 2.46 3.37 5.02v16.81h7.08V50.23c-.06-5.9-2.39-8.05-7.27-8.05-4.12 0-6.22 1.97-7.72 4.55-.77-3.12-3.41-4.55-6.89-4.55-3.5-.01-5.84 1.28-7.44 4.04M191.11 64.19c0 2.3-1.86 4.16-4.16 4.16s-4.16-1.86-4.16-4.16 1.86-4.16 4.16-4.16 4.16 1.86 4.16 4.16z\" /><path class=\"st2\" d=\"M81.77 54.36c0-2.63-.2-8.44 4.49-8.44 2.79 0 4.67 2.27 4.67 9.46 0 7.2-1.77 9.58-4.67 9.58-4.17 0-4.49-4.46-4.49-8.09v-2.51zm-.38-11.58H70.87v2.85h3.44v33.98h7.08v-14.3c1.24 1.72 2.95 3.19 6.57 3.19 5.43 0 10.66-3.8 10.66-13.15 0-7.58-3.48-13.16-10.1-13.16-2.29 0-5.3.64-7.13 3.51v-2.92z\" /></symbol>"
});


symbol.name = "plumLogo";

export default symbol;
