import React, { HTMLAttributes } from 'react';

import { defaultFormat } from '../BareFormattedInput/BareFormattedInput';
import Input from '../Input';
import passRef from '../../hocs/passRef';
import { UitkBaseProps } from '../../typings';
export const format = {
	...defaultFormat,
	mask: [/\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/],
};

export type TFNInputProps = {
	isInvalid?: boolean;
	variant?: 'boxed' | 'normal';
	onChange?: (...args: any[]) => any;
	readOnly?: boolean;
	value?: string;
	isLoading?: boolean;
};

export const TFNInput = ({
	forwardedRef,
	...restProps
}: UitkBaseProps & TFNInputProps & HTMLAttributes<HTMLInputElement>) => {
	return <Input ref={forwardedRef} {...restProps} format={format} />;
};
TFNInput.isFormField = true;

export default passRef(TFNInput);
