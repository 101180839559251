import React from 'react';

import { UitkBaseProps } from '../../typings';
import ScreenReaderMessenger from '../ScreenReaderMessenger';

import { calculateOffset } from './DisplayUtils';
import { NavCounter, PaginatorComponent, SelectionMenu } from './Paginator.style';
import { PaginatorListItem, PREV_NEXT_VARIANT } from './components/PaginatorListItem';

type PaginatorProps = {
	itemsPerPage?: number;
	/** A callback function that will be invoked when a new page is selected:
	 *
	 * **`onPageSelect(targetStartingIndex, targetEndingIndex)`**
	 *
	 * The paginator will perform an await on this function before updating the selected page in the menu.
	 *
	 * The parameters are dynamic and based on the `itemsPerPage` prop,
	 * e.g. if itemsPerPage is 25, and page 3 is selected, we get `onPageSelect(50, 75)`*/
	onPageSelect: (...args: any[]) => any;
	/** The total number of items in your *entire* dataset, e.g. Showing 1 - 25 of **10562** results.*/
	totalItems: number;
};
type PaginatorState = {
	currentPage: number;
};

type Props = PaginatorProps & UitkBaseProps;
export class Paginator extends React.Component<Props, PaginatorState> {
	SHORT_MENU_PAGE_LIMIT = 6;
	ELLIPSIS_MINIMUM_THRESHOLD = 2;
	firstPage = 0;
	totalPages: number;
	totalItems: number;
	itemsPerPage: number;
	onPageSelect: (...args: any[]) => any;
	lastPage: number;
	ELLIPSIS_MAXIMUM_THRESHOLD: number;
	counterRef: React.RefObject<HTMLDivElement>;
	constructor(props) {
		super(props);
		this.state = { currentPage: this.firstPage };
		this.totalPages = Math.ceil(props.totalItems / props.itemsPerPage);
		this.totalItems = props.totalItems;
		this.itemsPerPage = props.itemsPerPage;
		this.onPageSelect = props.onPageSelect;
		this.lastPage = this.totalPages - 1;
		this.ELLIPSIS_MAXIMUM_THRESHOLD = this.totalPages - 3;
		this.counterRef = React.createRef();
	}
	render() {
		return (
			<>
				{this.buildPaginationMenu(true)}
				{this.props.children}
				{this.buildPaginationMenu(false)}
			</>
		);
	}
	buildPaginationMenu(withCounterRef) {
		return (
			<PaginatorComponent aria-label="Pagination">
				<ScreenReaderMessenger role="navigation">
					{this.createPageSelectionMenu()}
					{this.createNavCounter(withCounterRef)}
				</ScreenReaderMessenger>
			</PaginatorComponent>
		);
	}
	createNavCounter(withCounterRef) {
		if (this.totalItems === 0) {
			return null;
		}
		const startIndex = this.state.currentPage * this.itemsPerPage;
		const startIndexForDisplay = startIndex + 1;
		const endIndexForDisplay =
			this.totalItems < this.itemsPerPage || this.totalItems < startIndex + this.itemsPerPage
				? this.totalItems
				: startIndex + this.itemsPerPage;
		const ref = withCounterRef ? this.counterRef : undefined;
		return (
			<NavCounter ref={ref} tabIndex="-1">
				{`Showing ${startIndexForDisplay} to ${endIndexForDisplay} of ${this.totalItems} results`}
			</NavCounter>
		);
	}
	createPageSelectionMenu() {
		if (this.totalPages === 1) {
			return <></>;
		}
		const pages = [];
		const nextPageIndex = this.state.currentPage + 2;
		const prevPageIndex = this.state.currentPage;
		if (this.totalPages < this.SHORT_MENU_PAGE_LIMIT) {
			this.generateSmallPageMenu(pages);
		} else {
			this.generateLargePageMenu(pages);
		}
		return (
			<SelectionMenu>
				<PaginatorListItem
					variant={PREV_NEXT_VARIANT.PREV}
					isVisible={this.state.currentPage > this.firstPage}
					onClick={() => this.goToPrevPage()}
				>
					{prevPageIndex}
				</PaginatorListItem>
				{pages}
				<PaginatorListItem
					variant={PREV_NEXT_VARIANT.NEXT}
					isVisible={this.state.currentPage < this.lastPage}
					onClick={() => this.goToNextPage()}
				>
					{nextPageIndex}
				</PaginatorListItem>
			</SelectionMenu>
		);
	}
	generateSmallPageMenu(pages) {
		for (let i = 0; i < this.totalPages; i++) {
			pages.push(
				<PaginatorListItem
					isSelectedItem={i === this.state.currentPage}
					key={i}
					onClick={() => this.selectPage(i)}
				>
					{i + 1}
				</PaginatorListItem>
			);
		}
	}
	generateLargePageMenu(pages) {
		// Generate 1 ...
		pages.push(
			<PaginatorListItem
				isSelectedItem={this.firstPage == this.state.currentPage}
				totalPages={this.totalPages}
				key={this.firstPage}
				onClick={() => this.selectPage(this.firstPage)}
			>
				{this.firstPage + 1}
			</PaginatorListItem>
		);
		if (this.state.currentPage > this.ELLIPSIS_MINIMUM_THRESHOLD) {
			pages.push(<React.Fragment key="startEllipsis">...</React.Fragment>);
		}
		// Generate middle pages
		let offset = calculateOffset(this.state.currentPage, this.lastPage);
		for (let i = 0; i < 3; offset++, i++) {
			const pageNumber = this.state.currentPage + offset;
			pages.push(
				<PaginatorListItem
					isSelectedItem={pageNumber == this.state.currentPage}
					totalPages={this.totalPages}
					key={pageNumber}
					onClick={() => this.selectPage(pageNumber)}
				>
					{pageNumber + 1}
				</PaginatorListItem>
			);
		}
		// Generate ... lastPage
		if (this.state.currentPage < this.ELLIPSIS_MAXIMUM_THRESHOLD) {
			pages.push(<React.Fragment key="endEllipsis">...</React.Fragment>);
		}
		pages.push(
			<PaginatorListItem
				isSelectedItem={this.lastPage == this.state.currentPage}
				totalPages={this.totalPages}
				key={this.lastPage}
				onClick={() => this.selectPage(this.lastPage)}
			>
				{this.lastPage + 1}
			</PaginatorListItem>
		);
	}
	goToNextPage() {
		this.selectPage(this.state.currentPage + 1);
	}
	goToPrevPage() {
		this.selectPage(this.state.currentPage - 1);
	}
	async selectPage(index) {
		await this.onPageSelect(
			index * this.itemsPerPage,
			index * this.itemsPerPage + this.itemsPerPage
		);
		this.setState({ currentPage: index }, () => {
			this.counterRef.current.focus();
		});
	}

	static defaultProps = {
		itemsPerPage: 25,
	};
}
export default Paginator;
