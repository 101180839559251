import { css } from 'styled-components';
import { themeGet } from 'styled-system';

export default ({ isInvalid }) => {
	const theme = isInvalid ? 'colors.mlcError' : 'colors.brandSecondaryLight';
	return css`
		${themeGet('global.base')}
		display: flex;
		margin-bottom: 20px;
		cursor: pointer;

		/* BASE */
		.Checkbox__positionedContext {
			display: inline-block;
			/* Base dimensions so that it is possible to scale with text */
			width: 24px;
			min-width: 24px;
			height: 24px;
			/* Align better with text by lowering by 2.5px (at default font size of 16) */
			vertical-align: -${2.5 / 16}em;
			/* Reduce size of Checkbox to 15px (at default font size) for better */
			/* padding: ${0.5 / 16}em; */
			position: absolute;
		}
		.Checkbox__label {
			padding-left: ${24 + 10}px;
			min-height: 24px;
			margin-bottom: 0;
			color: ${themeGet('colors.mlcVeryDarkGrey')};
			cursor: pointer;
		}
		input {
			/* IE needs this */
			opacity: 0;
			margin: 0;
			padding: 0;
			position: absolute;
			cursor: none;

			/* Safari needs this */
			width: 1px;
			height: 1px;

			&:focus {
				outline-style: none;
			}
		}
		.Checkbox__inputDisplayed {
			border: thin solid ${themeGet('colors.mlcWallaby')};
			background-color: ${themeGet('colors.mlcWhite')};

			position: absolute;
			pointer-events: none;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			transition-property: color, border-color, box-shadow;
			transition-duration: 100ms;
			transition-timing-function: linear;
			@media print {
				border: 2px solid ${themeGet('colors.mlcWallaby')};
			}
		}
		.Checkbox__mark {
			color: ${themeGet('colors.brandSecondaryLight')};
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			pointer-events: none;
			display: flex;
			align-items: center;
			justify-content: center;
		}

		/* STATES */
		/* hover/focus */
		input:focus ~ .Checkbox__inputDisplayed,
		&:hover .Checkbox__inputDisplayed {
			border-color: ${themeGet(theme)};
			box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset,
				0 0 4px ${themeGet(theme)};
		}
		/* checked */
		&.Checkbox--isChecked {
			.Checkbox__inputDisplayed {
				border-color: ${themeGet(theme)};
			}
		}
		/* unchecked */
		&.Checkbox--isUnchecked {
			.Checkbox__inputDisplayed {
				border-color: ${themeGet(theme)};
			}
		}
	`;
};
