import React, { Component } from 'react';
import { defaultTo, isEqual, noop, fill } from 'lodash';
import { createTextMaskInputElement } from 'text-mask-core';
import classnames from 'classnames';

import passRef from '../../hocs/passRef';
import { UitkBaseProps, UitkStatics, PassRefProps } from '../../typings';

export const defaultFormat = {
	guide: false,
	keepCharPositions: false,
	mask: (value) => fill(value.split(''), /.*/),
	pipe: (value) => value,
	placeholderChar: '\u2000',
	showMask: false,
};

interface BareFormattedInputProps {
	ariaDescribedby?: string;
	ariaLabelledby?: string;
	format?: {
		guide?: boolean;
		keepCharPositions?: boolean;
		mask?:
			| any[]
			| ((...args: any[]) => any)
			| boolean
			| {
					mask?: any[] | ((...args: any[]) => any);
					pipe?: (...args: any[]) => any;
			  };
		pipe?: (...args: any[]) => any;
		placeholderChar?: string;
		showMask?: boolean;
	};
	unformatMask?: any;
	formNoValidate?: boolean;
	onChange?: (...args: any[]) => any;
	value?: string;
}
/**
 * BareFormattedInput implements
 * [text-mask](https://github.com/text-mask/text-mask/). Text mask options are
 * passed in via the `format` prop. This is a base component, primarily for
 * internal use (e.g by Input with "format" prop and other components built on top of
 * it).It outputs an unstyled, uncontrolled input and the value is managed by
 * a text mask which handles formatting the value.
 */
export class BareFormattedInput extends Component<
	BareFormattedInputProps & UitkBaseProps & UitkStatics & PassRefProps
> {
	public ref: any;
	public textMask: any;

	public static defaultProps: Partial<BareFormattedInputProps> = {
		// Formatting defaults, particularly `mask` and `pipe` are intended to be
		// overridden by components with specific formatting needs
		format: defaultFormat,
		formNoValidate: true,
		onChange: noop,
		value: '',
		unformatMask: /[^0-9.]/g,
	};

	constructor(props) {
		super(props);
		this.ref = {};
		this.ref.current = null;
	}
	// based on type of ref supplied attempt to find parity
	handleRef(input) {
		const forwardedRef = this.props.forwardedRef;
		if (input) {
			// adapt ref types
			if (typeof forwardedRef === 'function') {
				this.props.forwardedRef(input); // excecute a callback ref
			} else if (typeof forwardedRef === 'object' && forwardedRef !== null) {
				forwardedRef.current = input; // assign an object ref
			}
			// assign own ref
			if (this.ref.current == null) {
				this.ref.current = input;
			}
		}
	}
	render() {
		const { format, forwardedRef, value, className, unformatMask, ...restProps } = this.props;
		const defaultValue = defaultTo(value, '');
		return (
			<input
				{...restProps}
				className={classnames('BareFormattedInput', className)}
				defaultValue={defaultValue}
				onChange={this.onChange}
				ref={this.handleRef.bind(this)}
			/>
		);
	}
	componentDidMount() {
		this.initTextMask();
		this.updateTextMask();
	}
	componentDidUpdate(prevProps) {
		const isFormatChanged = !isEqual(prevProps.format, this.props.format);
		if (isFormatChanged) {
			this.initTextMask();
		}
		this.updateTextMask();
	}
	initTextMask() {
		const { ref } = this;
		this.textMask = createTextMaskInputElement({
			inputElement: ref.current,
			...this.props.format,
		});
	}
	updateTextMask() {
		const { props, textMask } = this;
		const safeValue = defaultTo(props.value, '');
		textMask.update(safeValue);
	}
	onChange = (event) => {
		const { props, ref, textMask } = this;
		textMask.update();
		props.onChange(event, {
			value: ref.current.value,
			unformattedValue: ref.current.value.replace(props.unformatMask, ''),
		});
	};
}

export default passRef(BareFormattedInput);
