import { css } from 'styled-components';
import { themeGet } from 'styled-system';

export default (props) => {
	return css`
		padding: 10px;
		vertical-align: top;
		font-size: ${themeGet('fontSizes.small')}px;
		line-height: ${themeGet('lineHeights.small')};
		background-color: ${themeGet('colors.mlcAlmostWhite')};
		border-bottom: thin solid ${themeGet('colors.mlcMediumGrey')};

		@media screen {
			color: ${themeGet('colors.mlcVeryDarkGrey')};
		}
		@media print {
			color: black;
		}

		text-align: ${props.align};
	`;
};
