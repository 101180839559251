import React from 'react';
import styled from 'styled-components';
import classnames from 'classnames';

import { UitkBaseProps } from '../../typings';

import TableFootStyle from './TableFoot.style';
type TableFootProps = {
	noLine?: boolean;
};
export const TableFoot = ({
	noLine,
	children,
	className,
	forwardedRef,
	...restProps
}: UitkBaseProps & TableFootProps) => (
	<tfoot {...restProps} className={classnames('TableFoot', className)} ref={forwardedRef}>
		{children}
	</tfoot>
);

export default styled(TableFoot)`
	${TableFootStyle};
`;
