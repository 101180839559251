/**
 * facebookF
 * Don't modify this file. It is generated by scripts/build.js and is
 * ignored by git. The source svg file is located in the '../src' directory.
 */
import BrowserSpriteSymbol from 'svg-baker-runtime/browser-symbol';

const symbol = new BrowserSpriteSymbol({
  "id": "symbol-facebookF-2874361853",
  "use": "symbol-facebookF-2874361853-usage",
  "viewBox": "0 0 320 512",
  "content": "<symbol viewBox=\"0 0 320 512\" id=\"symbol-facebookF-2874361853\"><path d=\"M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z\" /></symbol>"
});


symbol.name = "facebookF";

export default symbol;
