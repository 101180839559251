import styled from 'styled-components';
import { themeGet } from 'styled-system';
import { media } from 'styled-bootstrap-grid';

export const Button = styled.button`
	background-color: ${themeGet('colors.mlcWhite')};
	border-width: 0;
	color: ${themeGet('colors.mlcVeryDarkGrey')};
	font-family: ${themeGet('fonts.buttons')};
	font-weight: ${themeGet('fontWeights.medium')};
	font-size: ${themeGet('fontSizes.default')}px;
	line-height: ${themeGet('lineHeight.default')}px;
	padding: 10px ${({ size }) => (size === 'medium' ? 30 : 15)}px;
	display: inline-flex;
	justify-content: center;
	align-content: top;
	flex-grow: 0;
	flex-shrink: 0;
	transition: left 0.25s ease-in-out, color 0.25s ease-in-out, outline 0.25s ease-in-out;
	margin: 2px;

	&.active,
	&:hover,
	&:focus {
		color: ${themeGet('colors.brandPrimaryLight')};
	}

	${media.xs`
        padding-left: 15px;
        padding-right: 15px;
    `}
`;
