/**
 * chevronLeft
 * Don't modify this file. It is generated by scripts/build.js and is
 * ignored by git. The source svg file is located in the '../src' directory.
 */
import BrowserSpriteSymbol from 'svg-baker-runtime/browser-symbol';

const symbol = new BrowserSpriteSymbol({
  "id": "symbol-chevronLeft-2630534827",
  "use": "symbol-chevronLeft-2630534827-usage",
  "viewBox": "0 0 256 512",
  "content": "<symbol viewBox=\"0 0 256 512\" id=\"symbol-chevronLeft-2630534827\"><path d=\"M231.293 473.899l19.799-19.799c4.686-4.686 4.686-12.284 0-16.971L70.393 256 251.092 74.87c4.686-4.686 4.686-12.284 0-16.971L231.293 38.1c-4.686-4.686-12.284-4.686-16.971 0L4.908 247.515c-4.686 4.686-4.686 12.284 0 16.971L214.322 473.9c4.687 4.686 12.285 4.686 16.971-.001z\" /></symbol>"
});


symbol.name = "chevronLeft";

export default symbol;
