import { css } from 'styled-components';
import { themeGet } from 'styled-system';

export default () => css`
	${themeGet('global.base')}
	display: block;

	.suggestions-container {
		position: relative;
	}

	.suggestions-list {
		position: absolute;
		z-index: 1;
		left: 0;
		right: 0;
		margin: 0;
		padding: 0;
		border-left: none;
		border-right: none;
		box-shadow: 0 1px 3px 0 ${themeGet('colors.mlcDarkGrey')};
		background-color: ${themeGet('colors.mlcWhite')};
		list-style-type: none;
	}

	.suggestion {
		padding: 10px;
		cursor: pointer;
		border-bottom: none;
	}

	.suggestion--highlighted {
		background-color: ${themeGet('colors.mlcAlmostWhite')};
	}
`;
