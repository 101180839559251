import { GrantTypes, SuccessResponse, DafEnvironments, DafSdkError } from '@nab/daf-common';
import { idmRequestOAuthAuthorization, idmRequestOAuthToken } from '@nab/daf-idm';
import PopupStrategy from './display-strategies/PopupStrategy';
import { DisplayStrategy } from './display-strategies/BaseDisplayStrategy';
import { getCurrentUri, extractStateFromUrl, cleanSearchParams } from './helpers';
import { EmployeeSsoErrorCodes } from './constants';

export type EmployeeSsoResponseType = 'code' | 'code id_token';
export interface EmployeeSsoState {
    code?: string;
    state: string;
    error?: string;
}

export interface EmployeeSsoConfig {
    clientId: string;
    scope: string;
    responseType: EmployeeSsoResponseType;
    environment: DafEnvironments;
    displayStrategy?: DisplayStrategy;
    initialState?: EmployeeSsoState;
    redirectUri?: string;
}

export interface ProcessAuthResponse {
    code?: string;
    state: string;
    error?: string;
}

export type EmployeeSsoAuthenticateResponse = SuccessResponse<{
    accessToken: string;
    tokenType: string;
    expiresIn: number;
    scope?: string;
    idToken?: string;
    lastLoginTime?: string;
    idleTimeout?: number;
    bearerToken?: string;
    issuedTokenType?: string;
}>;

async function doAuthorize({
    clientId,
    responseType,
    environment,
    redirectUri,
    initialState,
    displayStrategy,
    scope,
}: EmployeeSsoConfig): Promise<ProcessAuthResponse | void> {
    const ssoState = initialState || extractStateFromUrl();

    if (ssoState) {
        await displayStrategy.onAuthorized(ssoState);
        cleanSearchParams();
        return ssoState;
    }

    const {
        data: { url, codeChallenge, codeVerifier, state },
    } = await idmRequestOAuthAuthorization({
        client_id: clientId,
        response_type: responseType,
        environment,
        redirect_uri: redirectUri,
        scope,
    });

    displayStrategy.getPersist().set({
        codeChallenge,
        codeVerifier,
        state,
    });

    return displayStrategy.authorize(url);
}

/**
 * This method provides a convenience way for web applications to perform single sign on authentication flow with a simple set up.
 * All they need to do is calling this method on page/application load event.
 * The result of the method invocation will be a promise with the resolved value is an object contain the access token.
 *
 * @export
 * @param {EmployeeSsoConfig} { environment }
 * @returns {Promise<EmployeeSsoAuthenticateResponse>}
 */
export default async function employeeSsoAuthenticate(config: EmployeeSsoConfig): Promise<EmployeeSsoAuthenticateResponse | void> {
    try {
        const { displayStrategy = new PopupStrategy(), redirectUri = getCurrentUri() } = config;
        const authState = await doAuthorize({
            ...config,
            displayStrategy,
            redirectUri,
        });
        const generatedCodes = displayStrategy.getPersist().get();

        if (displayStrategy.shouldBreak(authState)) {
            return undefined;
        }

        if (!generatedCodes) {
            return Promise.reject(new DafSdkError(EmployeeSsoErrorCodes.NoGeneratedCodes, 'No generated code'));
        }

        if (!authState || authState.state !== generatedCodes.state) {
            return Promise.reject(new DafSdkError(EmployeeSsoErrorCodes.InvalidState, 'Invalid state'));
        }

        return idmRequestOAuthToken({
            environment: config.environment,
            client_id: config.clientId,
            grant_type: GrantTypes.AuthorizationCode,
            code: authState.code,
            code_verifier: generatedCodes.codeVerifier,
            redirect_uri: redirectUri,
        });
    } catch (e) {
        if (DafSdkError.isDafSdkError(e)) {
            throw e;
        }
        throw DafSdkError.customGeneralError(e.message);
    }
}
