import { css } from 'styled-components';

import { layout } from '../../utils/styleFunctions';

export default ({ width = '100', height = '100', keyword }) => {
	const withKey = `https://source.unsplash.com/${width}x${height}/?${keyword}`,
		noKey = `https://source.unsplash.com/random/${width}x${height}`;
	return css`
		display: inline-block;
		height: ${height}px;
		width: ${width}px;

		background-image: url(${keyword ? withKey : noKey});
		background-repeat: no-repeat;
		background-size: cover;
		background-color: grey;

		${layout}
	`;
};
