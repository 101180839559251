import { css } from 'styled-components';

export default () => css`
	box-sizing: border-box;
	max-width: 100%;
	flex: none;
	padding-left: 30px;
	padding-right: 30px;
	padding-bottom: 30px;

	.modalFooterLeftButton {
		margin-right: 15px;
	}

	.modalFooterRightButton {
		margin-left: 15px;
	}

	text-align: ${(props) => (props.align ? props.align : 'center')};
`;
