import { css } from 'styled-components';
import { themeGet } from 'styled-system';

import { layout, flexbox, bg } from '../../utils/styleFunctions';

const variants = {
	skinny: css`
		padding: ${themeGet('space.2')}px;
	`,
	narrow: css`
		padding: ${themeGet('space.4')}px;
	`,
	normal: css`
		padding: ${themeGet('space.5')}px;
	`,
	thick: css`
		padding: ${themeGet('space.6')}px;
	`,
};
export default (props) => css`
	${themeGet('global.base')}

	${props.containerSize ? variants[props.containerSize] : null}
	${layout}
	${flexbox}
	${bg}
`;
