function userAgentMatches(pattern) {
	return typeof window !== `undefined` && pattern.test(window.navigator.userAgent);
}

export function isFireFox() {
	return userAgentMatches(/firefox/i);
}

export function isIE11() {
	return userAgentMatches(/Trident/);
}
