import { css } from 'styled-components';
import { media } from 'styled-bootstrap-grid';
import { themeGet } from 'styled-system';

export default () => css`
	${themeGet('global.base')}
	max-width: 100%;
	display: flex;
	flex-wrap: wrap;

	&.ButtonGroup--left,
	&.ButtonGroup--right {
		& > * {
			display: block;
			width: 100%;
			margin-bottom: 20px; /* spacing between stacked buttons */
		}
		& > *:last-child {
			margin-bottom: 0; /* do not add to the last stacked button */
		}
		margin-bottom: 30px;
	}

	&.ButtonGroup--right {
		justify-content: flex-start;
		flex-direction: row-reverse;
	}

	&.ButtonGroup--left {
		justify-content: flex-end;
		flex-direction: row-reverse;
	}

	${media.md`
		&.ButtonGroup--right, &.ButtonGroup--left {
			& > * {
				display: inline-block;
				width: auto;
				max-width: 100%;
				margin-bottom: 0; /* medium screens have no button bottom spacing because they are not stacked */
			}
		}
		&.ButtonGroup--right {
			& > * {
				margin-left: 20px;
			}

			& > :last-child {
				margin-left: 0;
			}
		}

		&.ButtonGroup--left {
			& > * {
				margin-right: 20px;
			}

			& > :first-child {
				margin-right: 0;
			}
		}
	`}
`;
