import { themeGet } from 'styled-system';
import styled, { css } from 'styled-components';

export const styles = css`
	box-sizing: border-box;
	background-color: ${themeGet('colors.mlcLightGrey')};

	font-family: ${themeGet('fonts.default')};
	font-size: ${themeGet('fontSizes.disclaimer')}px;
	font-weight: ${themeGet('fontWeights.medium')};
	line-height: ${themeGet('lineHeights.button')};

	display: inline-flex;
	align-items: center;
	height: 44px;
	padding-left: 18px;
	padding-right: 18px;

	&.Button__Item {
		border: thin solid ${themeGet('colors.mlcLightGrey')};
		color: ${themeGet('colors.mlcVeryDarkGrey')};
		cursor: pointer;
		background-color: ${themeGet('colors.mlcWhite')};
		text-decoration: underline;

		:hover,
		:focus {
			text-decoration: none;
		}
	}

	&.Selected__Item {
		color: ${themeGet('colors.mlcBlack')};
		background-color: ${themeGet('colors.mlcLightGrey')};
		border: thin solid ${themeGet('colors.mlcLightGrey')};
		margin-top: 0px;
		margin-bottom: 0px;
		:hover,
		:focus {
			text-decoration: none;
		}
	}

	&.PrevNext__Button {
		background-color: ${themeGet('colors.mlcLightGrey')};
		border: thin solid ${themeGet('colors.mlcLightGrey')};
		color: ${themeGet('colors.mlcVeryDarkGrey')};
		cursor: pointer;

		:hover,
		:focus {
			text-decoration: none;
		}

		&.is-invisible {
			display: none;
		}
	}

	&.Previous__Button {
		margin-left: 0px;
		margin-right: 5px;
	}
`;

export const PaginationLink = styled.a`
	margin-left: 5px;
	margin-right: 5px;
	color: ${themeGet('colors.mlcVeryDarkGrey')};
	& :hover,
	:focus {
		text-decoration: none;
	}
`;
export default styles;
