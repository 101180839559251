import { compose, mapProps, withHandlers, withState } from 'recompose';

export default compose(
	withState('isFocused', 'setFocus', false),
	withHandlers({
		onFocus: ({ onFocus, setFocus }) => (event) => {
			setFocus(true);
			onFocus && onFocus(event);
		},
		onBlur: ({ onBlur, setFocus }) => (event) => {
			setFocus(false);
			onBlur && onBlur(event);
		},
	}),
	mapProps(({ setFocus, ...props }) => props)
);
