import React, { HTMLAttributes } from 'react';

import { UitkBaseProps } from '../../typings';

import { Page } from './PageWrapper.style';
type PageWrapperProps = {
	/** tighter spacing version of the PageWrapper, mostly used for pages with navigational elements before content */
	squish?: boolean;
};
export const PageWrapper = ({
	children,
	...restProps
}: PageWrapperProps & UitkBaseProps & HTMLAttributes<HTMLDivElement>) => {
	return <Page {...restProps}>{children}</Page>;
};

export default PageWrapper;
