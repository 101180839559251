import styled, { css } from 'styled-components';
import { themeGet } from 'styled-system';
import * as palette from '@mlc/mlc-palette';

const accordionOpenBorder = () => css`
	border: thin solid ${themeGet('colors.mlcMediumGrey')};
`;

const accordionOpen = ({ borderStyle }) => css`
	${borderStyle === 'normal' ? accordionOpenBorder : null}
`;

const accordionClosedBorder = () => css`
	border: thin solid transparent;
	border-top-color: ${themeGet('colors.mlcMediumGrey')};
`;

const accordionClosed = ({ borderStyle }) => css`
	${borderStyle === 'normal' ? accordionClosedBorder : null}
`;

const accordionStyle = ({ isOpen, hasCustomHeader, noMarginBottom, borderStyle }) => css`
	${themeGet('global.base')}

	margin: 0;
	position: relative;
	transition: border-color 0.8s, background-color 0.4s linear;
	margin-bottom: ${!hasCustomHeader || noMarginBottom ? '0' : '20px'};
	.ReactCollapse--collapse {
		transition: height 300ms;
	}
	.Accordion__icon {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		right: 10px;
	}

	[hidden] {
		display: none;
	}

	${hasCustomHeader
		? borderStyle === 'emphasis'
			? `border: thin ${palette.mlcMediumGrey} solid;`
			: null
		: null}

	${isOpen && borderStyle !== 'emphasis'
		? accordionOpen({ borderStyle })
		: accordionClosed({ borderStyle })}
`;

export const AccordionButton = styled.button`
	${themeGet('global.base')}
	border: none;
	margin: 0;
	padding: ${(props) => (props.hasCustomHeader ? '0px' : '10px 30px 10px 10px')};
	width: 100%;
	background: ${(props) => props.backgroundColor || themeGet('colors.mlcAlmostWhite')};
	color: inherit;
	text-align: left;

	font: inherit;
	cursor: pointer;
	transition: border 0.25s linear;
	&:focus,
	&:hover {
		outline: none;
		box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset,
			0 0 4px ${themeGet('colors.brandSecondaryLight')};
		z-index: 1;
		position: relative;
	}

	&::-moz-focus-inner {
		border: 0;
		padding: 0;
	}

	${(props) =>
		props.borderStyle === 'emphasis'
			? !props.isOpen
				? `border-left: medium ${props.borderEmphasisColor} solid`
				: `border-left: medium transparent solid`
			: null}
`;

export const AccordionBox = styled.div`
	${accordionStyle}
`;
