import React, { HTMLAttributes } from 'react';
import styled from 'styled-components';
import classnames from 'classnames';
import { Column } from 'styled-bootstrap-grid';

import Heading from '../Heading/Heading';
import Col from '../Col/Col';
import Row from '../Row/Row';
import Grid from '../Grid/Grid';
import Card from '../Card';
import passRef from '../../hocs/passRef';
import { UitkBaseProps } from '../../typings';

import componentStyle from './PageHeader.style';
type PageHeaderProps = {
	pageName?: string;
	pageDescription?: string;
	/** Background color */
	bg?: string;
	/** Headings color */
	color?: string;
	col?: Column;
};
export const PageHeader = ({
	pageName,
	pageDescription,
	col,
	color,
	className,
	forwardedRef,
	...restProps
}: PageHeaderProps & UitkBaseProps & HTMLAttributes<HTMLDivElement>) => {
	return (
		<Card className={classnames('PageHeader', className)} {...restProps}>
			<Grid>
				<Row>
					<Col col={col}>
						<Heading
							className="PageHeader__title"
							el="h1"
							ref={forwardedRef}
							color={color}
						>
							{pageName}
						</Heading>
						{pageDescription && (
							<Heading
								className="PageHeader__description"
								el="p"
								level={3}
								color={color}
							>
								{pageDescription}
							</Heading>
						)}
					</Col>
				</Row>
			</Grid>
		</Card>
	);
};
PageHeader.defaultProps = {
	color: 'mlcWhite',
	bg: 'mlcOrange',
};

export default styled(passRef(PageHeader))`
	${componentStyle}
`;
