const addPlural = (n: number) => {
	return n === 1 ? '' : 's';
};

export const getRemainingTime = (seconds: number) => {
	let time;
	const minutes = Math.floor(seconds / 60000);
	const secondsRemaining = Math.round((seconds - minutes * 60000) / 1000);
	time = minutes > 0 ? minutes + ' minute' + addPlural(minutes) : '';
	time +=
		(time ? ' and ' + secondsRemaining : secondsRemaining) +
		' second' +
		addPlural(secondsRemaining);
	return time;
};
