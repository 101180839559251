import { css } from 'styled-components';
import { themeGet } from 'styled-system';

import { typography, textLink } from '../../utils/styleFunctions';

const variants = {
	button: css`
		font-family: ${themeGet('fonts.buttons')};
		font-size: ${themeGet('fontSizes.button')}px;
		font-weight: ${themeGet('fontWeights.semibold')};
		line-height: ${themeGet('lineHeights.button')};
	`,
	caption: css`
		font-size: ${themeGet('fontSizes.caption')}px;
		letter-spacing: 0.25px;
		line-height: ${themeGet('lineHeights.caption')};
	`,
	normal: css`
		font-size: ${themeGet('fontSizes.label')}px;
		font-weight: ${themeGet('fontWeights.medium')};
		letter-spacing: 0.15px;
		line-height: ${themeGet('lineHeights.label')};
		margin: 0 0 ${themeGet('space.1')}px 0;
		padding: 0;
	`,
};

// if el is a legend then make sure it still looks like label
const legendReset = css`
	border: none;
	padding: 0;
`;

const aemCounterStyles = ({ border, style, className }) => css`
	text-align: left;
	background-color: transparent;
	border: none;
	/* AEM has 4 levels of specificty so a conditional important here is vital */
	${border || style || className ? '' : `border-left: none!important;`};
	min-width: 0;

	&:hover {
		background-color: transparent;
		cursor: default;
	}
`;

export default ({ variant, el }) => css`
	${themeGet('global.base')}
	display: inline-block;
	${variant ? variants[variant] : variants.normal}
	&:focus {
		outline-style: none;
	}
	${el == 'legend' ? legendReset : ''}

	${textLink}
	&& {
		/* create arbitrary specificty for aemCounter */
		${aemCounterStyles}
		${typography}
	}
`;
