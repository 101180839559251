export const mlcOrange = '#c95109';
export const mlcDeepOrange = '#741915';
export const mlcWhite = '#fff';
export const mlcAlmostWhite = '#f0eeea';
export const mlcLightGrey = '#cfcbc3';
export const mlcMediumGrey = '#99948a';
export const mlcDarkGrey = '#473f3a';
export const mlcVeryDarkGrey = '#161818';
export const mlcBlack = '#000';
export const mlcOrange90 = '#d86018';
export const mlcOrange80 = '#d4743a';
export const mlcOrange60 = '#df976b';
export const mlcOrange40 = '#e9b99d';
export const mlcDeepOrange80 = '#904744';
export const mlcDeepOrange60 = '#ac7573';
export const mlcDeepOrange40 = '#c7a3a1';
export const mlcAqua = '#01838c';
export const mlcDeepAqua = '#004651';
export const mlcRubine = '#e40571';
export const mlcDeepRubine = '#60003a';
export const mlcDarkGrey80 = '#6c6561';
export const mlcDarkGrey70 = '#707272';
export const mlcDarkGrey60 = '#918c89';
export const mlcMediumGrey80 = '#ada9a1';
export const mlcMediumGrey60 = '#c2bfb9';
export const mlcVeryDarkGrey80 = '#454646';
export const mlcVeryDarkGrey60 = '#737474';
export const mlcError = '#900';
export const mlcSuccess = '#1a6d29';
export const mlcWarning = '#e6920a';
export const mlcTextLink = '#086b82';
export const mlcTextLinkBright = '#0dafd4';
export const mlcTextLinkVisited = '#66427a';
export const mlcCore = '#f0eeea';
export const mlcDolphin = '#f5f5f5';
export const mlcDove = '#c8c6c2';
export const mlcKoala = '#faf8f4';
export const mlcPartner = '#282828';
export const mlcPremium = '#473f3a';
export const mlcShark = '#eee';
export const mlcText = '#3b3b3b';
export const mlcWallaby = '#949089';
export const mlcGreen = '#2c853c';
export const mlcRed = '#900';

export default {
  mlcOrange,
  mlcDeepOrange,
  mlcWhite,
  mlcAlmostWhite,
  mlcLightGrey,
  mlcMediumGrey,
  mlcDarkGrey,
  mlcVeryDarkGrey,
  mlcBlack,
  mlcOrange90,
  mlcOrange80,
  mlcOrange60,
  mlcOrange40,
  mlcDeepOrange80,
  mlcDeepOrange60,
  mlcDeepOrange40,
  mlcAqua,
  mlcDeepAqua,
  mlcRubine,
  mlcDeepRubine,
  mlcDarkGrey80,
  mlcDarkGrey70,
  mlcDarkGrey60,
  mlcMediumGrey80,
  mlcMediumGrey60,
  mlcVeryDarkGrey80,
  mlcVeryDarkGrey60,
  mlcError,
  mlcSuccess,
  mlcWarning,
  mlcTextLink,
  mlcTextLinkBright,
  mlcTextLinkVisited,
  mlcCore,
  mlcDolphin,
  mlcDove,
  mlcKoala,
  mlcPartner,
  mlcPremium,
  mlcShark,
  mlcText,
  mlcWallaby,
  mlcGreen,
  mlcRed,
};
	

export const groups = {
	primary: [
		[
			{
				mlcOrange: mlcOrange
			},
			{
				mlcDeepOrange: mlcDeepOrange
			}
		]
	],
	neutrals: [
		[
			{
				mlcWhite: mlcWhite
			},
			{
				mlcAlmostWhite: mlcAlmostWhite
			},
			{
				mlcLightGrey: mlcLightGrey
			},
			{
				mlcMediumGrey: mlcMediumGrey
			},
			{
				mlcDarkGrey: mlcDarkGrey
			},
			{
				mlcVeryDarkGrey: mlcVeryDarkGrey
			},
			{
				mlcBlack: mlcBlack
			}
		]
	],
	limited: [
		[
			{
				mlcOrange90: mlcOrange90
			},
			{
				mlcOrange80: mlcOrange80
			},
			{
				mlcOrange60: mlcOrange60
			},
			{
				mlcOrange40: mlcOrange40
			},
			{
				mlcDeepOrange80: mlcDeepOrange80
			},
			{
				mlcDeepOrange60: mlcDeepOrange60
			},
			{
				mlcDeepOrange40: mlcDeepOrange40
			}
		],
		[
			{
				mlcAqua: mlcAqua
			},
			{
				mlcDeepAqua: mlcDeepAqua
			},
			{
				mlcRubine: mlcRubine
			},
			{
				mlcDeepRubine: mlcDeepRubine
			}
		],
		[
			{
				mlcDarkGrey80: mlcDarkGrey80
			},
			{
				mlcDarkGrey70: mlcDarkGrey70
			},
			{
				mlcDarkGrey60: mlcDarkGrey60
			},
			{
				mlcMediumGrey80: mlcMediumGrey80
			},
			{
				mlcMediumGrey60: mlcMediumGrey60
			},
			{
				mlcVeryDarkGrey80: mlcVeryDarkGrey80
			},
			{
				mlcVeryDarkGrey60: mlcVeryDarkGrey60
			}
		]
	],
	system: [
		[
			{
				mlcError: mlcError
			},
			{
				mlcSuccess: mlcSuccess
			},
			{
				mlcWarning: mlcWarning
			},
			{
				mlcTextLink: mlcTextLink
			},
			{
				mlcTextLinkBright: mlcTextLinkBright
			},
			{
				mlcTextLinkVisited: mlcTextLinkVisited
			}
		]
	],
	deprecated: [
		[
			{
				mlcCore: mlcCore
			},
			{
				mlcDolphin: mlcDolphin
			},
			{
				mlcDove: mlcDove
			},
			{
				mlcKoala: mlcKoala
			},
			{
				mlcPartner: mlcPartner
			},
			{
				mlcPremium: mlcPremium
			},
			{
				mlcShark: mlcShark
			},
			{
				mlcText: mlcText
			},
			{
				mlcWallaby: mlcWallaby
			},
			{
				mlcGreen: mlcGreen
			},
			{
				mlcRed: mlcRed
			}
		]
	]
};
