/* Prop Types cannot be imported or destructured properly because of limitations in react-docgen
Issue: https://github.com/reactjs/react-docgen/issues/33
There is currently a PR to add native support for these features to docgen: https://github.com/reactjs/react-docgen/pull/352

In the meantime, there are workarounds such as https://www.npmjs.com/package/react-docgen-external-proptypes-handler
but they require specific syntax which makes the output of docgen/styleguidist more obtuse
and furthermore they do not support destructuring which would be great for all styled-system functions
which have proptypes included in their function definition */

import {
	alignContent,
	alignItems,
	alignSelf,
	background,
	backgroundImage,
	backgroundPosition,
	backgroundRepeat,
	backgroundSize,
	border,
	borderBottom,
	borderLeft,
	borderRight,
	borderRadius,
	borderTop,
	borderColor,
	boxShadow,
	color,
	compose,
	display,
	flex,
	flexBasis,
	flexDirection,
	flexWrap,
	fontFamily,
	fontSize as originalFontSize,
	fontWeight,
	get,
	height,
	justifyContent,
	justifyItems,
	justifySelf,
	order,
	space,
	style,
	textAlign,
	width,
	overflow,
} from 'styled-system';

// fontSizes without transform and coupled with lineheight
export const fontSize = compose(
	originalFontSize,
	style({
		prop: 'fontSize',
		cssProperty: 'lineHeight',
		key: 'lineHeights',
	})
);

// textLink :: Object -> String
// combine font-size and line-height from theme
export const textLink = (props) => {
	if (!props.textLink) {
		return null;
	}
	return get(props.theme, 'global.textLink') || {};
};

export const typography = compose(
	border,
	borderColor,
	boxShadow,
	color,
	display,
	fontFamily,
	fontSize,
	fontWeight,
	space,
	textAlign
);

export const layout = compose(
	border,
	borderBottom,
	borderLeft,
	borderRight,
	borderTop,
	borderColor,
	boxShadow,
	color,
	display,
	height,
	space,
	textAlign,
	borderRadius,
	width,
	overflow
);

export const flexbox = compose(
	alignContent,
	alignItems,
	alignSelf,
	flex,
	flexBasis,
	flexDirection,
	flexWrap,
	justifyContent,
	justifyItems,
	justifySelf,
	order
);

export const bg = compose(
	background,
	backgroundImage,
	backgroundSize,
	backgroundPosition,
	backgroundRepeat
);
