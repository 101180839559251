import { css } from 'styled-components';
import { themeGet } from 'styled-system';
import { media } from 'styled-bootstrap-grid';

function getBg(variant) {
	return variant === 'error' ? 'mlcError' : variant === 'success' ? 'mlcSuccess' : 'mlcWarning';
}

const styles = (props) => css`
	.Toast__Wrapper {
		display: flex;
		left: 0px;
		position: fixed;
		width: 100%;
		z-index: 10;
		top: 0px;
		-webkit-font-smoothing: antialiased;
		background-color: ${themeGet('colors.' + getBg(props.variant))};
		box-sizing: border-box;
	}

	.Toast__Message {
		padding: 18px 110px 18px 10px;
		display: flex;
		justify-content: flex-start;
		align-items: start;

		${media.md`
			max-width: 800px;
			padding: 18px 110px;
			margin: 0 auto;
			justify-content: center;
		`}
	}

	.Toast__CloseContainer {
		display: flex;
		align-items: center;
		text-decoration: none;
		font-family: ${themeGet('fonts.default')};
		font-size: ${themeGet('fontSizes.default')}px;
		position: absolute;
		top: 10px;
		right: 10px;
	}

	.Toast__Icon {
		margin-right: 10px;
		justify-content: start;
	}

	.Toast__Close {
		margin-left: 5px;
	}
`;

export default styles;
