import React, { createContext, HTMLAttributes, useEffect, useState } from 'react';
import ReactModal from 'react-modal';
import styled from 'styled-components';
import classnames from 'classnames';
import { timesCircleSolid } from '@mlc/symbols';

import { UitkBaseProps } from '../../typings';
import Text from '../Text/Text';
import ModalHeader from '../Modal/components/ModalHeader/ModalHeader';
import ModalFooter from '../Modal/components/ModalFooter/ModalFooter';
import ScreenReaderText from '../ScreenReaderText/ScreenReaderText';

import componentStyle, {
	TimeoutModalContentContainer,
	TimeoutModalProgressContainer,
} from './TimeoutModal.style';
import { getRemainingTime } from './TimeoutModalUtil';

export const TimeoutModalContext = createContext(false);
const TimeoutModalAdapter = ({ className, ...props }) => (
	<ReactModal
		portalClassName={`${className}__timeoutPortal`}
		className={`${className}__reactModal`}
		overlayClassName={`${className}__overlay`}
		bodyOpenClassName={`${className}__bodyOpen`}
		htmlOpenClassName={`${className}__htmlOpen`}
		{...props}
	/>
);
type TimeoutModalProps = {
	/**
	 * Number of milliseconds of idle time remaining to display the Timeout Modal.
	 * Default value is 5 seconds
	 */
	idleTime: number;
	/**
	 * Function that will be triggered when the idle timeout is reached.
	 */
	onIdleTimeout: (...args: any[]) => any;
	/**
	 * Number of milliseconds remaining to trigger the onTimeout function.
	 * * Default value is 10 seconds
	 */
	timeout: number;
	/**
	 * Function that will be triggered when the timeout is reached.
	 */
	onTimeout: (...args: any[]) => any;
	/**
	 * Content of the modal header
	 */
	title: string;
	/**
	 * Description of the timeout modal. It will be displayed below the progress bar
	 * You can add the ${time} tag and it will be replaced by the remaining time.
	 */
	description: string;
	/**
	 * Content is center aligned, unless otherwise stated
	 */
	align?: 'left' | 'right' | 'center';
	/**
	 * Footer is center aligned, unless otherwise stated
	 */
	alignFooter?: 'left' | 'right' | 'center';
	/**
	 * Additional aria attributes (optional).
	 */
	aria?: object;
	/**
	 * Content of the button that will dismiss the modal.
	 * Default value is 'Continue'.
	 */
	primaryButtonContent?: string;
	/**
	 * Extra button event and content
	 * Can also specify extra button type: secondary or tertiary
	 */
	extraButton?: any;
	/** Number indicating the milliseconds to wait before closing the modal. */
	closeTimeoutMS?: number;
	/** The theme for the modal. */
	colorTheme?: 'mlcOrange' | 'mlcAqua' | 'mlcRubine' | 'mlcWhite' | 'brandColor';
	/** Function that will be run after the modal has opened. */
	onAfterOpen?: (...args: any[]) => any;
	/**
	 * Function that will be called to get the parent element that the modal will be attached to.
	 */
	parentSelector?: (...args: any[]) => any;
	/**
	 * String indicating the role of the modal, allowing the 'dialog' role to be applied if desired.
	 */
	role?: string;
	/**
	 * Boolean indicating if the modal should be focused after render
	 */
	shouldFocusAfterRender?: boolean;
	/** Boolean indicating if the overlay should close the modal */
	shouldCloseOnOverlayClick?: boolean;
	/**
	 * Set the CSS `z-index` of the portal element. If possible, as per docs above,
	 * render your application in a Container component rather than use this
	 * prop. If you find yourself in a z-index war with a badly behaved
	 * application or component then use with discretion
	 */
	zIndex?: number;
	/**
	 * Icon that will be displayed in header
	 */
	headerIcon?: object;
	/**
	 * Close icon that will be displayed on the top right
	 */
	symbol: {
		content?: string;
		id?: string;
		viewBox?: string;
		isMounted?: boolean;
	};
	/**
	 * Custom props for the close icon
	 */
	symbolProps?: object;
};

export const TimeoutModal = ({
	idleTime = 5000,
	onIdleTimeout,
	timeout = 10000,
	onTimeout,
	aria,
	primaryButtonContent,
	extraButton,
	className,
	closeTimeoutMS,
	colorTheme,
	forwardedRef,
	onAfterOpen,
	parentSelector,
	role,
	shouldCloseOnOverlayClick,
	shouldFocusAfterRender,
	title = 'Your session will expire in ${time}.',
	description,
	headerIcon,
	symbol,
	symbolProps,
	zIndex,
	align,
	alignFooter,
	...restProps
}: UitkBaseProps & TimeoutModalProps & HTMLAttributes<HTMLDivElement>) => {
	const containerClass = colorTheme === 'mlcWhite' ? '--mlcWhite' : '';
	const [openTimeoutModal, setOpenTimeoutModal] = useState(false);
	const [, setIdleTimer] = useState(null);
	const [progress, setProgress] = useState(timeout);
	const [progressTimer, setProgressTimer] = useState(null);
	const [srProgress, setSrProgress] = useState(timeout);

	const activateModal = () => { // eslint-disable-line
		setIdleTimer((idleTimer) => {
			if (idleTimer) {
				clearTimeout(idleTimer);
			}
			return setTimeout(() => {
				onIdleTimeout();
				setOpenTimeoutModal(true);
				setProgressTimer(() =>
					setInterval(() => {
						setProgress((progress) => progress - 1000);
					}, 1000)
				);
			}, idleTime);
		});
	};

	const resetIdleTimer = () => { // eslint-disable-line
		setOpenTimeoutModal((open: boolean) => {
			if (!open) {
				setIdleTimer((idleTimer) => clearTimeout(idleTimer));
				activateModal();
			}
			return open;
		});
	};

	const onRequestClose = () => {
		setOpenTimeoutModal(false);
		clearInterval(progressTimer);
		setProgress(timeout);
		resetIdleTimer();
	};

	const timeoutModalHeaderProps = {
		headerIcon,
		symbol,
		symbolProps,
		onRequestClose,
		colorTheme,
		align,
	};

	const getFooterButtons = () => {
		const buttons: any[] = [];
		if (extraButton) {
			buttons.push(extraButton);
		}
		buttons.push({
			onClick: onRequestClose,
			content: primaryButtonContent ? primaryButtonContent : 'Continue',
		});
		return buttons;
	};

	const timeoutModalFooterProps = {
		hideFooterButtons: false,
		footerButtons: getFooterButtons(),
		onRequestClose,
		colorTheme,
		align: alignFooter,
	};

	useEffect(() => {
		window.addEventListener('load', resetIdleTimer, true);
		const events = ['mousedown', 'mousemove', 'keypress', 'keydown', 'scroll', 'touchstart'];
		events.forEach(function(name) {
			document.addEventListener(name, resetIdleTimer, true);
		});
		activateModal();
	}, []); // eslint-disable-line

	useEffect(() => {
		if (progress === 0) {
			onTimeout();
			clearInterval(progressTimer);
		} else {
			// update screen reader at every minute or 30 seconds if less then a minute missing
			if (progress % 60000 === 0 || (progress < 60000 && progress % 30000 === 0)) {
				setSrProgress(progress);
			}
		}
	}, [progress]); // eslint-disable-line

	const getTitle = (p) => {
		return title.replace('${time}', getRemainingTime(p));
	};

	const getSrTitle = (p) => {
		// do not show screen reader on load
		return p !== timeout ? getTitle(p).replace(' and 0 seconds', '') : '';
	};

	return (
		<TimeoutModalContext.Provider value={true}>
			<TimeoutModalAdapter
				className={className}
				aria={aria}
				closeTimeoutMS={closeTimeoutMS}
				colorTheme={colorTheme}
				contentLabel={getTitle(srProgress)}
				isOpen={openTimeoutModal}
				onAfterOpen={onAfterOpen}
				onRequestClose={onRequestClose}
				parentSelector={parentSelector}
				ref={forwardedRef}
				role={role}
				shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
				shouldFocusAfterRender={shouldFocusAfterRender}
			>
				<div {...restProps} className={classnames('timeoutModal__body', className)}>
					<ScreenReaderText role={'alert'} aria-live={'assertive'} aria-atomic={'true'}>
						{getSrTitle(srProgress)}
					</ScreenReaderText>
					<ModalHeader {...timeoutModalHeaderProps}>
						<span id={'timer'} data-seconds={timeout} className={'countdown'}>
							{getTitle(progress)}
						</span>
					</ModalHeader>
					<TimeoutModalProgressContainer
						className={classnames(`timeoutModalContentContainer${containerClass}`)}
					>
						<progress
							value={progress / 1000}
							max={timeout / 1000}
							className="TimeoutModal__bar"
						/>
					</TimeoutModalProgressContainer>
					<TimeoutModalContentContainer
						className={classnames(`timeoutModalContentContainer${containerClass}`)}
						align={align}
					>
						<Text>{description}</Text>
					</TimeoutModalContentContainer>
					<ModalFooter {...timeoutModalFooterProps} />
				</div>
			</TimeoutModalAdapter>
		</TimeoutModalContext.Provider>
	);
};
TimeoutModal.defaultProps = {
	isOpen: false,
	role: 'dialog',
	zIndex: 1,
	colorTheme: 'mlcWhite',
	hideFooterButtons: false,
	symbol: timesCircleSolid,
	align: 'center',
};
TimeoutModal.setAppElement = ReactModal.setAppElement;

export default styled(TimeoutModal)`
	${componentStyle}
`;
