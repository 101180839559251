import React from 'react';

import { defaultFormat } from '../BareFormattedInput/BareFormattedInput';
import Input from '../Input';
import passRef from '../../hocs/passRef';
import { UitkBaseProps } from '../../typings';

export const format = {
	...defaultFormat,
	guide: true,
	keepCharPositions: true,
	mask: [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/],
};
type DateInputProps = {
	isInvalid?: boolean;
	onChange?: (...args: any[]) => any;
	readOnly?: boolean;
	value?: string;
	isLoading?: boolean;
};
export const DateInput = ({
	forwardedRef,
	...restProps
}: UitkBaseProps & DateInputProps & React.HTMLAttributes<HTMLInputElement>) => {
	return <Input ref={forwardedRef} {...restProps} format={format} />;
};
DateInput.isFormField = true;
export default passRef(DateInput);
