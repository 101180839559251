import { chevronDownSolid, chevronRightSolid, chevronUpSolid } from '@mlc/symbols';
import React from 'react';
import classnames from 'classnames';

import Svg from '../../../Svg';
import DropdownLink from '../DropdownLink/DropdownItemContent';
import NewTabMsg from '../../../NewTabMsg/NewTabMsg';
import { UitkBaseProps } from '../../../../typings';

import { Item } from './DropdownItem.style';

type MenuButtonItemProps = {
	menuType?: string;
	onClick?: (...args: any[]) => any;
	open?: boolean;
	role?: string;
	link?: string;
	menu?: {
		key?: string;
		pageurl?: string;
		subpages?: any[];
		title?: string;
		isAbsoluteLink?: boolean;
		openInNewTab?: boolean;
	};
};

const MenuButtonItem = ({
	children,
	menuType,
	menu,
	onClick,
	open,
	role = 'button',
}: MenuButtonItemProps & UitkBaseProps) => {
	return (
		<DropdownLink
			name={menu.key}
			onClick={onClick}
			aria-expanded={open}
			aria-controls={menu.key}
			role={role}
			link={menu.pageurl}
			isAbsoluteLink={menu.isAbsoluteLink}
			openInNewTab={menu.openInNewTab}
			className={classnames(menuType, 'is-ButtonStyle Dropdowns__link')}
			data-uitk={`${role}-link-${menuType}`}
			// to solve safari e.relatedTarget returns null issue
			// https://stackoverflow.com/questions/42764494/blur-event-relatedtarget-returns-null
			tabIndex="0"
		>
			{children}
		</DropdownLink>
	);
};

type DropdownItemProps = {
	dropdownLevel?: number;
	onClick?: (...args: any[]) => any;
	menu?: {
		key?: string;
		pageurl?: string;
		subpages?: any[];
		title?: string;
		isAbsoluteLink?: boolean;
		openInNewTab?: boolean;
		type?: string;
		onClick?: (...args: any[]) => any;
	};
	hoverColor?: string;
	hoverDecoration?: string;
	textDecoration?: string;
};

type Props = DropdownItemProps & UitkBaseProps;
type State = {
	open: boolean;
};

export class DropdownItem extends React.Component<Props, State> {
	constructor(props) {
		super(props);
		this.state = {
			open: false,
		};
	}

	handleMenuOnclick = (e: any) => {
		e.preventDefault();
		// to set focus on safai
		e.target.focus();
		if (this.state.open === true) {
			this.setState({
				open: false,
			});
		} else {
			this.setState({
				open: true,
			});
		}
	};

	handleLinkOnClick = (e) => {
		e.target.blur();
		if (this.props.onClick) {
			this.props.onClick();
		}
	};

	render() {
		const { menu, dropdownLevel, onClick } = this.props;

		const { open } = this.state;

		const iconWidth = '12px';
		const updatedDropdownLevel = dropdownLevel + 1,
			menuType = updatedDropdownLevel === 0 ? 'is-main' : 'is-sub';

		// prevent default so inherently url added in DropdownItemContent doesn't do anything on click
		const handleClick = !menu.pageurl
			? (e: any) => {
					e.preventDefault();
					menu.onClick();
			  }
			: menu.onClick;

		const content = (menu) => {
			return menu.subpages && menu.subpages.length > 0 ? (
				<MenuButtonItem
					menuType={menuType}
					menu={menu}
					onClick={this.handleMenuOnclick}
					open={open}
					role={menu.type}
				>
					<span className="Dropdowns__content">{menu.title}</span>
					{/* Desktop menu icons */}
					<span className="Dropdowns__icon-wrapper--desktop">
						{/* Desktop main menu icons */}
						<span hidden={menuType === 'is-sub'}>
							<span hidden={open}>
								<Svg
									className="Dropdowns__icon"
									symbol={chevronDownSolid}
									width={iconWidth}
								/>
							</span>
							<span hidden={!open}>
								<Svg
									className="Dropdowns__icon"
									symbol={chevronUpSolid}
									width={iconWidth}
								/>
							</span>
						</span>

						{/* Desktop sub menu icons */}
						<span hidden={menuType === 'is-main'}>
							<span hidden={open}>
								<Svg
									className="Dropdowns__icon"
									symbol={chevronRightSolid}
									width={iconWidth}
								/>
							</span>
							<span hidden={!open}>
								<Svg
									className="Dropdowns__icon"
									symbol={chevronDownSolid}
									width={iconWidth}
								/>
							</span>
						</span>
					</span>
				</MenuButtonItem>
			) : (
				<DropdownLink
					className={classnames(menuType, ' Dropdowns__link')}
					data-uitk={`${menu.type}-link-${menuType}`}
					link={menu.pageurl}
					onClick={handleClick || this.handleLinkOnClick}
					name={menu.key}
					isAbsoluteLink={menu.isAbsoluteLink}
					openInNewTab={menu.openInNewTab}
					role={menu.type}
				>
					<span className="Dropdowns__content">{menu.title}</span>
					{menu.openInNewTab && <NewTabMsg />}
				</DropdownLink>
			);
		};

		return (
			<Item
				data-uitk={'DropdownItem'}
				className={classnames(open === true ? 'is-open' : '', menuType, 'Dropdowns__item')}
				hoverColor={this.props.hoverColor}
				hoverDecoration={this.props.hoverDecoration}
				textDecoration={this.props.textDecoration}
			>
				{content(menu)}

				{menu && menu.subpages && menu.subpages.length > 0 && (
					<ul className={classnames(menuType, 'Menu__sublist')} id={menu.key}>
						{menu.subpages.map((menu) => (
							<DropdownItem
								key={menu.key}
								menu={menu}
								dropdownLevel={updatedDropdownLevel}
								onClick={onClick}
							/>
						))}
					</ul>
				)}
			</Item>
		);
	}

	static defaultProps = {
		dropdownLevel: 0,
	};
}

export default DropdownItem;
