import React from 'react';
import { chevronLeft, chevronRight } from '@mlc/symbols';
import { ENTER, SPACE } from 'react-day-picker/lib/src/keys';

import Svg from '../Svg/Svg';
import { UitkBaseProps } from '../../typings';
const handleKeyDown = (e, handler) => {
	if (e.keyCode !== ENTER && e.keyCode !== SPACE) {
		return;
	}
	e.preventDefault();
	handler();
};
type SvgNavbarProps = {
	onPreviousClick?: (...args: any[]) => any;
	onNextClick?: (...args: any[]) => any;
	classNames?: {
		navButtonPrev?: string;
		navButtonNext?: string;
	};
};
export const SvgNavbar = ({
	onPreviousClick,
	onNextClick,
	className,
	classNames,
}: SvgNavbarProps & UitkBaseProps) => (
	<div className={className}>
		<span
			tabIndex={0}
			role="button"
			aria-label="Previous Month"
			className={classNames.navButtonPrev}
			onClick={() => onPreviousClick()}
			onKeyDown={(e) => handleKeyDown(e, onPreviousClick)}
		>
			<Svg symbol={chevronLeft} />
		</span>
		<span
			tabIndex={0}
			role="button"
			aria-label="Next Month"
			className={classNames.navButtonNext}
			onClick={() => onNextClick()}
			onKeyDown={(e) => handleKeyDown(e, onNextClick)}
		>
			<Svg symbol={chevronRight} />
		</span>
	</div>
);
export default SvgNavbar;
