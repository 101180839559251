import React, { Children, cloneElement, HTMLAttributes } from 'react';
import { find } from 'lodash';
import classnames from 'classnames';
import { generate } from 'shortid';

import { UitkBaseProps, UitkStatics } from '../../typings';
import passRef from '../../hocs/passRef';

/* Helper functions for handling state change */
import {
	isMultipleValueOptionSelected,
	isSingleValueOptionSelected,
	multipleValueHandleChange,
	singleValueHandleChange,
} from './GroupedFieldHelpers';

export type ToggleGroupProps = {
	id?: string;
	autoFocus?: boolean;
	variant?: 'boxed' | 'normal';
	isInvalid?: boolean;
	multiple?: boolean;
	name?: string;
	onChange: (...args: any[]) => any;
	size?: 'normal' | 'small';
	style?: object;
	value?: any[] | boolean | number | string;
	required?: boolean;
	flexGrowChildren?: boolean;
	ariaDescribedby?: string;
	ariaLabelledby?: string;
};
/*
 * HOC that wraps an array of form controls in useful options
 * used in RadioGroup, CheckboxGroup
 */
/**
 * @component
 */
export const ToggleGroup = ({
	autoFocus,
	children,
	className,
	forwardedRef,
	isInvalid,
	multiple,
	name,
	onChange,
	size,
	value,
	required,
	variant,
	ariaDescribedby,
	ariaLabelledby,
	id,
	flexGrowChildren,
	...restProps
}: ToggleGroupProps & UitkBaseProps & HTMLAttributes<HTMLDivElement> & UitkStatics) => {
	const ariaId = id ? id : generate();
	const childrenArray = Children.toArray(children);
	const isChecked = multiple ? isMultipleValueOptionSelected : isSingleValueOptionSelected;
	const handleChange = multiple ? multipleValueHandleChange : singleValueHandleChange;
	const childToAutoFocus =
		autoFocus &&
		(find(childrenArray, (c) => isChecked(value, c.props.value)) ||
			find(childrenArray, (c) => !c.props.disabled));
	return (
		<div
			id={ariaId}
			role={multiple ? 'group' : 'radiogroup'}
			{...restProps}
			className={classnames('ToggleGroup', isInvalid && 'ToggleGroup--isInvalid', className)}
			ref={forwardedRef}
		>
			{childrenArray.map((child: React.ReactElement) => {
				const childName = multiple ? `${name}[${child.props.value}]` : name;
				const childInvalid = child.props.isInvalid || isInvalid;
				const checked = isChecked(value, child.props.value);
				const isAutoFocused = child === childToAutoFocus;
				const childOnChange = handleChange(value, child.props.value, onChange);
				return cloneElement(child, {
					autoFocus: isAutoFocused,
					isInvalid: childInvalid,
					name: childName,
					onChange: childOnChange,
					checked,
					variant,
					required,
					ariaDescribedby,
					setFlexGrow: flexGrowChildren,
				});
			})}
		</div>
	);
};
ToggleGroup.defaultProps = {
	autoFocus: false,
	isInvalid: false,
	multiple: false,
	flexGrowChildren: false,
};
ToggleGroup.isGroupedFormField = true;

export default passRef(ToggleGroup);
