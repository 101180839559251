import React from 'react';
import classnames from 'classnames';
import styled from 'styled-components';

import { UitkBaseProps } from '../../typings';

type ScreenReaderTextProps = {
	el?: any;
	textLink?: boolean;
};

export const ScreenReaderText = ({
	children,
	className,
	el: El = 'span',
	forwardedRef,
	textLink,
	...restProps
}: ScreenReaderTextProps & UitkBaseProps) => (
	<El {...restProps} className={classnames('ScreenReaderText', className)} ref={forwardedRef}>
		{children}
	</El>
);

export default styled(ScreenReaderText)`
	overflow: hidden;
	clip: rect(1px, 1px, 1px, 1px);
	height: 1px;
	width: 1px;
	position: absolute;
`;
