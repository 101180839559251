import React, { HTMLAttributes } from 'react';
import styled from 'styled-components';
import classnames from 'classnames';

import Input from '../Input/Input';
import passRef from '../../hocs/passRef';
import { UitkBaseProps } from '../../typings';

import componentStyle from './Textarea.style';

type TextareaProps = {
	resize?: 'none' | 'both' | 'horizontal' | 'vertical';
	rows?: number;
};

export const Textarea = ({
	resize = 'vertical',
	rows = 5,
	forwardedRef,
	className,
	...restProps
}: TextareaProps & UitkBaseProps & HTMLAttributes<HTMLTextAreaElement>) => (
	<Input
		className={classnames('Textarea', className)}
		resize={resize}
		{...restProps}
		rows={rows}
		size="normal"
		type="textarea"
		ref={forwardedRef}
	/>
);

Textarea.isFormField = true;

export default styled(passRef(Textarea))`
	${componentStyle};
`;
