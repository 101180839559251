import React from 'react';
import classnames from 'classnames';
import { useMediaQuery } from 'react-responsive';
import { withTheme } from 'styled-components';
import { themeGet } from 'styled-system';

import Dropdowns from '../Dropdowns/Dropdowns';
import { UitkBaseProps } from '../../../../typings';

import { Nav } from './Navigation.style';
type NavigationProps = {
	menuConfig?: {
		key?: string;
		pageurl?: string;
		subpages?: any[];
		title?: string;
		url?: string;
	}[];
	navigationType?: string;
	activeItems?: any[];
	colorTheme?: 'adviser' | 'investor';
	highlightLast?: boolean;
	onClick?: (...args: any[]) => any;
	topNavLinks?: any;
	isDesktop?: string;
	/** @ignore */
	theme?: any;
};
const Navigation = withTheme(
	({
		activeItems,
		menuConfig,
		colorTheme,
		highlightLast,
		onClick,
		topNavLinks,
		...restProps
	}: NavigationProps & UitkBaseProps) => {
		const breakpints = themeGet('breakpoints.lg')(restProps);
		const isDesktop = useMediaQuery({
			query: `(min-width: ${breakpints}px)`,
		});
		return (
			<Nav
				aria-label="primary"
				role="navigation"
				className="navigation"
				{...restProps}
				data-uitk="navigation"
			>
				<ul
					className={classnames(
						highlightLast === true ? 'nav-lastItem' : '',
						'Navigation__list'
					)}
					data-uitk="navigation__list"
				>
					{menuConfig.map((menu) => (
						<Dropdowns
							navigationType="navigation"
							activeItems={activeItems}
							key={menu.key}
							menu={menu}
							colorTheme={colorTheme}
							highlightLast={highlightLast}
							onClick={onClick}
							isDesktop={isDesktop}
						/>
					))}
					{topNavLinks &&
						topNavLinks.map((link, index) => (
							<Dropdowns
								navigationType="navigation_toplink"
								key={index}
								menu={{ ...link, subpages: [] }}
								colorTheme={colorTheme}
								onClick={onClick}
								isDesktop={isDesktop}
							/>
						))}
				</ul>
			</Nav>
		);
	}
);
/**
 * @component
 */
export default Navigation;
