import { has, pickBy } from 'lodash';
import React from 'react';
import styled from 'styled-components';
import classnames from 'classnames';

import ScreenReaderMessenger from '../../../ScreenReaderMessenger';
import Card from '../../../Card/Card';
import { UitkBaseProps } from '../../../../typings';

import componentStyle from './PasswordCriteria.style';
import PasswordRule from './PasswordRule';
export const PasswordRules = [
	{
		rule: new RegExp('^.{8,}$'),
		ruleText: 'At least eight (8) characters',
		stateVariable: 'minNumberChars',
	},
	{
		rule: new RegExp('^.*[a-z]'),
		ruleText: 'A lowercase letter (a-z)',
		stateVariable: 'minNumberLowerCaseChars',
	},
	{
		rule: new RegExp('^.*[A-Z]'),
		ruleText: 'An uppercase letter (A-Z)',
		stateVariable: 'minNumberUpperCaseChars',
	},
	{
		rule: new RegExp('^.*\\d'),
		ruleText: 'A number (0-9)',
		stateVariable: 'minNumberIntegers',
	},
	{
		rule: new RegExp('^.*[,!@#$]'),
		ruleText: 'A special character (,!@#$)',
		stateVariable: 'minNumberSpecialChars',
	},
];
export const passwordValidator = (value) => {
	return PasswordRules.reduce(
		(obj, item) => ((obj[item.stateVariable] = item.rule.test(value)), obj),
		{}
	);
};
type PasswordCriteriaProps = {
	show?: boolean;
	value?: string;
};
export const PasswordCriteria = styled(
	({ show, value, className }: PasswordCriteriaProps & UitkBaseProps) => {
		const criteriaPassed = pickBy(passwordValidator(value), (c) => c);
		return (
			<ScreenReaderMessenger>
				<div
					id="passwordCriteria"
					className={classnames(
						className,
						show ? 'Password__PasswordRule--show' : 'Password__PasswordRule--hide'
					)}
				>
					<Card boxShadow="mlcShadowStrong">
						<div className="Password__PasswordRule-title">
							<span>Password must contain the following: </span>
						</div>
						{PasswordRules.map((passRule, key) => (
							<PasswordRule
								valid={has(criteriaPassed, passRule.stateVariable)}
								rule={passRule.ruleText}
								key={key}
								idx={key}
							/>
						))}
					</Card>
				</div>
			</ScreenReaderMessenger>
		);
	}
)`
	${componentStyle};
`;
PasswordCriteria.defaultProps = {
	show: false,
	value: '',
};
export default PasswordCriteria;
