import React from 'react';
import styled from 'styled-components';
import { generate } from 'shortid';

import ToggleGroup from '../ToggleGroup/ToggleGroup';
import passRef from '../../hocs/passRef';
import { UitkBaseProps } from '../../typings';

import toggleButtonStyle from './ToggleButtonGroup.style';

/**
 * @component
 */
type ToggleButtonGroupProps = {
	id?: string;
	name?: string;
	isInvalid?: boolean;
	autoFocus?: boolean;
	onChange: (...args: any[]) => any;
	value?: string;
	flexGrowChildren?: boolean;
};
export const ToggleButtonGroup = ({
	children,
	className,
	id,
	...restProps
}: ToggleButtonGroupProps & UitkBaseProps) => {
	const ariaId = id ? id : generate();
	return (
		<ToggleGroup id={ariaId} name={name} className={className} multiple={false} {...restProps}>
			{children}
		</ToggleGroup>
	);
};

ToggleButtonGroup.isGroupedFormField = true;

ToggleButtonGroup.defaultProps = {
	flexGrowChildren: false,
};

export default styled(passRef(ToggleButtonGroup))`
	${toggleButtonStyle}
`;
