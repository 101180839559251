import React from 'react';
import classnames from 'classnames';

import Context from '../Context/Context';
import { UitkBaseProps } from '../../typings';

type ContainerProps = {
	themeName?:
		| 'mlc'
		| 'dpm'
		| 'blueprint'
		| 'portfolio-focus'
		| 'premium-choice'
		| 'fc-one'
		| 'enevita'
		| 'st-andrews'
		| 'plum';
};
export const Container = ({
	themeName,
	children,
	className,
	...restProps
}: ContainerProps & UitkBaseProps) => {
	return (
		<Context themeName={themeName}>
			<div className={classnames('uitk-container', className)} {...restProps}>
				{children}
			</div>
		</Context>
	);
};
Container.defaultProps = {
	themeName: 'mlc',
};

export default Container;
