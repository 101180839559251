import styled from 'styled-components';

export const UserNav = styled.nav`
	ul,
	li {
		list-style: none;
		padding: 0;
		margin: 0;
	}
`;
