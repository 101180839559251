import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import passRef from '../../hocs/passRef';
import { UitkBaseProps } from '../../typings';

import componentStyle from './TextLink.style.js';
type TextLinkProps = {
	href?: string;
	to?: string;
	el?: string;
	linkStyledButton?: boolean;
	fontWeight?: 'inherit' | 'thin' | 'light' | 'regular' | 'semibold' | 'bold' | 'black';
	textAlign?: 'left' | 'right' | 'center' | 'justify' | 'initial' | 'inherit';
	tabIndex?: -1 | 0;
	onClick?: (...args: any[]) => any;
};
export const TextLink = ({
	el,
	children,
	className,
	href,
	forwardedRef,
	to,
	linkStyledButton,
	tabIndex,
	onClick,
	...restProps
}: TextLinkProps & UitkBaseProps) => {
	const Wrapper = to ? Link : el ? el : 'a';
	const linkChildren = React.Children.map(children, (x: React.ReactElement) =>
		x && x.type ? React.cloneElement(x, { ...x.props, textLink: true }) : x
	);

	const onClickProp =
		el === 'button'
			? {
					onClick: (e: any) => {
						e.preventDefault();
						onClick(e);
					},
			  }
			: { onClick };

	return (
		<Wrapper
			{...restProps}
			{...onClickProp}
			className={className}
			href={href}
			to={to}
			ref={forwardedRef}
			tabIndex={tabIndex}
		>
			{linkChildren}
		</Wrapper>
	);
};

TextLink.defaultProps = {
	tabIndex: 0 as const,
};

export default styled(passRef(TextLink))`
	${componentStyle};
`;
