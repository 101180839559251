import React from 'react';
import { filter, find, flatten } from 'lodash';

import { TabsButtonGroupProps } from '../TabsButtonGroup/TabsButtonGroup';
import { TabsContentProps } from '../TabsContent/TabsContent';

import * as Styled from './Tabs.style';

type TabsProps = {
	/** chidren will be of type TabButtonGroup and TabContent */
	children?: Array<
		React.ReactElement<(TabsContentProps | Array<TabsContentProps>) | TabsButtonGroupProps>
	>;
	/**  Label that is to be used as a label for accessibility  */
	ariaLabel: string;
};

type TabsState = {
	activeTabIndex: number;
};
class Tabs extends React.Component<TabsProps, TabsState> {
	constructor(props: TabsProps) {
		super(props);
		this.state = {
			activeTabIndex: 0,
		};
	}

	componentDidMount() {
		this.setState({
			activeTabIndex: 0,
		});
	}

	handleActiveTabChange = (activeTabIndex: number) => {
		this.setState({
			activeTabIndex,
		});
	};

	getChildren = () => {
		const { children } = this.props;

		const tabsButtonGroup = find(children, (child) => {
			return child.type.tabsRole === 'tabsButtonGroup';
		});
		const tabsContentList = filter(flatten(children), (child) => {
			return child.type.tabsRole === 'tabsContent';
		});

		return {
			tabsButtonGroup,
			tabsContentList,
		};
	};

	validateTabs = (tabsButtonGroup, tabsContentList) => {
		const tabsButtons = React.Children.toArray(tabsButtonGroup.props.children);

		if (tabsButtons.length !== tabsContentList.length) {
			throw Error(
				'The number of TabsButtons in the TabsButtonGroup and TabsContent must be equal'
			);
		}
	};

	render() {
		const { children, ariaLabel } = this.props;
		const { activeTabIndex } = this.state;
		const { tabsButtonGroup, tabsContentList } = this.getChildren();
		if (
			!children ||
			tabsContentList.length === 0 ||
			tabsButtonGroup.props.children.length === 0
		) {
			return null;
		}
		this.validateTabs(tabsButtonGroup, tabsContentList);
		const activeTabPanelId = `tabpanel-${activeTabIndex}`;
		const activeTabId = `tabs-${activeTabIndex}`;

		return (
			<div aria-label={ariaLabel}>
				<Styled.TabList role="tablist">
					{React.cloneElement(tabsButtonGroup, {
						handleActiveTabChange: this.handleActiveTabChange,
					})}
				</Styled.TabList>

				<Styled.TabContent
					aria-labelledby={activeTabId}
					id={activeTabPanelId}
					tabIndex="0"
					role="tabpanel"
				>
					{tabsContentList[activeTabIndex]}
				</Styled.TabContent>
			</div>
		);
	}
}

/**
 * @component
 */
export default Tabs;
