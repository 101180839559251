import React, { useState } from 'react';

import { Announcement } from './types';
import LiveRegion from './ScreenReaderLiveRegion';

/**
 *
 * NOTE: This component should not be used directly, it is provided in the Container by the Context component
 *
 */

/**
 * Context definition and object
 */
type LiveRegionContextType = {
	announce: (announcement: Announcement) => void;
};

export const LiveRegionContext = React.createContext<LiveRegionContextType>({
	announce: () => {},
});

/**
 * Context Provider
 */
export const ScreenReaderLiveRegionProvider = ({ children }) => {
	const [announcement, setAnnouncement] = useState<Announcement>({ children: undefined });

	const announceWithTimeout = (announcement: Announcement) => {
		setAnnouncement(announcement);
		setTimeout(() => {
			setAnnouncement({ ...announcement, children: undefined });
		}, 5000);
	};

	return (
		<>
			<LiveRegion announcement={announcement} />
			<LiveRegionContext.Provider
				value={{
					announce: announceWithTimeout,
				}}
			>
				{children}
			</LiveRegionContext.Provider>
		</>
	);
};
