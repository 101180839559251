export enum DafEnvironments {
    LOCAL = 'LOCAL',
    JENKINS = 'JENKINS',
    DOCKER = 'DOCKER',
    SIT1 = 'SIT1',
    SIT4 = 'SIT4',
    SIT5 = 'SIT5',
    PPTE = 'PPTE',
    STAGING = 'STAGING',
    PROD = 'PROD',
    NFT = 'NFT',
}

export enum GrantTypes {
    AuthorizationCode = 'authorization_code',
    Password = 'password',
    RefreshToken = 'refresh_token',
    ClientCredentials = 'client_credentials',
    UsernamePassword = 'nab:usernamePassword',
    DeviceRegIdPasscode = 'nab:deviceRegIdPasscode',
    DeviceRegIdPassword = 'nab:deviceRegIdPassword',
    DeviceRegIdFingerprint = 'nab:deviceRegIdFingerprint',
    DeviceRegIdFaceprint = 'nab:deviceRegIdFaceprint',
    IvrPasscode = 'nab:ivrPasscode',
    UsernameTokenCode = 'nab:usernameTokenCode',
    UsernamePasswordTokenCode = 'nab:usernamePasswordTokenCode',
    UsernameOTP = 'nab:usernameOTP',
    UsernamePasswordOTP = 'nab:usernamePasswordOTP',
    Saml2Bearer = 'urn:ietf:params:oauth:grant-type:saml2-bearer',
    JwtBearer = 'urn:ietf:params:oauth:grant-type:jwt-bearer',
    TokenExchange = 'urn:ietf:params:oauth:grant-type:token-exchange',
    Anonymous = 'nab:anonymous',
    IvrInAppCall = 'nab:ivrInAppCall',
    WssePasswordDigest = 'nab:wssePasswordDigest',
    Reauthentication = 'nab:reauthentication',
}

export enum DafSdkErrorCodes {
    GeneralError = 'daf_sdk_general_error',
    IllegalArgument = 'daf_sdk_illegal_argument',
    Unauthenticated = 'daf_sdk_unauthenticated',
    Unauthorised = 'daf_sdk_unauthorized',
    InternalServerError = 'daf_sdk_internal_server_error',
    TpsLimitReach = 'daf_sdk_tps_limit_reach',
    BadRequest = 'daf_sdk_bad_request',
}

export enum AuthLevel {
    High = 'high',
    Low = 'low',
    Partial = 'partial',
    None = 'none',
    Fail = 'fail',
}

export enum AuthMethod {
    SMS = 'sms',
    Manual = 'manual',
    MobileApp = 'mobileApp',
    NinAndPin = 'ninAndPin',
    NinNoPin = 'ninNoPin',
    NoNinNoPin = 'noNinNoPin',
}

export enum ProfileType {
    Personal = 'Personal',
    Business = 'Business',
}
