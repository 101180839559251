import React, { AllHTMLAttributes } from 'react';
import styled from 'styled-components';

import { UitkBaseProps, Border, Space } from '../../typings';

import componentStyle from './Text.style';
type TextProps = {
	el?: string | ((...args: any[]) => any);
	backgroundColor?: string;
	border?: Border;
	borderColor?: string;
	borderRadius?: string;
	color?: string;
	display?: string | object;
	fontSize?:
		| (
				| 'default'
				| 'inherit'
				| 'disclaimer'
				| 'miniscule'
				| 'tiny'
				| 'little'
				| 'regular'
				| 'big'
				| 'huge'
		  )
		| object;
	fontWeight?: 'regular' | 'medium' | 'semibold';
	margin?: Space;
	marginBottom?: Space;
	marginLeft?: Space;
	marginRight?: Space;
	marginTop?: Space;
	padding?: Space;
	paddingBottom?: Space;
	paddingLeft?: Space;
	paddingRight?: Space;
	paddingTop?: Space;
	textAlign?: string | object;
	textLink?: boolean;
};
export const Text = ({
	el: El,
	backgroundColor,
	border,
	borderColor,
	borderRadius,
	color,
	display,
	fontSize,
	fontWeight,
	margin,
	marginBottom,
	marginLeft,
	marginRight,
	marginTop,
	padding,
	paddingBottom,
	paddingLeft,
	paddingRight,
	paddingTop,
	textAlign,
	textLink,
	...restProps
}: UitkBaseProps & TextProps & AllHTMLAttributes<HTMLElement>) => {
	return <El {...restProps} />;
};
Text.defaultProps = {
	el: 'span',
};

export default styled(Text)`
	${componentStyle};
`;
