import { Link } from 'react-router-dom';
import React from 'react';
import styled from 'styled-components';

import passRef from '../../../../hocs/passRef';
import { UitkBaseProps } from '../../../../typings';

import componentStyle from './DropdownLink.style';
type DropdownLinkProps = {
	href?: string;
	link?: string;
	navItemRole?: string;
	isAbsoluteLink?: boolean;
	to?: string;
	openInNewTab?: boolean;
};
export const DropdownLink = styled(
	passRef(
		({
			children,
			link,
			isAbsoluteLink,
			navItemRole,
			forwardedRef,
			openInNewTab,
			...restProps
		}: DropdownLinkProps & UitkBaseProps) => {
			const linkChildren = React.Children.map(children, (x: any) =>
				x && x.type ? React.cloneElement(x, { ...x.props }) : x
			);
			const role = navItemRole === 'user' ? 'button' : 'link';
			const to = isAbsoluteLink === true ? null : link;
			const target = openInNewTab ? '_blank' : null;
			const Wrapper = to ? Link : 'a';
			return (
				<Wrapper
					{...restProps}
					href={link}
					ref={forwardedRef}
					to={to}
					role={role}
					target={target}
					tabIndex={0}
				>
					{linkChildren}
				</Wrapper>
			);
		}
	)
)`
	${componentStyle};
`;
DropdownLink.defaultProps = {
	openInNewTab: false,
};
/** @component */
export default DropdownLink;
