import classnames from 'classnames';
import React from 'react';
import styled from 'styled-components';

import { UitkBaseProps } from '../../typings';

import TableStyle from './Table.style';
export type TableProps = {
	border?: 'mlcBorderThin' | 'mlcBorderThick';
	borderColor?: string;
	variant?: 'zebraStripes' | 'bottomLines';
	layout?: 'auto' | 'fixed';
};
export const Table = ({
	children,
	className,
	layout,
	forwardedRef,
	variant,
	border,
	borderColor,
	...restProps
}: UitkBaseProps & TableProps) => (
	<table {...restProps} className={classnames(`Table--${layout}`, className)} ref={forwardedRef}>
		{children}
	</table>
);

Table.defaultProps = {
	layout: 'auto',
};
export default styled(Table)`
	${TableStyle};
`;
