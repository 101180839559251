import React, { InputHTMLAttributes } from 'react';
import withFocus from '@mlc/with-focus';
import classnames from 'classnames';
import styled from 'styled-components';
import { generate } from 'shortid';

import { UitkBaseProps, FieldWrapperClone } from '../../typings';
import passRef from '../../hocs/passRef';

import componentStyle from './ToggleButton.style';
type ToggleButtonProps = {
	autoFocus?: boolean;
	checked?: boolean;
	isInvalid?: boolean;
	isFirst?: boolean;
	multiple?: boolean;
	isFocused?: boolean;
	setFlexGrow?: boolean;
};
export const ToggleButton = ({
	autoFocus,
	checked,
	children,
	className,
	disabled,
	forwardedRef,
	isFocused,
	isInvalid,
	multiple,
	name,
	onChange,
	size,
	value,
	required,
	ariaDescribedby,
	setFlexGrow,
	...restProps
}: UitkBaseProps & FieldWrapperClone & ToggleButtonProps & InputHTMLAttributes<HTMLElement>) => {
	const randomId = generate();
	return (
		<div
			className={classnames(
				!disabled && checked && 'ToggleButton--isChecked',
				!disabled && isFocused && 'ToggleButton--isFocused',
				isInvalid && 'ToggleButton--isInvalid',
				setFlexGrow && 'ToggleButton__flexGrow',
				className
			)}
		>
			<input
				id={randomId}
				aria-invalid={isInvalid}
				aria-describedby={ariaDescribedby}
				autoFocus={autoFocus}
				checked={checked}
				className={classnames('ToggleButton__input')}
				disabled={disabled}
				name={name}
				onChange={onChange}
				ref={forwardedRef}
				type={multiple ? 'checkbox' : 'radio'}
				value={value}
				required={required}
				{...restProps}
			/>
			<label htmlFor={randomId} className="ToggleButton__label">
				{children}
			</label>
		</div>
	);
};

export default styled(passRef(withFocus(ToggleButton)))`
	${componentStyle};
`;
