import React, { InputHTMLAttributes } from 'react';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';

import { mlcSymbol } from '../Svg';
import { defaultFormat } from '../BareFormattedInput/BareFormattedInput';
import Input from '../Input';
import passRef from '../../hocs/passRef';
import { UitkBaseProps } from '../../typings';

type NumberInputProps = {
	allowDecimal?: boolean;
	allowLeadingZeroes?: boolean;
	allowNegative?: boolean;
	decimalLimit?: number;
	/** @ignore */
	decimalSymbol?: string;
	/** @ignore */
	includeThousandsSeparator?: boolean;
	/** Host can only take 11 digits for currency values. Set to a higher number if needed. */
	integerLimit?: number;
	isInvalid?: boolean;
	onChange?: (...args: any[]) => any;
	/** @ignore */
	prefix?: string;
	/** @ignore */
	requireDecimal?: boolean;
	/** @ignore */
	style?: object;
	/** @ignore */
	suffix?: string;
	/** @ignore */
	thousandsSeparatorSymbol?: string;
	value?: string;
	variant?: 'boxed' | 'normal';
	isLoading?: boolean;
	/** See [Input](#!/Input) and [Svg](#!/Svg) */
	symbol?: mlcSymbol;
	/** See [Input](#!/Input) */
	symbolPosition?: 'left' | 'right';
};

export const NumberInput = ({
	allowDecimal,
	allowLeadingZeroes,
	allowNegative,
	decimalLimit,
	decimalSymbol,
	includeThousandsSeparator,
	integerLimit,
	prefix,
	requireDecimal,
	style,
	suffix,
	thousandsSeparatorSymbol,
	forwardedRef,
	...restProps
}: NumberInputProps & UitkBaseProps & InputHTMLAttributes<HTMLInputElement>) => (
	<Input
		{...restProps}
		ref={forwardedRef}
		format={{
			...defaultFormat,
			mask: createNumberMask({
				allowDecimal,
				allowLeadingZeroes,
				allowNegative,
				decimalLimit,
				decimalSymbol,
				includeThousandsSeparator,
				integerLimit,
				prefix,
				requireDecimal,
				suffix,
				thousandsSeparatorSymbol,
			}),
		}}
		style={style}
	/>
);
NumberInput.defaultProps = {
	allowDecimal: true,
	allowLeadingZeroes: false,
	allowNegative: false,
	decimalLimit: 2,
	decimalSymbol: '.',
	includeThousandsSeparator: true,
	integerLimit: 11,
	prefix: '',
	requireDecimal: false,
	style: { textAlign: 'right' as 'right' },
	suffix: '',
	thousandsSeparatorSymbol: ',',
};

NumberInput.isFormField = true;

export default passRef(NumberInput);
