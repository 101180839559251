/**
 * moneyBag
 * Don't modify this file. It is generated by scripts/build.js and is
 * ignored by git. The source svg file is located in the '../src' directory.
 */
import BrowserSpriteSymbol from 'svg-baker-runtime/browser-symbol';

const symbol = new BrowserSpriteSymbol({
  "id": "symbol-moneyBag-4213063280",
  "use": "symbol-moneyBag-4213063280-usage",
  "viewBox": "0 0 48 48",
  "content": "<symbol viewBox=\"0 0 48 48\" id=\"symbol-moneyBag-4213063280\"><g id=\"symbol-moneyBag-4213063280_NPS-Form\" stroke=\"none\" stroke-width=\"1\" fill=\"none\" fill-rule=\"evenodd\"><g id=\"symbol-moneyBag-4213063280_NPS-Form-desktop--Error-page\" transform=\"translate(-576 -379)\"><g id=\"symbol-moneyBag-4213063280_INVESTOR-SECURE/ICON/2-tone/MLC-Orange-#c95109/dark-background/moneybag\" transform=\"translate(576 379)\"><g id=\"symbol-moneyBag-4213063280_customer-support-(1)\"><path d=\"M46.236 37.928c.295.298.558.629.784.984l-8.046 8.058a5.818 5.818 0 0 1-.981-.785l-9.834-9.85.412-.412a1.755 1.755 0 0 0 0-2.478 1.748 1.748 0 0 0-2.473 0l-.412.413-1.009-.881 8.243-8.257 1.01.881-.413.413a1.754 1.754 0 0 0 0 2.477c.683.684 1.79.684 2.473 0l.412-.413 9.834 9.85zm-13.715-4.312l7.244 7.296 1.165-1.167-7.243-7.296-1.166 1.167zM10.023 6.61l11.3 10.932-3.297 3.303-11.3-10.932-3.297-.826L.13 3.307 3.43.004l5.77 3.303.824 3.303z\" id=\"symbol-moneyBag-4213063280_Combined-Shape\" fill=\"#741915\" /><path d=\"M47.02 38.912l-2.374 2.378 2.374-2.378zm.072.114a5.84 5.84 0 0 1-.072 6.19 5.816 5.816 0 0 1-8.046 1.754l5.672-5.68zm.573-32.137a9.078 9.078 0 0 1 0 4.46c-1.23 4.844-6.147 7.772-10.982 6.54L17.987 36.616a9.159 9.159 0 0 1 0 4.495c-1.24 4.89-6.201 7.847-11.083 6.606C2.022 46.476-.93 41.506.31 36.617c1.239-4.89 6.2-7.848 11.082-6.607l18.696-18.727a9.065 9.065 0 0 1 0-4.472c1.233-4.84 6.15-7.763 10.982-6.528l-5.492 5.5 1.649 4.955 4.946 1.652 5.492-5.501zM11.672 42.94l2.473-4.129-2.473-4.128H6.726l-2.473 4.129 2.473 4.128h4.946zm2.713-10.493l1.166 1.168L33.686 15.45l-1.166-1.168-18.135 18.166z\" id=\"symbol-moneyBag-4213063280_Combined-Shape\" fill=\"#C95109\" /></g></g></g></g></symbol>"
});


symbol.name = "moneyBag";

export default symbol;
