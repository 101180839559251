import React from 'react';

import { defaultFormat } from '../BareFormattedInput/BareFormattedInput';
import Input from '../Input';
import passRef from '../../hocs/passRef';
import { UitkBaseProps } from '../../typings';

import { maskFunc } from './Masks';

const format = {
	...defaultFormat,
	mask: maskFunc,
};
type CreditCardInputProps = {
	isInvalid?: boolean;
	onChange?: (...args: any[]) => any;
	value?: string;
	isLoading?: boolean;
};
export const CreditCardInput = ({
	forwardedRef,
	...restProps
}: UitkBaseProps & CreditCardInputProps & React.HTMLAttributes<HTMLInputElement>) => {
	return <Input ref={forwardedRef} {...restProps} format={format} />;
};
CreditCardInput.isFormField = true;
export default passRef(CreditCardInput);
