import React, { useState, useEffect, useCallback } from 'react';
import classnames from 'classnames';

import { UitkBaseProps } from '../../typings';

import DropdownItem from './components/DropdownItem/DropdownItem';
import { MenuContainer } from './DropdownMenu.style';

type DropdownMenuProps = {
	/** Items to show in the dropdown, are anchors by default but can pass isAbsoluteLink to render React Link. Can also pass an onClick */
	menuConfig?: {
		key?: string;
		pageurl?: string;
		subpages?: any[];
		title?: string;
		isAbsoluteLink?: boolean;
		openInNewTab?: boolean;
		type?: string;
		onClick?: (...args: any[]) => any;
	}[];
	/** Custom button used to open and close the dropdown */
	openButton: any;
	/** Align dropdown relative to center of button */
	alignMenu?: 'left' | 'center' | 'right';
	/** Adjust spacing between menu and button */
	menuMarginTop?: number | string;
	/** Change the dropdown item's colour when hovered - pass in the name of a color from the MLC Palette*/
	hoverColor?: string;
	/** Change the dropdown item's text decoration when hovered */
	hoverDecoration?: string;
	/** Change the dropdown item's text decoration */
	textDecoration?: string;
	/** Custom onChange function to handle dropdown open state */
	onChange?: (...args: any[]) => any;
	/** Initial dropdown open state, can also be controlled by the onChange prop */
	isOpen?: boolean;
};

export const DropdownMenu = ({
	menuConfig,
	openButton,
	alignMenu,
	menuMarginTop,
	hoverColor,
	hoverDecoration,
	textDecoration,
	isOpen,
	onChange,
}: DropdownMenuProps & UitkBaseProps) => {
	const [isDropdownOpen, setIsDropdownOpen] = useState(false);

	//handle menu close
	const handleMenuClose = useCallback(() => {
		if (onChange) {
			isDropdownOpen && onChange();
		} else {
			setIsDropdownOpen(false);
		}
	}, [isDropdownOpen, onChange]);

	//handle Escape key
	const handleEscapeKeyDown = (e: any) => {
		if (e.key === 'Escape') {
			handleMenuClose();
		}
	};

	// handle blur
	const handleBlur = (e: any) => {
		const dropdownsContainFocus =
			!!(
				e.target.nextElementSibling && e.target.nextElementSibling.contains(e.relatedTarget)
			) ||
			!!(
				e.target.closest('ul.is-open') &&
				e.target.closest('ul.is-open').contains(e.relatedTarget)
			);
		//The purpose of using relatedTarget:
		//https://developer.mozilla.org/en-US/docs/Web/API/MouseEvent/relatedTarget

		// check if focus is still in the opened list
		if (dropdownsContainFocus === false) {
			handleMenuClose();
		}
	};

	// handle click
	const handleMenuOnclick = (e: any) => {
		e.preventDefault();
		// to set focus on safai
		e.target.focus();
		if (onChange) {
			onChange();
		} else {
			if (isDropdownOpen === true) {
				handleMenuClose();
			} else {
				setIsDropdownOpen(true);
			}
		}
	};

	useEffect(() => {
		setIsDropdownOpen(isOpen);
	}, [isOpen]);

	useEffect(() => {
		document.addEventListener('keydown', handleEscapeKeyDown);
		window.addEventListener('resize', handleMenuClose);
		Array.prototype.forEach.call(
			document.querySelectorAll('[data-uitk="dropdown-menu"]'),
			(menu) => {
				menu.addEventListener('focusout', handleBlur);
			}
		);
		return () => {
			Array.prototype.forEach.call(
				document.querySelectorAll('[data-uitk="dropdown-menu"]'),
				(menu) => {
					menu.removeEventListener('focusout', handleBlur);
				}
			);
			document.removeEventListener('keydown', handleEscapeKeyDown);
			window.removeEventListener('resize', handleMenuClose);
		};
	}, [handleBlur, handleEscapeKeyDown, handleMenuClose]);

	const button = React.cloneElement(openButton, {
		onClick: handleMenuOnclick,
		onKeyUp: (e: any) => {
			if (e.keyCode === 13 || e.keyCode === 32) {
				handleMenuOnclick(e);
			}
		},
		onKeyDown: (e: any) => {
			if (e.keyCode === 13 || e.keyCode === 32) {
				e.preventDefault();
			}
		},
		'aria-expanded': isDropdownOpen,
		'data-uitk': `dropdown-menu-button`,
		// to solve safari e.relatedTarget returns null issue
		// https://stackoverflow.com/questions/42764494/blur-event-relatedtarget-returns-null
		tabIndex: '0',
		role: 'button',
	});

	return (
		<MenuContainer
			data-uitk="dropdown-menu"
			alignMenu={alignMenu}
			menuMarginTop={menuMarginTop}
		>
			{button}
			<div>
				<ul
					className={classnames(
						'Menu__list',
						'is-main',
						isDropdownOpen === true ? 'is-open' : ''
					)}
					data-uitk="menu__list"
				>
					{menuConfig.map((item) => (
						<DropdownItem
							key={item.key}
							menu={item}
							hoverColor={hoverColor}
							hoverDecoration={hoverDecoration}
							textDecoration={textDecoration}
						/>
					))}
				</ul>
			</div>
		</MenuContainer>
	);
};

DropdownMenu.defaultProps = {
	alignMenu: 'center',
};

export default DropdownMenu;
