import React from 'react';
import styled from 'styled-components';
import { themeGet } from 'styled-system';

import Text from '../Text';

export const HeaderStyle = styled.header`
	* {
		box-sizing: border-box;
	}
	position: relative;

	.Header__logo {
		display: none;
		overflow: hidden;
		@media (min-width: ${themeGet('gridTheme.breakpoints.lg')}px) {
			display: block;
			padding-left: 10px;
		}
	}
	.Header__links {
		display: none;
		@media (min-width: ${themeGet('gridTheme.breakpoints.lg')}px) {
			display: flex;
			align-items: center;
			font-size: ${themeGet('fontSizes.disclaimer')}px;
			padding-left: 10px;
		}
	}
	.Header__mobile-logo {
		display: flex;
		align-items: center;
	}
	.Header__mobile-icon {
		margin-left: 10px;
	}
	.Header__user {
		width: 100%;
		@media (min-width: ${themeGet('gridTheme.breakpoints.lg')}px) {
			width: auto;
		}
	}
	.Header__navigation {
		width: 100%;
	}
	.Header__panel {
		justify-content: space-between;
		align-items: center;
		display: flex;

		& + .Header__panel {
			@media (min-width: ${themeGet('gridTheme.breakpoints.lg')}px) {
				margin-top: 10px;
			}
		}

		@media (min-width: ${themeGet('gridTheme.breakpoints.lg')}px) {
			margin-left: -10px;
			margin-right: -15px;
		}
	}
	.Header__mobile-header {
		@media (max-width: ${themeGet('gridTheme.breakpoints.lg')}px) {
			display: flex;
			justify-content: space-between;
			align-items: center;
		}
		display: none;
	}
	.Header__mobile-nav {
		@media (max-width: ${themeGet('gridTheme.breakpoints.lg')}px) {
			visibility: hidden;
			position: fixed;
			z-index: 9999;
			top: 0;
			bottom: 0;
			right: -100%;
			width: 100%;
			height: 100vh;
			overflow-x: hidden;
			transition: right ease-in-out 0.4s, visibility ease-in-out 0.2s;
			background-color: ${themeGet('colors.mlcWhite')};
			height: 100%;
			overflow-y: scroll;

			.Header__mobile-header.is-close {
				display: flex;
			}
		}
	}
	&.is-open {
		.Header__mobile-nav {
			visibility: visible;
			right: 0;
			height: 100%;
			transition: right ease-in-out 0.4s;
		}
	}
	.Header__mobile-header-wrapper {
		padding: 0 10px;
	}
`;

export const HeaderButton = styled.button`
	font-family: ${themeGet('fonts.fontFamily')};
	font-weight: ${themeGet('fontWeights.regular')};
	font-size: ${themeGet('fontSizes.default')}px;
	text-decoration: none;
	position: relative;
	color: ${themeGet('colors.mlcText')};
	text-align: center;
	display: inline-flex;
	align-items: center;
	padding: 10px 0 10px 10px;
	border: 0;
	width: auto;
	margin: 5px 0;
	cursor: pointer;
	background-color: ${themeGet('colors.mlcWhite')};
	&:focus,
	&:hover {
		text-decoration: underline;
		outline: 1px solid transparent;
	}
	@media (min-width: ${themeGet('gridTheme.breakpoints.lg')}px) {
		font-weight: ${themeGet('fontWeights.medium')};
	}
`;

export const HeaderLink = styled.a`
	color: ${themeGet('colors.mlcText')};
	text-decoration: none;
	margin-right: 10px;
	&:hover,
	&:focus {
		text-decoration: underline;
		outline: 1px solid transparent;
	}
`;

export const HeaderText = ({ ...props }) => (
	<Text color="mlcText" fontSize="disclaimer" {...props} />
);
