export const DEFAULT_MASK = [
	/\d/,
	/\d/,
	/\d/,
	/\d/,
	' ',
	/\d/,
	/\d/,
	/\d/,
	/\d/,
	' ',
	/\d/,
	/\d/,
	/\d/,
	/\d/,
	' ',
	/\d/,
	/\d/,
	/\d/,
	/\d/,
];

export const AMEX_MASK = [
	/\d/,
	/\d/,
	/\d/,
	/\d/,
	' ',
	/\d/,
	/\d/,
	/\d/,
	/\d/,
	/\d/,
	/\d/,
	' ',
	/\d/,
	/\d/,
	/\d/,
	/\d/,
	/\d/,
];
export const AMEX_REGEX = /^\D*(3[47])/;
export const DINERS_MASK = [
	/\d/,
	/\d/,
	/\d/,
	/\d/,
	' ',
	/\d/,
	/\d/,
	/\d/,
	/\d/,
	/\d/,
	/\d/,
	' ',
	/\d/,
	/\d/,
	/\d/,
	/\d/,
];
export const DINERS_REGEX = /^\D*((30[0-59])|3[689])/;

export const maskFunc = (value) =>
	(AMEX_REGEX.test(value) && AMEX_MASK) ||
	(DINERS_REGEX.test(value) && DINERS_MASK) ||
	DEFAULT_MASK;
