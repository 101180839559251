import React, { HTMLAttributes } from 'react';
import classnames from 'classnames';
import styled from 'styled-components';

import passRef from '../../hocs/passRef';
import { UitkBaseProps, Space, Border } from '../../typings';

import componentStyle from './Heading.style.js';

type HeadingProps = {
	visual?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'p';
	el?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'p' | 'div';
	backgroundColor?: string;
	border?: Border;
	borderColor?: string;
	borderRadius?: string;
	color?: string;
	display?: string | object;
	fontSize?: ('miniscule' | 'tiny' | 'little' | 'regular' | 'big' | 'huge' | 'hero') | object;
	fontWeight?: 'regular' | 'medium' | 'semibold' | 'bold';
	margin?: Space;
	marginBottom?: Space;
	marginLeft?: Space;
	marginRight?: Space;
	marginTop?: Space;
	padding?: Space;
	paddingBottom?: Space;
	paddingLeft?: Space;
	paddingRight?: Space;
	paddingTop?: Space;
	textAlign?: string | object;
	textLink?: boolean;
};

export const Heading = ({
	visual,
	el = 'h1',
	children,
	className,
	backgroundColor,
	border,
	borderColor,
	borderRadius,
	color,
	display,
	fontSize,
	fontWeight,
	forwardedRef,
	margin,
	marginBottom,
	marginLeft,
	marginRight,
	marginTop,
	padding,
	paddingBottom,
	paddingLeft,
	paddingRight,
	paddingTop,
	textAlign,
	textLink,
	...restProps
}: HeadingProps & UitkBaseProps & HTMLAttributes<HTMLHeadingElement>) => {
	const visualStyle = visual ? visual : null;
	const El = el || visualStyle;

	return (
		<El
			className={classnames(className, 'Heading--typography')}
			ref={forwardedRef}
			{...restProps}
			tabIndex={-1}
		>
			{children}
		</El>
	);
};

export default styled(passRef(Heading))`
	${componentStyle}
`;
