import React from 'react';
import classnames from 'classnames';
import styled from 'styled-components';

import { UitkBaseProps } from '../../typings';

import TableRowStyle from './TableRow.style';
type TableRowProps = {
	/**	**Deprecated:** Use the variant API on Table instead Adds a line on the bottom of the row */
	bottomLine?: boolean;
	/**	**Deprecated:** the only color that should need to be changed should come from Table variants Changes the color of the row */
	rowColor?: string;
};
export const TableRow = ({
	bottomLine,
	children,
	className,
	forwardedRef,
	rowColor,
	...restProps
}: UitkBaseProps & TableRowProps) => (
	<tr
		{...restProps}
		className={classnames('TableRow', `TableRow--${rowColor}`, className)}
		ref={forwardedRef}
	>
		{children}
	</tr>
);

TableRow.defaultProps = {
	bottomLine: false,
	rowColor: 'mlcWhite',
};
export default styled(TableRow)`
	${TableRowStyle};
`;
