import { css } from 'styled-components';
import { themeGet, border, borderColor } from 'styled-system';

const zebtraStripesStyles = css`
	tbody tr {
		:nth-child(even) {
			background-color: ${themeGet(
				'colors.mlcAlmostWhite'
			)}80; /* Adds transparency to color */
		}

		:nth-child(odd) {
			background-color: ${themeGet('colors.mlcWhite')};
		}
	}
`;

const bottomLinesStyles = css`
	tbody tr {
		border-bottom: thin solid ${themeGet('colors.mlcLightGrey')};
		&:last-child {
			border-bottom: none;
		}
	}
`;

export default ({ layout, variant }) => {
	return css`
		${themeGet('global.base')};
		width: 100%;
		padding: 20px;
		border-collapse: collapse;
		table-layout: ${layout};

		${variant === 'zebraStripes' ? zebtraStripesStyles : null}
		${variant === 'bottomLines' ? bottomLinesStyles : null}
		${border}
		${borderColor}
	`;
};
