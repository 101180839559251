/**
 * chartPie
 * Don't modify this file. It is generated by scripts/build.js and is
 * ignored by git. The source svg file is located in the '../src' directory.
 */
import BrowserSpriteSymbol from 'svg-baker-runtime/browser-symbol';

const symbol = new BrowserSpriteSymbol({
  "id": "symbol-chartPie-4242487143",
  "use": "symbol-chartPie-4242487143-usage",
  "viewBox": "0 0 544 512",
  "content": "<symbol viewBox=\"0 0 544 512\" id=\"symbol-chartPie-4242487143\"><path d=\"M511.96 223.2C503.72 103.74 408.26 8.28 288.8.04c-.35-.03-.7-.04-1.04-.04C279.11 0 272 7.44 272 16.23V240h223.77c9.14 0 16.82-7.68 16.19-16.8zM320 192V53.51C387.56 70.95 441.05 124.44 458.49 192H320zm-96 96V50.72c0-8.83-7.18-16.21-15.74-16.21-.7 0-1.4.05-2.11.15C86.99 51.49-4.1 155.6.14 280.37 4.47 407.53 113.18 512 240.12 512c.98 0 1.93-.01 2.91-.02 50.4-.63 96.97-16.87 135.26-44.03 7.9-5.6 8.42-17.23 1.57-24.08L224 288zm18.44 175.99l-2.31.01c-100.66 0-188.59-84.84-192.01-185.26-2.91-85.4 50.15-160.37 127.88-187.6v216.74l14.06 14.06 126.22 126.22c-23.16 10.1-48.16 15.5-73.84 15.83zM527.79 288H290.5l158.03 158.03c3.17 3.17 7.41 4.81 11.62 4.81 3.82 0 7.62-1.35 10.57-4.13 38.7-36.46 65.32-85.61 73.13-140.86 1.34-9.46-6.51-17.85-16.06-17.85z\" /></symbol>"
});


symbol.name = "chartPie";

export default symbol;
