import styled, { css, keyframes } from 'styled-components';
import { themeGet } from 'styled-system';
import ReactModal from 'react-modal';

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

const resetSpace = css`
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
`;

const absolute = css`
	${resetSpace}
	position: absolute;
	margin: 0;
`;

export const spinnerStyles = ({ variant, colorTheme }) => {
	const borderSize = variant === 'inline' ? 1.5 : 4;
	const width = variant === 'inline' ? '16px' : '100%';

	const accent =
		colorTheme === 'dark'
			? themeGet('colors.mlcMediumGrey60')
			: themeGet('colors.mlcMediumGrey60');

	const primary =
		colorTheme === 'dark' ? themeGet('colors.mlcVeryDarkGrey60') : themeGet('colors.mlcWhite');

	return css`
		box-sizing: border-box;
		&,
		&:after {
			border-radius: 50%;
			width: ${width};
			height: 0;
			padding-bottom: calc(${width} - ${borderSize * 2}px);
		}

		border-top: ${borderSize}px solid ${accent};
		border-right: ${borderSize}px solid ${accent};
		border-bottom: ${borderSize}px solid ${accent};
		border-left: ${borderSize}px solid ${primary};

		animation: ${rotate} 1.5s infinite linear;
	`;
};

export const LoadingSpinner = styled.div`
	${spinnerStyles}
`;

export const loadingWrapperStyles = ({ variant }) => {
	const margin =
		variant === 'inline' ? '0 10px' : variant === 'fullscreen' ? '10px auto' : '30px';
	const display = variant === 'inline' ? 'inline-block' : 'block';

	return css`
		display: ${display};
		margin: ${margin};
		width: ${variant === 'fullscreen' ? '60px' : ''};
	`;
};

// general formatting of loading area
export const LoadingWrapper = styled.span`
	${loadingWrapperStyles}
`;

// fixed background for the modal
export const Overlay = styled(ReactModal)`
	${resetSpace}
	display: flex;
	position: fixed;
	align-items: center;
	justify-content: center;
	background-color: ${({ colorTheme }) =>
		colorTheme === 'dark' ? 'rgba(255, 255, 255, 0.8)' : 'rgba(0, 0, 0, 0.7)'};
`;

// absolutely positioned context within the loading spinner area
export const PercentageWrapper = styled.div`
	${absolute}
`;

// flex context within the PercentageWrapper for aligning the numbers
export const PercentagePosition = styled.div`
	height: 100%;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
`;
