import styled, { css } from 'styled-components';
import { media } from 'styled-bootstrap-grid';
import { themeGet } from 'styled-system';

import { isIE11 } from '../../utils/UserAgent';

const timeoutBarHeight = 5;

export default () => css`
	&__htmlOpen {
		z-index: 1;
		overflow: hidden;
		min-height: 100vh;
		height: ${isIE11() ? '100vh' : null};
	}	

	&__bodyOpen {
		position: static;
		overflow-y: scroll;
	}

	&__reactModal {
		${media.md`
			top: 20vh;
			outline-style: none;
			width: 600px;
			max-width: 600px;
			position: ${isIE11() ? 'static' : 'absolute'};
		`}

		${media.sm`
			margin-left: 15px;
			margin-right: 15px;
		`}

		${media.xs`
			margin-left: 15px;
			margin-right: 15px;
		`}

		max-height: 70vh;
		overflow-y: auto;
		box-shadow: none;
		box-sizing: border-box;
		background-color: ${themeGet('colors.mlcWhite')};
	}

	&__overlay {
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		display: flex;
		position: fixed;
		align-items: center;
		justify-content: center;
		background-color: rgba(0, 0, 0, 0.6);
	}

	&__timeoutPortal {
		position: fixed;
		z-index: 5;
	}

	&.timeoutModal__body {
		z-index: 1;
		display: flex;
		max-height: calc(100vh - 20px);
		flex-direction: column;
		border: thin solid #c8c6c2;
		border-bottom: none;
		justify-content: space-between;
	}
	
	progress.TimeoutModal__bar {
		appearance: none;
		width: 100%;
		height: ${timeoutBarHeight}px;
		border: 0;
		border-radius: ${timeoutBarHeight}px;
		background-color: ${themeGet('colors.mlcMediumGrey60')};
	}

	progress.TimeoutModal__bar::-webkit-progress-bar {
		background-color: ${themeGet('colors.mlcMediumGrey60')};
		border-radius: ${timeoutBarHeight}px;
	}

	progress.TimeoutModal__bar::-webkit-progress-value {
		background-color: ${themeGet('colors.mlcOrange')};
		border-radius: ${timeoutBarHeight}px;
		transition: width 0.5s ease-in-out;
	}

	progress.TimeoutModal__bar::-moz-progress-bar {
		background-color: ${themeGet('colors.mlcOrange')};
		border-radius: ${timeoutBarHeight}px;
		transition: width 0.5s ease-in-out;
	}

	progress.TimeoutModal__bar::-ms-fill {
		border: none;
		background-color: ${themeGet('colors.mlcOrange')};
		border-radius: ${timeoutBarHeight}px;
	}
	/* https://stackoverflow.com/questions/16982449/why-isnt-it-possible-to-combine-vendor-specific-pseudo-elements-classes-into-on */
`;

export const TimeoutModalProgressContainer = styled.div`
	&.timeoutModalContentContainer {
		margin: 30px;
	}

	&.timeoutModalContentContainer--mlcWhite {
		margin: 10px 30px 30px 30px;
	}

	text-align: ${(props) => props.align};
`;

export const TimeoutModalContentContainer = styled.div`
	&.timeoutModalContentContainer {
		margin: 30px;
	}

	&.timeoutModalContentContainer--mlcWhite {
		margin: 0px 30px 30px 30px;
	}

	text-align: ${(props) => props.align};
`;
