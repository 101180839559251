import React, { InputHTMLAttributes } from 'react';
import styled from 'styled-components';
import classnames from 'classnames';
import { generate } from 'shortid';

import Label from '../Label/Label';
import passRef from '../../hocs/passRef';
import { UitkBaseProps, FieldWrapperClone } from '../../typings';

import componentStyle from './Radio.style';

type RadioProps = {
	checked?: any;
	formNoValidate?: boolean;
	isInvalid?: boolean;
	label?: string;
	variant?: 'boxed' | 'normal';
	required?: boolean;
	/** @ignore */
	name: string;
	/** @ignore */
	disabled?: boolean;
	/** @ignore */
	autoFocus?: boolean;
	colorVariant?: 'primary' | 'secondary';
};

export const Radio = ({
	autoFocus,
	checked,
	className,
	disabled,
	formNoValidate = true,
	forwardedRef,
	id,
	isInvalid,
	label,
	name,
	onChange,
	value,
	children,
	required,
	ariaDescribedby,
	ariaLabelledby,
	variant = 'normal',
	colorVariant,
	...restProps
}: RadioProps & UitkBaseProps & InputHTMLAttributes<HTMLInputElement> & FieldWrapperClone) => {
	const ariaId = id ? id : generate();
	const checkedValue = checked !== 'indeterminate' && checked;
	const labelValue = label ? label : children;
	const handleChange = (event) => (disabled || !onChange ? null : onChange(event));
	return (
		<div
			className={classnames(
				{
					Radio: true,
					'Radio--isIndeterminate': checked === 'indeterminate',
					'Radio--isChecked': checked === 'indeterminate' || checked,
					'Radio--isUnchecked': !checked,
					'Radio--isInvalid': isInvalid,
					'Radio--isDisabled': disabled,
					[`Radio--${variant}`]: variant,
				},
				className
			)}
		>
			<input
				id={ariaId}
				autoFocus={autoFocus}
				className="Radio__input"
				checked={checkedValue}
				disabled={disabled}
				formNoValidate={formNoValidate}
				onChange={handleChange}
				name={name}
				ref={forwardedRef}
				type="radio"
				value={value}
				aria-checked={checkedValue}
				aria-invalid={isInvalid}
				aria-describedby={ariaDescribedby}
				aria-labelledby={ariaLabelledby}
				required={required}
				{...restProps}
			/>
			<Label className="Radio__label" htmlFor={ariaId}>
				{labelValue}
			</Label>
		</div>
	);
};

Radio.isFormField = true;
Radio.defaultProps = {
	colorVariant: 'secondary' as const,
};

export default styled(passRef(Radio))`
	${componentStyle};
`;
