import React from 'react';
import { check } from '@mlc/symbols';
import styled from 'styled-components';
import classnames from 'classnames';
import { generate } from 'shortid';

import Svg from '../Svg/Svg';
import Label from '../Label/Label';
import passRef from '../../hocs/passRef';
import { UitkBaseProps, FieldWrapperClone } from '../../typings';

import componentStyle from './Checkbox.style';

type CheckboxProps = {
	checked?: any;
	formNoValidate?: boolean;
	isInvalid?: boolean;
	label?: string;
	onChange?: (...args: any[]) => any;
	value?: boolean | number | string;
	required?: boolean;
};
export const Checkbox = ({
	autoFocus,
	checked,
	className,
	disabled,
	formNoValidate,
	forwardedRef,
	isInvalid,
	label,
	onChange,
	readOnly,
	style,
	type,
	value,
	required,
	ariaDescribedby,
	ariaLabelledby,
	id,
	children,
	...restProps
}: CheckboxProps &
	UitkBaseProps &
	React.InputHTMLAttributes<HTMLInputElement> &
	FieldWrapperClone) => {
	const ariaId = id ? id : generate();
	const isSvgDisplayed = type === 'checkbox' && checked === true;
	const checkedValue = checked !== 'indeterminate' && checked;
	const labelValue = label ? label : children;
	const handleChange = (event) =>
		disabled || readOnly || !onChange
			? null
			: onChange(event, {
					checked: event.target.checked,
					value: value,
			  });
	return (
		<div
			className={classnames(
				{
					Checkbox: true,
					['Checkbox--isRadio']: type === 'radio',
					['Checkbox--isIndeterminate']: checked === 'indeterminate',
					['Checkbox--isChecked']: checked === 'indeterminate' || checked,
					['Checkbox--isUnchecked']: !checked,
					['Checkbox--isReadOnly']: readOnly,
					['Checkbox--isDisabled']: disabled,
				},
				className
			)}
		>
			<span className="Checkbox__positionedContext">
				<input
					id={ariaId}
					autoFocus={autoFocus}
					aria-invalid={isInvalid}
					aria-readonly={readOnly}
					aria-describedby={ariaDescribedby}
					aria-labelledby={ariaLabelledby}
					className="Checkbox__input"
					checked={checkedValue}
					disabled={disabled || readOnly}
					formNoValidate={formNoValidate}
					onChange={handleChange}
					ref={forwardedRef}
					type={type}
					value={value}
					required={required}
					{...restProps}
				/>
				<span className="Checkbox__inputDisplayed" />
				<span className="Checkbox__mark">
					{isSvgDisplayed && (
						<Svg
							aria-hidden
							symbol={check}
							width="50%"
							symbolProps={{
								height: '100%',
								overflow: 'visible',
								width: '100%',
								strokeDasharray: 40,
							}}
						>
							<animate
								begin="50ms"
								attributeName="stroke-dashoffset"
								from="40"
								to="0"
								dur="275ms"
							/>
						</Svg>
					)}
				</span>
			</span>
			{/* to avoid some false errors by accessibility tools in the case of providing label separately */}
			{labelValue && (
				<Label className="Checkbox__label" htmlFor={ariaId}>
					{labelValue}
				</Label>
			)}
		</div>
	);
};
Checkbox.defaultProps = {
	formNoValidate: true,
	type: 'checkbox',
};
Checkbox.isFormField = true;
/**
 * @component
 */
export default styled(passRef(Checkbox))`
	${componentStyle};
`;
