import React from 'react';

import Card from '../Card';

export type TabsContentProps = {
	/** You can pass in any react component */
	children?: any;
};
class TabsContent extends React.Component<TabsContentProps> {
	render() {
		return <Card>{this.props.children}</Card>;
	}
}

//@ts-ignore
TabsContent.tabsRole = 'tabsContent';

/**
 * component
 */
export default TabsContent;
