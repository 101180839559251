import React from 'react';
import classnames from 'classnames';
import styled from 'styled-components';

import LoadingIndicator from '../LoadingIndicator/LoadingIndicator';
import passRef from '../../hocs/passRef';
import { UitkBaseProps } from '../../typings';

import componentStyle from './Button.style';

export type ButtonProps = {
	formNoValidate?: boolean;
	isLoading?: boolean;
	variant?: 'primary' | 'secondary' | 'tertiary';
	colorTheme?: 'mlcOrange' | 'mlcAqua' | 'mlcRubine' | 'mlcWhite' | 'mlcText' | 'brandColor';
	hoverColor?: 'mlcOrange' | 'mlcAqua' | 'mlcRubine';
	width?: string;
};

/**
 * We recommend that you use the preset components
 * [PrimaryButton](#!/PrimaryButton), [SecondaryButton](#!/SecondaryButton), [TertiaryButton](#!/TertiaryButton) rather than using `Button` directly.

 * Groups of button components should be wrapped in a [ButtonGroup](#!/ButtonGroup) component to automatically deal with alignment,positioning and tab order.
 */
export const Button = ({
	children,
	className,
	colorTheme = 'brandColor',
	disabled,
	formNoValidate,
	forwardedRef,
	hoverColor = null,
	isLoading = false,
	variant = 'primary',
	onClick,
	...restProps
}: UitkBaseProps & ButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement>) => (
	<button
		onClick={isLoading ? null : onClick}
		{...restProps}
		className={classnames(
			{
				Button: true,
				'Button--isLoading': isLoading,
				[`Button--${variant}`]: variant,
				[`Button--${colorTheme}`]: variant,
				[`Button--${hoverColor}`]: hoverColor,
			},
			className
		)}
		disabled={disabled}
		formNoValidate={formNoValidate}
		ref={forwardedRef}
	>
		<span className="Button__wrapper">
			{isLoading && !disabled && (
				<span className="Button__spinner-wrapper">
					<LoadingIndicator isUserAction={true} message="Processing, please wait..." />
				</span>
			)}
			{children}
		</span>
	</button>
);

export default styled(passRef(Button))`
	${componentStyle};
`;
