import React from 'react';

import { UitkStatics, PassRefProps } from '../typings';

const passRef = <ComponentProps extends {}>(Component: React.ComponentType<ComponentProps>) => {
	const ComponentWithForwardingRef: React.ForwardRefExoticComponent<
		React.PropsWithoutRef<ComponentProps> & React.RefAttributes<any>
	> &
		PassRefProps &
		// we have to add Statics here because ts isn't aware we're injecting them after
		UitkStatics = React.forwardRef<any, ComponentProps>((props, ref) => (
		<Component forwardedRef={ref} {...props} />
	));

	// map static props onto forwarded component such as propTypes and isFormField
	Object.keys(Component).forEach((value) => {
		ComponentWithForwardingRef[value] = Component[value];
	});
	ComponentWithForwardingRef.displayName = `forwardRef(${Component.displayName ||
		Component.name})`;
	return ComponentWithForwardingRef;
};

export default passRef;
