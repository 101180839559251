/**
 * spinner
 * Don't modify this file. It is generated by scripts/build.js and is
 * ignored by git. The source svg file is located in the '../src' directory.
 */
import BrowserSpriteSymbol from 'svg-baker-runtime/browser-symbol';

const symbol = new BrowserSpriteSymbol({
  "id": "symbol-spinner-1770836399",
  "use": "symbol-spinner-1770836399-usage",
  "viewBox": "0 0 24 24",
  "content": "<symbol viewBox=\"0 0 24 24\" id=\"symbol-spinner-1770836399\"><g fill=\"currentColor\" fill-rule=\"evenodd\"><path fill-opacity=\".8\" d=\"M20.25 11.203h-.104l-2.659.006-.129.002a.793.793 0 0 0 .065 1.586h2.847a.8.8 0 0 0-.02-1.594z\" /><path fill-opacity=\".9\" d=\"M16.398 8.733l.002-.002 1.911-1.907.101-.103A.8.8 0 0 0 17.27 5.61l-.073.074-1.875 1.883-.09.09a.793.793 0 0 0 1.167 1.077z\" /><path d=\"M12 3.01a.8.8 0 0 0-.8.748l.001.105.008 2.655v.129a.794.794 0 0 0 1.588-.063V6.58l.001-2.697v-.144A.801.801 0 0 0 12 3.01z\" /><path fill-opacity=\".3\" d=\"M8.727 7.604L6.818 5.697l-.102-.103a.802.802 0 0 0-1.08.049c-.3.3-.312.78-.035 1.093l.074.075L7.56 8.682l.091.091a.797.797 0 0 0 1.077-.043.793.793 0 0 0 .001-1.123l-.002-.003z\" /><path fill-opacity=\".4\" d=\"M6.64 12.789a.794.794 0 0 0-.063-1.586H3.73a.799.799 0 0 0 .02 1.594h.105l2.658-.009h.128z\" /><path fill-opacity=\".5\" d=\"M7.6 15.266l-.001.003-1.91 1.908-.102.1a.8.8 0 0 0 1.143 1.115l.074-.075 1.875-1.883.09-.09a.794.794 0 0 0-1.168-1.078z\" /><path fill-opacity=\".6\" d=\"M12.79 17.354a.796.796 0 0 0-1.588.063v.003l-.001 2.698v.143a.802.802 0 0 0 1.597-.019v-.105l-.008-2.655v-.128z\" /><path fill-opacity=\".7\" d=\"M18.325 17.19l-1.886-1.872-.09-.09a.796.796 0 0 0-1.079 1.166l.003.003 1.908 1.908.103.1a.8.8 0 0 0 1.115-1.14l-.074-.075z\" /></g></symbol>"
});


symbol.name = "spinner";

export default symbol;
