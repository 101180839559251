import React from 'react';
import styled from 'styled-components';

import { isFireFox } from '../../utils/UserAgent';
import passRef from '../../hocs/passRef';

import { resetLegend } from './Legend.style';
// Firefox has a 9 year old bug around printing fieldsets so legend becomes a
// div @see https://bugzilla.mozilla.org/show_bug.cgi?id=471015
const legendElement = isFireFox() ? 'div' : 'legend';
export const Legend = ({ children, className, forwardedRef, ...restProps }) => {
	const StyledLegend = styled(legendElement)`
		${resetLegend};
	`;
	return (
		<StyledLegend className={className} ref={forwardedRef} {...restProps}>
			{children}
		</StyledLegend>
	);
};

export default passRef(Legend);
