import { css } from 'styled-components';
import { themeGet } from 'styled-system';

export default () => css`
	.Password__PasswordRule-title {
		border: thin solid ${themeGet('colors.mlcDove')};
		color: ${themeGet('colors.mlcBlack')};
		padding: ${themeGet('space.3')}px ${themeGet('space.2')}px ${themeGet('space.3')}px
			${themeGet('space.4')}px;
	}
	.PasswordRule {
		border-top: 0px;
		padding: ${themeGet('space.1')}px ${themeGet('space.2')}px ${themeGet('space.1')}px
			${themeGet('space.4')}px;
	}
	.Password__PasswordRule-notification {
		color: ${themeGet('colors.mlcText')};
		font-size: ${themeGet('fontSizes.disclaimer')}px;
		margin-top: ${themeGet('space.6')}px;
	}
`;
