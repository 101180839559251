import { css } from 'styled-components';
import { themeGet } from 'styled-system';

export default () => {
	return css`
		${themeGet('global.base')};
		display: flex;
		width: 100%;
		margin-bottom: ${themeGet('space.2')}px;

		@media screen and (min-width: ${themeGet('gridTheme.breakpoints.sm')}px) {
			width: auto;
		}

		&:not(:last-child) {
			margin-right: -1px;
		}

		&.ToggleButton__flexGrow {
			flex-grow: 1;
		}

		/* hide the browser's default input */
		.ToggleButton__input {
			/* IE needs this */
			opacity: 0;
			margin: 0;
			padding: 0;
			position: absolute;
			cursor: none;

			/* Safari needs this */
			width: 1px;
			height: 1px;
		}

		.ToggleButton__label {
			border: none;
			cursor: pointer;
			font-family: ${themeGet('fonts.fontFamily')};
			padding: 15px 20px;
			font-size: 14px;
			height: 44px;
			align-items: center;
			border: solid thin ${themeGet('colors.mlcWallaby')};
			transition: background-color 100ms ease-in-out, box-shadow 100ms ease-in-out,
				color 100ms ease-in-out;

			color: ${themeGet('colors.mlcVeryDarkGrey')};
			background-color: ${themeGet('colors.mlcWhite')};
			width: 100%;
			display: flex;
			justify-content: center;
			z-index: 0;

			@media screen and (min-width: ${themeGet('gridTheme.breakpoints.sm')}) {
				max-width: 100%;
				width: auto;
				display: inline-flex;
			}
		}

		.ToggleButton__label:hover,
		.ToggleButton__input:focus + .ToggleButton__label {
			outline: none;
			text-decoration-color: ${themeGet('colors.mlcWhite')};
			text-decoration: underline;
			border: solid thin ${themeGet('colors.mlcText')};
			z-index: 1;
		}

		&.ToggleButton--isChecked .ToggleButton__label {
			background-color: ${themeGet('colors.mlcVeryDarkGrey')};
			color: ${themeGet('colors.mlcWhite')};
			border: solid thin ${themeGet('colors.mlcText')};
			z-index: 1;
		}

		&.ToggleButton--isInvalid .ToggleButton__label {
			border: thin solid ${themeGet('colors.mlcRed')};
			color: ${themeGet('colors.mlcRed')};
		}
	`;
};
