import React, { HTMLAttributes } from 'react';
import { defaultTo } from 'lodash';
import ReactAutosuggest from 'react-autosuggest';
import styled from 'styled-components';

import Input from '../Input/Input';
import Text from '../Text/Text';
import passRef from '../../hocs/passRef';
import { UitkBaseProps } from '../../typings';

import AutosuggestStyle from './Autosuggest.style';

export type AutosuggestProps = {
	alwaysRenderSuggestions?: boolean;
	display?: string | object;
	focusInputOnSuggestionClick?: boolean;
	getSectionSuggestions?: (...args: any[]) => any;
	getSuggestionValue?: (...args: any[]) => any;
	formNoValidate?: boolean;
	highlightFirstSuggestion?: boolean;
	id?: string;
	inputProps?: object;
	required?: boolean;
	multiSection?: boolean;
	onSuggestionsClearRequested?: (...args: any[]) => any;
	onSuggestionsFetchRequested: (...args: any[]) => any;
	onSuggestionHighlighted?: (...args: any[]) => any;
	onSuggestionSelected?: (...args: any[]) => any;
	renderInputComponent?: (...args: any[]) => any;
	renderSectionTitle?: (...args: any[]) => any;
	renderSuggestion?: (...args: any[]) => any;
	renderSuggestionsContainer?: (...args: any[]) => any;
	shouldRenderSuggestions?: (...args: any[]) => any;
	suggestions?: any[];
	value?: string;
};

export const Autosuggest = ({
	alwaysRenderSuggestions,
	focusInputOnSuggestionClick,
	formNoValidate,
	forwardedRef,
	getSectionSuggestions,
	getSuggestionValue,
	highlightFirstSuggestion,
	id,
	inputProps,
	onSuggestionHighlighted,
	onSuggestionsClearRequested,
	onSuggestionSelected,
	onSuggestionsFetchRequested,
	renderInputComponent,
	renderSectionTitle,
	renderSuggestion,
	renderSuggestionsContainer,
	shouldRenderSuggestions,
	suggestions,
	value,
	className,
	required,
	...restProps
}: UitkBaseProps & AutosuggestProps & HTMLAttributes<HTMLInputElement>) => {
	const autoSuggestProps = {
		alwaysRenderSuggestions,
		focusInputOnSuggestionClick,
		getSectionSuggestions,
		getSuggestionValue,
		highlightFirstSuggestion,
		id: `suggest-${id}`,
		inputProps: {
			...restProps,
			formNoValidate,
			required: required,
			forwardedRef,
			id,
			value: defaultTo(value, ''),
			...inputProps,
		},
		onSuggestionHighlighted,
		onSuggestionsClearRequested,
		onSuggestionSelected,
		onSuggestionsFetchRequested,
		renderInputComponent,
		renderSectionTitle,
		renderSuggestion,
		renderSuggestionsContainer,
		shouldRenderSuggestions,
		suggestions,
	};
	const theme = {
		container: className,
		suggestionsContainer: 'suggestions-container',
		suggestionsList: 'suggestions-list',
		suggestion: 'suggestion',
		suggestionHighlighted: 'suggestion--highlighted',
	};
	return <ReactAutosuggest theme={theme} {...autoSuggestProps} className={className} />;
};

const InputComponent = ({ forwardedRef, ...restProps }) => (
	<Input {...restProps} ref={forwardedRef} />
);

const renderSuggestion = (suggestion) => <Text>{suggestion.text}</Text>;
Autosuggest.defaultProps = {
	formNoValidate: true,
	getSuggestionValue: (suggestion) => suggestion.text,
	renderInputComponent: InputComponent,
	renderSuggestion,
	suggestions: [],
	value: '',
};
Autosuggest.isFormField = true;

export default styled(passRef(Autosuggest))`
	${AutosuggestStyle}
`;
