import { themeGet } from 'styled-system';
import { css } from 'styled-components';
import { media } from 'styled-bootstrap-grid';

const progressBarHeight = 10;

export default ({ barBaseColor }) => css`
	${themeGet('global.base')}

	.ProgressBar__info {
		font-size: ${themeGet('fontSizes.tiny')}px;
		font-weight: ${themeGet('fontWeights.medium')};
	}

	.ProgressBar__step {
		margin-left: 20px;
		white-space: nowrap;
	}

	.ProgressBar__step-label {
		${media.xs`
			display: none;
		`}
	}

	progress.ProgressBar__bar {
		appearance: none;
		width: 100%;
		height: ${progressBarHeight}px;
		border: 0;
		border-radius: ${progressBarHeight}px;
		background-color: ${themeGet('colors.' + barBaseColor)};
	}

	progress.ProgressBar__bar::-webkit-progress-bar {
		background-color: ${themeGet('colors.' + barBaseColor)};
		border-radius: ${progressBarHeight}px;
	}

	progress.ProgressBar__bar::-webkit-progress-value {
		background-color: ${themeGet('colors.mlcWhite')};
		border-radius: ${progressBarHeight}px;
		transition: width 0.5s ease-in-out;
	}

	progress.ProgressBar__bar::-moz-progress-bar {
		background-color: ${themeGet('colors.mlcWhite')};
		border-radius: ${progressBarHeight}px;
		transition: width 0.5s ease-in-out;
	}

	progress.ProgressBar__bar::-ms-fill {
		border: none;
		background-color: ${themeGet('colors.mlcWhite')};
		border-radius: ${progressBarHeight}px;
	}
	/* https://stackoverflow.com/questions/16982449/why-isnt-it-possible-to-combine-vendor-specific-pseudo-elements-classes-into-on */
`;
