import React from 'react';

import ScreenReaderText from '../ScreenReaderText/ScreenReaderText';

type NewTabMsgProps = {
	text?: string;
};

const NewTabMsg = ({ text }: NewTabMsgProps) => (
	<ScreenReaderText>{text ? ' ' + text : null}, opens in a new window</ScreenReaderText>
);

export default NewTabMsg;
