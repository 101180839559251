/**
 * volumeMute
 * Don't modify this file. It is generated by scripts/build.js and is
 * ignored by git. The source svg file is located in the '../src' directory.
 */
import BrowserSpriteSymbol from 'svg-baker-runtime/browser-symbol';

const symbol = new BrowserSpriteSymbol({
  "id": "symbol-volumeMute-1294225184",
  "use": "symbol-volumeMute-1294225184-usage",
  "viewBox": "0 0 512 512",
  "content": "<symbol viewBox=\"0 0 512 512\" id=\"symbol-volumeMute-1294225184\"><path d=\"M231.81 64c-5.91 0-11.92 2.18-16.78 7.05L126.06 160H24c-13.26 0-24 10.74-24 24v144c0 13.25 10.74 24 24 24h102.06l88.97 88.95c4.87 4.87 10.88 7.05 16.78 7.05 12.33 0 24.19-9.52 24.19-24.02V88.02C256 73.51 244.13 64 231.81 64zM208 366.05l-48-47.99L145.94 304H48v-96h97.94L160 193.94l48-47.99v220.1zM465.94 256l41.37-41.37c6.25-6.25 6.25-16.38 0-22.63L496 180.69c-6.25-6.25-16.38-6.25-22.63 0L432 222.06l-41.37-41.37c-6.25-6.25-16.38-6.25-22.63 0L356.69 192c-6.25 6.25-6.25 16.38 0 22.63L398.06 256l-41.37 41.37c-6.25 6.25-6.25 16.38 0 22.63L368 331.32c6.25 6.25 16.38 6.25 22.63 0L432 289.94l41.37 41.37c6.25 6.25 16.38 6.25 22.63 0L507.31 320c6.25-6.25 6.25-16.38 0-22.63L465.94 256z\" /></symbol>"
});


symbol.name = "volumeMute";

export default symbol;
