import { css } from 'styled-components';
import { themeGet } from 'styled-system';

import { layout } from '../../utils/styleFunctions';

export default ({ color }) => {
	const backgroundColor = color ? themeGet('colors.' + color) : themeGet('colors.mlcMediumGrey');

	return css`
		${themeGet('global.base')}
		border-top: thin solid;
		color: ${backgroundColor};
		margin: 0 0 30px 0;
		width: 100%;

		${layout}
	`;
};
