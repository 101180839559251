import React from 'react';

import { isFireFox } from '../../../../utils/UserAgent';
import Label from '../../../Label';
import { UitkBaseProps } from '../../../../typings';

// Firefox has a 9 year old bug around printing fieldsets so legend becomes a
// div @see https://bugzilla.mozilla.org/show_bug.cgi?id=471015
const legendElement = isFireFox() ? 'div' : 'legend';

export const Legend = ({ children, className, forwardedRef, ...restProps }: UitkBaseProps) => {
	return (
		<Label el={legendElement} className={className} ref={forwardedRef} {...restProps}>
			{children}
		</Label>
	);
};

/**
 * @component
 */
export default Legend;
