import { Link } from 'react-router-dom';
import React from 'react';
import styled from 'styled-components';

import passRef from '../../../../hocs/passRef';
import { UitkBaseProps } from '../../../../typings';

import componentStyle from './DropdownItemContent.style';
type DropdownItemContentProps = {
	link?: string;
	role?: string;
	isAbsoluteLink?: boolean;
	to?: string;
	openInNewTab?: boolean;
	onClick?: (...args: any[]) => any;
};
export const DropdownItemContent = styled(
	passRef(
		({
			children,
			link,
			isAbsoluteLink,
			role = 'link',
			forwardedRef,
			openInNewTab,
			onClick,
			...restProps
		}: DropdownItemContentProps & UitkBaseProps) => {
			const linkChildren = React.Children.map(children, (x: any) =>
				x && x.type ? React.cloneElement(x, { ...x.props }) : x
			);
			const to = isAbsoluteLink === true ? null : link;
			const target = openInNewTab ? '_blank' : null;
			const Wrapper = to ? Link : 'a';
			return (
				<Wrapper
					{...restProps}
					href={link || '#'} //add href so link is tabbable to
					ref={forwardedRef}
					to={to}
					role={role}
					target={target}
					onClick={onClick}
					onKeyUp={(e: any) => {
						if (role === 'button' && e.keyCode === 32) {
							onClick(e);
						}
					}}
					onKeyDown={(e: any) => {
						if (role === 'button' && e.keyCode === 32) {
							e.preventDefault();
						}
					}}
				>
					{linkChildren}
				</Wrapper>
			);
		}
	)
)`
	${componentStyle};
`;
DropdownItemContent.defaultProps = {
	openInNewTab: false,
	role: 'link',
};
/** @component */
export default DropdownItemContent;
