import React, { HTMLAttributes } from 'react';

import { defaultFormat } from '../BareFormattedInput/BareFormattedInput';
import Input from '../Input';
import passRef from '../../hocs/passRef';
import { UitkBaseProps } from '../../typings';
export const format = {
	...defaultFormat,
	mask: [/\d/, /\d/, ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/],
};

export type ABNInputProps = {
	isInvalid?: boolean;
	variant?: 'boxed' | 'normal';
	onChange?: (...args: any[]) => any;
	readOnly?: boolean;
	value?: string;
	isLoading?: boolean;
};

export const ABNInput = ({
	forwardedRef,
	...restProps
}: UitkBaseProps & ABNInputProps & HTMLAttributes<HTMLInputElement>) => {
	return <Input ref={forwardedRef} {...restProps} format={format} />;
};
ABNInput.isFormField = true;

export default passRef(ABNInput);
