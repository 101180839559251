import React, { HTMLAttributes } from 'react';

import passRef from '../../hocs/passRef';
import { UitkBaseProps } from '../../typings';

type FormProps = {
	el?: string;
	noValidate?: boolean;
	/**
	 * When this value is set to true, form submission events will not be triggered.
	 *
	 * You may want to set this when the form is in an "isSubmitting" state.
	 */
	preventSubmission?: boolean;
};

export const Form = passRef(
	({
		children,
		forwardedRef,
		noValidate = true,
		preventSubmission,
		onSubmit,
		...restProps
	}: FormProps & UitkBaseProps & HTMLAttributes<HTMLFormElement>) => (
		<form
			{...restProps}
			noValidate={noValidate}
			ref={forwardedRef}
			onSubmit={
				preventSubmission
					? (event: any) => {
							event.preventDefault();
					  }
					: onSubmit
			}
		>
			<div>{children}</div>
		</form>
	)
);

export default Form;
