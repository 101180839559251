import { css } from 'styled-components';
import { themeGet } from 'styled-system';

const disclaimerVariantStyles = css`
	p,
	h1,
	h2,
	h3,
	h4,
	h5 {
		color: ${themeGet('colors.mlcVeryDarkGrey')};
		font-family: ${themeGet('fonts.fontFamily')};
		font-size: ${themeGet('fontSizes.disclaimer')}px;
		line-height: ${themeGet('lineHeights.disclaimer')};
		margin-top: 0;
	}

	h1,
	h2,
	h3,
	h4,
	h5 {
		font-weight: 700;
	}
`;

// shorthand for setting all of the needed styles for each heading level
const headerStyles = () => {
	const headers = ['h1', 'h2', 'h3', 'h4', 'h5'];
	return headers.map(
		(h) => css`
			${h} {
				${themeGet('global.headings')}
				${themeGet(`global.${h}`)}
			}
		`
	);
};

const editorialStyles = () => {
	const headers = ['h1', 'h2', 'h3', 'h4', 'h5'];
	return headers.map(
		(h) => css`
			${h} {
				margin-bottom: ${themeGet('space.3')}px;
			}
		`
	);
};

// uitk-like styles
const normalStyles = css`
	p {
		${themeGet('global.paragraph')}
	}
	${headerStyles}
`;

export default ({ variant, editorial }) => css`
	${themeGet('global.base')}
	${themeGet('global.list')}
	a {
		${themeGet('global.link')}
	}
	${variant === 'disclaimer' ? disclaimerVariantStyles : normalStyles}
	${editorial ? editorialStyles : null}
`;
