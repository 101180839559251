import { css } from 'styled-components';
import { themeGet } from 'styled-system';

export default () => css`
	${themeGet('global.base')}
	background-color: inherit;
	color: inherit;
	font-size: inherit;
	font-weight: inherit;
	text-align: inherit;
	text-transform: inherit;
`;
