/**
 * lockSolid
 * Don't modify this file. It is generated by scripts/build.js and is
 * ignored by git. The source svg file is located in the '../src' directory.
 */
import BrowserSpriteSymbol from 'svg-baker-runtime/browser-symbol';

const symbol = new BrowserSpriteSymbol({
  "id": "symbol-lockSolid-3834137534",
  "use": "symbol-lockSolid-3834137534-usage",
  "viewBox": "0 0 35 35",
  "content": "<symbol viewBox=\"0 0 35 35\" id=\"symbol-lockSolid-3834137534\"><g id=\"symbol-lockSolid-3834137534_Steppers_lock\" stroke=\"none\" stroke-width=\"1\" fill=\"none\" fill-rule=\"evenodd\"><path d=\"M27.429 13.25c.714 0 1.321.273 1.821.82.5.547.75 1.211.75 1.992v13.125c0 .782-.25 1.446-.75 1.993-.5.547-1.107.82-1.821.82H8.57c-.714 0-1.321-.273-1.821-.82-.5-.547-.75-1.211-.75-1.992V16.063c0-.782.25-1.446.75-1.993.5-.547 1.107-.82 1.821-.82h1.715v-2.813c0-1.523.348-2.93 1.044-4.218.697-1.29 1.634-2.315 2.813-3.076A6.966 6.966 0 0 1 18 2c1.393 0 2.679.38 3.857 1.143 1.179.761 2.116 1.796 2.813 3.105a8.903 8.903 0 0 1 1.044 4.248v2.754h1.715zm-14.286-3.104v2.666h10v-2.666c0-1.482-.486-2.74-1.458-3.778-.973-1.037-2.153-1.556-3.542-1.556-1.389 0-2.57.519-3.542 1.556s-1.458 2.296-1.458 3.778z\" id=\"symbol-lockSolid-3834137534_Icon\" fill=\"#161818\" /></g></symbol>"
});


symbol.name = "lockSolid";

export default symbol;
