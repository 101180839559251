import React, { useState } from 'react';
import classnames from 'classnames';
import styled from 'styled-components';

import { UitkBaseProps } from '../../typings';

import { Announcement } from './types';

/**
 * This component should not be used directly, it should be implemented via the ScreenReaderLiveRegionContextProvider
 */

type LiveRegionProps = {
	el?: any;
	/** The message to be read by the screenreader */
	message?: string;
};
export const liveRegionTestId = 'liveregion';
const LiveRegion = styled(
	({ className, el: El = 'span', message, ...restProps }: LiveRegionProps & UitkBaseProps) => (
		<El data-testid={liveRegionTestId} className={classnames('sr-only sr-announcement', className)} {...restProps}>
			{message ? message : ''}
		</El>
	)
)`
	overflow: hidden;
	clip: rect(1px, 1px, 1px, 1px);
	height: 1px;
	width: 1px;
	position: absolute;
`;

/**
 * Component to interface with the live region and manage its state
 */
type LiveRegionWrapperProps = {
	announcement: Announcement;
};
const LiveRegionWrapper = ({ announcement }: LiveRegionWrapperProps) => {
	const [storedMessage, storeMessage] = useState({});

	const { children, ...ariaConfig } = announcement;

	if (storedMessage !== children) {
		storeMessage(children);
	}

	return (
		<LiveRegion
			message={storedMessage}
			/**
			 *  Default to aria-live="polite"
			 *  If caller has set role="alert" we don't use their aria-live setting.
			 */
			aria-live={
				ariaConfig.role === 'alert'
					? 'assertive'
					: ariaConfig['aria-live']
					? ariaConfig['aria-live']
					: 'polite'
			}
			aria-atomic={ariaConfig['aria-atomic'] ? ariaConfig['aria-atomic'] : 'true'}
			role={ariaConfig.role}
		/>
	);
};

export default LiveRegionWrapper;
