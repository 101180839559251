import React from 'react';
import { Col as BootstrapCol, ColProps as BaseColProps } from 'styled-bootstrap-grid';

import { UitkBaseProps } from '../../typings';

type ColProps = {
	noGutter?: boolean;
};

export class Col extends React.Component<ColProps & UitkBaseProps & BaseColProps> {
	public static defaultProps = {
		auto: false,
	};

	constructor(props: ColProps & UitkBaseProps & BaseColProps) {
		super(props);
	}

	render() {
		const { children, className, forwardedRef, ...restProps } = this.props;
		return (
			<BootstrapCol className={className} ref={forwardedRef} {...restProps}>
				{children}
			</BootstrapCol>
		);
	}
}
export default Col;
