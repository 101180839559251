import { css } from 'styled-components';
import { themeGet } from 'styled-system';

export default (props) => {
	return css`
		${themeGet('global.base')};
		border-top: ${props.noLine ? 'none' : 'thin solid'};
		border-color: ${themeGet('colors.mlcMediumGrey')};
		font-weight: ${themeGet('fontWeights.medium')};
	`;
};
