import { css } from 'styled-components';
import { themeGet } from 'styled-system';

export default ({ symbolPosition, symbol }) => css`
	&& {
		/* create arbitrary specificty for aemCounter */

		box-sizing: border-box;
		background-color: ${themeGet('colors.mlcWhite')};
		border: thin solid ${themeGet('colors.mlcMediumGrey')};
		color: ${themeGet('colors.mlcVeryDarkGrey')};
		display: inline-block;
		font-family: ${themeGet('fonts.typography')};
		font-size: ${themeGet('fontSizes.input')}px;
		line-height: ${themeGet('lineHeights.input')};
		padding: 10px;
		margin-bottom: 10px;
		text-align: inherit;
		width: 100%;

		transition-timing-function: linear;
		transition-property: border-color, box-shadow;
		transition-duration: 100ms;
		animation-timing-function: linear;

		${symbol
			? symbolPosition === 'left'
				? 'padding-left: 30px;'
				: 'padding-right: 30px;'
			: null}
	}

	::placeholder {
		color: ${themeGet('colors.mlcMediumGrey')};
		font-style: normal;
	}

	&::-ms-clear,
	&::-ms-reveal {
		display: none;
	}

	&:focus,
	&:hover {
		outline-style: none;
		border: thin solid ${themeGet('colors.brandSecondaryLight')};
		box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset,
			0 0 4px ${themeGet('colors.brandSecondaryLight')};
	}

	&.Input--textarea {
		&.Input--isInvalid {
			border-color: ${themeGet('colors.mlcError')};
			:hover,
			:focus {
				box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset,
					0 0 4px ${themeGet('colors.mlcError')};
			}
		}
		overflow: auto;
		display: block;
	}

	&.Input--isInvalid:not(:focus):not(:hover) {
		border-color: ${themeGet('colors.mlcError')};
	}

	@media print {
		color: ${themeGet('colors.mlcVeryDarkGrey')};
		box-shadow: none;
		border: 2px solid ${themeGet('colors.mlcMediumGrey')};
	}
`;

export const positionedSymbolStyle = ({ symbolPosition, marginTop }) => css`
	${symbolPosition == 'left' ? 'left: 10px;' : 'right 10px;'}
	top: ${marginTop};
	position: absolute;
	right: 10px;
	top: ${marginTop};
	transform: translateY(-50%);
	z-index: 1;
	pointer-events: none;
	line-height: 0;
`;
