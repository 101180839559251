import { css } from 'styled-components';
import { themeGet } from 'styled-system';

import { typography, textLink } from '../../utils/styleFunctions';

export default (props) => {
	const visualStyle = props.visual ? props.visual : props.el || 'h1';

	return css`
		${themeGet('global.base')}
		${themeGet('global.headings')}
		${themeGet(`global.${visualStyle}`)}

		&.Heading--typography {
			${textLink}
			${typography}
		}
		
		&:focus {
			outline-style: none;
		}
	`;
};
