import React from 'react';
import styled from 'styled-components';

import { UitkBaseProps } from '../../typings';

import componentStyle from './Counter.style';
type CounterProps = {
	counterMax: number;
	counterCurrent: number;
};
/**
 * A small utility for rendering "Number of Number"
 */
export const Counter = styled(
	({
		counterMax,
		counterCurrent,
		className,
		...restProps
	}: CounterProps & UitkBaseProps & React.HTMLAttributes<HTMLSpanElement>) => {
		const count = counterMax - counterCurrent;
		return (
			<span className={className} {...restProps}>
				{count} of {counterMax} left
			</span>
		);
	}
)`
	${componentStyle};
`;
Counter.defaultProps = {
	counterMax: 2500,
};
/**
 * @component
 */
export default Counter;
