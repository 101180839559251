import { chevronDownSolid, chevronRightSolid, chevronUpSolid, user } from '@mlc/symbols';
import React from 'react';
import classnames from 'classnames';
import { truncate } from 'lodash';

import Svg from '../../../Svg';
import DropdownLink from '../DropdownLink/DropdownLink';
import NewTabMsg from '../../../NewTabMsg/NewTabMsg';
import { UitkBaseProps } from '../../../../typings';

import { DropdownsItem } from './Dropdowns.style';

type NavButtonItemProps = {
	menuType?: string;
	onClick?: (...args: any[]) => any;
	open?: boolean;
	navItemRole?: string;
	link?: string;
	isLinkActive?: string;
	menu?: {
		key?: string;
		pageurl?: string;
		subpages?: any[];
		title?: string;
		url?: string;
		isAbsoluteLink?: boolean;
		openInNewTab?: boolean;
	};
};

const NavButtonItem = ({
	children,
	menuType,
	menu,
	onClick,
	open,
	navItemRole,
	isLinkActive,
}: NavButtonItemProps & UitkBaseProps) => {
	return (
		<DropdownLink
			name={menu.key}
			onClick={onClick}
			aria-expanded={open}
			aria-controls={menu.key}
			role={navItemRole}
			link={menu.pageurl}
			isAbsoluteLink={menu.isAbsoluteLink}
			openInNewTab={menu.openInNewTab}
			className={classnames(menuType, 'is-ButtonStyle Dropdowns__link', isLinkActive)}
			data-uitk={`${navItemRole}-link-${menuType}`}
			// to solve safari e.relatedTarget returns null issue
			// https://stackoverflow.com/questions/42764494/blur-event-relatedtarget-returns-null
			tabIndex="0"
		>
			{children}
		</DropdownLink>
	);
};

type DropdownsProps = {
	activeItems?: any[];
	dropdownLevel?: number;
	highlightLast?: boolean;
	colorTheme?: 'adviser' | 'investor';
	navItemRole?: string;
	onClick?: (...args: any[]) => any;
	navigationType?: string;
	loginLink?: string;
	isDesktop?: boolean;
	menu?: {
		key?: string;
		pageurl?: string;
		subpages?: any[];
		title?: string;
		url?: string;
		isAbsoluteLink?: boolean;
		openInNewTab?: boolean;
	};
};

type Props = DropdownsProps & UitkBaseProps;
type State = {
	open: boolean;
};

export class Dropdowns extends React.Component<Props, State> {
	constructor(props) {
		super(props);
		this.state = {
			open: false,
		};
	}

	//handle nav close
	handleNavClose = () => {
		this.setState({
			open: false,
		});
	};

	//handle Escape key
	handleEscapeKeyDown = (e) => {
		if (e.key === 'Escape') {
			this.handleNavClose();
		}
	};

	// handle blur
	handleBlur = (e) => {
		const dropdownsContainFocus =
			e.target.closest('li.is-open') &&
			e.target.closest('li.is-open').contains(e.relatedTarget);
		//The purpose of using relatedTarget:
		//https://developer.mozilla.org/en-US/docs/Web/API/MouseEvent/relatedTarget

		// check if focus is still in the opened list
		if (dropdownsContainFocus === false) {
			this.handleNavClose();
		}
	};

	// handle click
	handleNavOnclick = (e) => {
		e.preventDefault();
		// to set focus on safai
		e.target.focus();

		if (this.state.open === true) {
			this.handleNavClose();
		} else {
			this.setState({
				open: true,
			});
		}
	};

	handleLinkOnClick = (e) => {
		e.target.blur();
		if (this.props.onClick) {
			this.props.onClick();
		}
	};

	componentDidMount() {
		document.addEventListener('keydown', this.handleEscapeKeyDown);
		window.addEventListener('resize', this.handleNavClose);
	}

	componentWillUnmount() {
		document.removeEventListener('keydown', this.handleEscapeKeyDown);
		window.removeEventListener('resize', this.handleNavClose);
		Array.prototype.forEach.call(document.querySelectorAll('nav'), (item) => {
			item.removeEventListener('focusout', this.handleBlur);
		});
	}

	componentDidUpdate() {
		//to handle mouse click outside the nav area:
		//https://stackoverflow.com/questions/32553158/detect-click-outside-react-component
		//fix links not clickable on safari
		Array.prototype.forEach.call(document.querySelectorAll('nav'), (item) => {
			this.props.isDesktop === true
				? item.addEventListener('focusout', this.handleBlur)
				: item.removeEventListener('focusout', this.handleBlur);
		});
	}

	render() {
		const {
			menu,
			activeItems,
			dropdownLevel,
			colorTheme,
			navigationType,
			highlightLast,
			loginLink,
			onClick,
		} = this.props;

		const { open } = this.state;
		const iconWidth = '12px';
		const updatedDropdownLevel = dropdownLevel + 1,
			menuType = updatedDropdownLevel === 1 ? 'is-main' : 'is-sub',
			loginIcon = <Svg className="Dropdowns__icon-login" symbol={user} />;

		const isLinkActive = activeItems && activeItems.indexOf(menu.key) !== -1 ? 'is-active' : '';

		const displayUserPanel = (menu) => {
			//resons to make text link to be role="button", in stead of html button
			//https://developer.mozilla.org/en-US/docs/Web/HTML/Element/button#Clicking_and_focus

			return menu ? (
				<NavButtonItem
					menuType={menuType}
					menu={menu}
					onClick={this.handleNavOnclick}
					open={open}
					navItemRole="user"
				>
					{loginIcon}
					<span
						className="Dropdowns__content"
						aria-label={`You are logged in as: ${menu.title}`}
					>
						{truncate(menu.title, {
							length: 35,
						})}
					</span>
					{/* Desktop nav icons */}
					<span className="Dropdowns__icon-wrapper--mobile">
						<span hidden={open}>
							<Svg
								className="Dropdowns__icon"
								symbol={chevronRightSolid}
								width={iconWidth}
							/>
						</span>
					</span>
					{/* Mobile nav icons */}
					<span className="Dropdowns__icon-wrapper--desktop">
						<span className="Dropdowns--arrow">
							<span hidden={open}>
								<Svg
									className="Dropdowns__icon"
									symbol={chevronDownSolid}
									width={iconWidth}
								/>
							</span>
							<span hidden={!open}>
								<Svg
									className="Dropdowns__icon"
									symbol={chevronUpSolid}
									width={iconWidth}
								/>
							</span>
						</span>
					</span>
				</NavButtonItem>
			) : (
				<DropdownLink
					className="is-ButtonStyle Dropdowns__link"
					link={loginLink}
					isAbsoluteLink={true}
				>
					{loginIcon} Login
				</DropdownLink>
			);
		};

		const displayNavigation = (menu) => {
			return menu.subpages.length > 0 ? (
				<NavButtonItem
					menuType={menuType}
					menu={menu}
					onClick={this.handleNavOnclick}
					open={open}
					navItemRole="navigation"
					isLinkActive={isLinkActive}
				>
					<span className="Dropdowns__content">{menu.title}</span>
					{/* Desktop nav icons */}
					<span className="Dropdowns__icon-wrapper--desktop">
						{/* Desktop main nav icons */}
						<span hidden={menuType === 'is-sub'}>
							<span hidden={open}>
								<Svg
									className="Dropdowns__icon"
									symbol={chevronDownSolid}
									width={iconWidth}
								/>
							</span>
							<span hidden={!open}>
								<Svg
									className="Dropdowns__icon"
									symbol={chevronUpSolid}
									width={iconWidth}
								/>
							</span>
						</span>

						{/* Desktop sub nav icons */}
						<span hidden={menuType === 'is-main'}>
							<span hidden={open}>
								<Svg
									className="Dropdowns__icon"
									symbol={chevronRightSolid}
									width={iconWidth}
								/>
							</span>
							<span hidden={!open}>
								<Svg
									className="Dropdowns__icon"
									symbol={chevronDownSolid}
									width={iconWidth}
								/>
							</span>
						</span>
					</span>

					{/* Mobile main nav icons */}
					<span className="Dropdowns__icon-wrapper--mobile">
						<span hidden={open}>
							<Svg
								className="Dropdowns__icon"
								symbol={chevronRightSolid}
								width={iconWidth}
							/>
						</span>
					</span>
				</NavButtonItem>
			) : (
				<DropdownLink
					className={classnames(menuType, ' Dropdowns__link', isLinkActive)}
					data-uitk={`link-${menuType}`}
					link={menu.pageurl}
					onClick={this.handleLinkOnClick}
					name={menu.key}
					isAbsoluteLink={menu.isAbsoluteLink}
					openInNewTab={menu.openInNewTab}
				>
					<span className="Dropdowns__content">{menu.title}</span>
					{menu.openInNewTab && <NewTabMsg />}
				</DropdownLink>
			);
		};

		return (
			<DropdownsItem
				data-uitk={'Dropdowns'}
				className={classnames(
					open === true ? 'is-open' : '',
					menuType,
					navigationType,
					'Dropdowns__item'
				)}
				colorTheme={colorTheme}
				highlightLast={highlightLast}
			>
				{navigationType === 'user' ? displayUserPanel(menu) : displayNavigation(menu)}

				{menu && menu.subpages.length > 0 && (
					<ul className={classnames(menuType, 'Navigation__sublist')} id={menu.key}>
						{menu.subpages.map((menu) => (
							<Dropdowns
								key={menu.key}
								menu={menu}
								dropdownLevel={updatedDropdownLevel}
								colorTheme={colorTheme}
								onClick={onClick}
								activeItems={activeItems}
							/>
						))}
					</ul>
				)}
			</DropdownsItem>
		);
	}

	static defaultProps = {
		dropdownLevel: 0,
	};
}

export default Dropdowns;
