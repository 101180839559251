import React, { HTMLAttributes } from 'react';
import withFocus from '@mlc/with-focus';
import styled from 'styled-components';
import classnames from 'classnames';

import { ModalContext } from '../Modal/Modal';
import { UitkBaseProps } from '../../typings';

import InputWrapperStyle from './InputWrapper.style';

type InputWrapperProps = {
	isInvalid?: boolean;
	size?: 'large' | 'normal';
	disabled?: boolean;
	readOnly?: boolean;
	isFocused?: boolean;
	variant?: boolean;
};
/**
 * InputWrapper should not be used directly. It is used internally to wrap
 * shared markup and styling around Input and Select
 */
export const InputWrapper = ({
	children,
	className,
	disabled,
	isFocused,
	isInvalid,
	readOnly,
	size = 'normal',
	variant,
	...restProps
}: InputWrapperProps & UitkBaseProps & HTMLAttributes<HTMLSpanElement>) => {
	if (disabled && (isInvalid || readOnly)) {
		// eslint-disable-next-line no-console
		console.warn(
			`If setting the "disabled" prop, don’t also set "${
				isInvalid ? 'isInvalid' : 'readOnly'
			}"`
		);
	}
	return (
		<ModalContext.Consumer>
			{(isModal) => (
				<span
					{...restProps}
					className={classnames(
						'InputWrapper',
						`InputWrapper--${
							variant ? variant : isModal ? 'labelUnderline' : 'normal'
						}Variant`,
						`InputWrapper--${size}Size`,
						isInvalid && 'InputWrapper--isInvalid',
						readOnly && 'InputWrapper--isReadonly',
						disabled && 'InputWrapper--isDisabled',
						isFocused && 'InputWrapper--isFocused',
						className
					)}
				>
					{children}
				</span>
			)}
		</ModalContext.Consumer>
	);
};

export default withFocus(
	styled(InputWrapper)`
		${InputWrapperStyle};
	`
);
