import { transparentize } from 'polished';
import { css } from 'styled-components';
import { themeGet } from 'styled-system';

export default () => {
	return css`
		${themeGet('global.base')}
		.__textHighlighter {
			background-color: ${() => transparentize(0.7, 'yellow')};
			color: inherit;
		}

		.__active {
			font-weight: 700;
		}
	`;
};
