import { themeGet, width, textAlign } from 'styled-system';
import { css } from 'styled-components';

const styles = css`
	${themeGet('global.base')}
	border: thin solid transparent;
	cursor: pointer;

	/* buttonLabel variant for label */
	display: inline-block;
	font-family: ${themeGet('fonts.buttons')};
	font-size: ${themeGet('fontSizes.button')}px;
	font-weight: ${themeGet('fontWeights.semibold')};
	line-height: ${themeGet('lineHeights.button')};

	max-width: 100%;
	padding: 0 20px;
	transition: background-color 100ms ease-in-out, box-shadow 100ms ease-in-out,
		color 100ms ease-in-out;

	height: 44px;

	/* wrapper styles */
	.Button__wrapper,
	.Button__spinner-wrapper {
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.Button__wrapper {
		position: relative;

		@media print {
			display: inline;
		}
	}

	/* VARIANTS */
	/* shared variant styles */
	&.Button--primary,
	&.Button--secondary {
		:hover,
		:focus,
		:active {
			outline: none;
			text-decoration: underline;
		}
		.Button--isLoading {
			text-decoration: none;
			cursor: inherit;
		}
	}

	&.Button--tertiary {
		text-decoration: underline;

		:hover,
		:focus,
		:active {
			text-decoration: none;
		}

		.Button--isLoading {
			text-decoration: none;
			cursor: inherit;
		}
	}

	/* primary variant */
	&.Button--primary {
		.Button--isLoading {
			color: ${themeGet('colors.mlcWhite')};
			background-color: ${themeGet('colors.brandPrimaryLight')};
			border: none;
		}

		&.Button--brandColor {
			color: ${themeGet('colors.mlcWhite')};
			background-color: ${themeGet('colors.brandPrimaryLight')};
			:hover,
			:focus,
			:active {
				background-color: ${themeGet('colors.brandPrimaryDark')};
				text-decoration-color: ${themeGet('colors.mlcWhite')};
			}
		}

		/* primary variant colors */
		&.Button--mlcOrange {
			color: ${themeGet('colors.mlcWhite')};
			background-color: ${themeGet('colors.mlcOrange')};
			:hover,
			:focus,
			:active {
				background-color: ${themeGet('colors.mlcDeepOrange')};
				text-decoration-color: ${themeGet('colors.mlcWhite')};
			}
		}
		&.Button--mlcAqua {
			color: ${themeGet('colors.mlcWhite')};
			background-color: ${themeGet('colors.mlcAqua')};
			:hover,
			:focus,
			:active {
				background-color: ${themeGet('colors.mlcDeepAqua')};
				text-decoration-color: ${themeGet('colors.mlcWhite')};
			}
		}
		&.Button--mlcRubine {
			color: ${themeGet('colors.mlcWhite')};
			background-color: ${themeGet('colors.mlcRubine')};
			:hover,
			:focus,
			:active {
				background-color: ${themeGet('colors.mlcDeepRubine')};
				text-decoration-color: ${themeGet('colors.mlcWhite')};
			}
		}

		@media print {
			&.Button--mlcOrange,
			&.Button--mlcAqua,
			&.Button--brandColor,
			&.Button--mlcRubine {
				color: ${themeGet('colors.mlcVeryDarkGrey')};
				background-color: transparent;
				border: 2px solid ${themeGet('colors.mlcText')};
			}
		}
	}

	/* secondary variant */
	&.Button--secondary {
		background-color: transparent;

		.Button--isLoading {
			color: ${themeGet('colors.brandPrimaryLight')};
			background-color: ${themeGet('colors.mlcWhite')};
			border: solid medium ${themeGet('colors.brandPrimaryLight')};
		}

		&.Button--mlcOrange,
		&.Button--mlcAqua,
		&.Button--brandColor,
		&.Button--mlcRubine {
			:hover,
			:focus,
			:active {
				color: ${themeGet('colors.mlcWhite')};
				text-decoration-color: ${themeGet('colors.mlcWhite')};
			}
		}

		/* secondary variant colors */

		&.Button--brandColor {
			color: ${themeGet('colors.brandPrimaryLight')};
			border: solid medium ${themeGet('colors.brandPrimaryLight')};
			:hover,
			:focus,
			:active {
				background-color: ${themeGet('colors.brandPrimaryLight')};
				border: solid medium ${themeGet('colors.brandPrimaryLight')};
			}
		}
		&.Button--mlcOrange {
			color: ${themeGet('colors.mlcOrange')};
			border: solid medium ${themeGet('colors.mlcOrange')};
			:hover,
			:focus,
			:active {
				background-color: ${themeGet('colors.mlcOrange')};
				border: solid medium ${themeGet('colors.mlcOrange')};
			}
		}
		&.Button--mlcAqua {
			color: ${themeGet('colors.mlcAqua')};
			border: solid medium ${themeGet('colors.mlcAqua')};
			:hover,
			:focus,
			:active {
				background-color: ${themeGet('colors.mlcAqua')};
				border: solid medium ${themeGet('colors.mlcAqua')};
			}
		}
		&.Button--mlcRubine {
			color: ${themeGet('colors.mlcRubine')};
			border: solid medium ${themeGet('colors.mlcRubine')};
			:hover,
			:focus,
			:active {
				background-color: ${themeGet('colors.mlcRubine')};

				border: solid medium ${themeGet('colors.mlcRubine')};
			}
		}

		&.Button--mlcWhite {
			color: ${themeGet('colors.mlcWhite')};
			border: solid medium ${themeGet('colors.mlcWhite')};
			:hover,
			:focus,
			:active {
				color: ${themeGet('colors.mlcBlack')};
				background-color: ${themeGet('colors.mlcWhite')};
				border: solid medium ${themeGet('colors.mlcWhite')};
				/* Variable hover colors depending on background */
				&.Button--mlcOrange {
					color: ${themeGet('colors.mlcOrange')};
				}
				&.Button--mlcRubine {
					color: ${themeGet('colors.mlcRubine')};
				}
				&.Button--mlcAqua {
					color: ${themeGet('colors.brandSecondaryLight')};
				}
			}
		}

		@media print {
			&.Button--mlcOrange,
			&.Button--mlcAqua,
			&.Button--mlcRubine,
			&.Button--brandColor,
			&.Button--mlcWhite {
				color: ${themeGet('colors.mlcVeryDarkGrey')};
				background-color: transparent;
				border: 2px solid ${themeGet('colors.mlcText')};
			}
		}
	}

	/* tertiary variant */
	&.Button--tertiary {
		background-color: transparent;
		border: none;

		/* tertiary variant colors */
		&.Button--brandColor {
			color: ${themeGet('colors.brandPrimaryLight')};
			:hover,
			:focus,
			:active {
				color: ${themeGet('colors.brandPrimaryDark')};
				text-decoration-color: ${themeGet('colors.brandPrimaryDark')};
			}
			:focus {
				outline: 1px solid ${themeGet('colors.brandPrimaryDark')};
			}
		}
		&.Button--mlcOrange {
			color: ${themeGet('colors.mlcOrange')};
			:hover,
			:focus,
			:active {
				color: ${themeGet('colors.mlcDeepOrange')};
				text-decoration-color: ${themeGet('colors.mlcDeepOrange')};
			}
			:focus {
				outline: 1px solid ${themeGet('colors.mlcDeepOrange')};
			}
		}
		&.Button--mlcAqua {
			color: ${themeGet('colors.mlcAqua')};
			:hover,
			:focus,
			:active {
				color: ${themeGet('colors.mlcDeepAqua')};
				text-decoration-color: ${themeGet('colors.mlcDeepAqua')};
			}
			:focus {
				outline: 1px solid ${themeGet('colors.mlcDeepAqua')};
			}
		}
		&.Button--mlcRubine {
			color: ${themeGet('colors.mlcRubine')};
			:hover,
			:focus,
			:active {
				color: ${themeGet('colors.mlcDeepRubine')};
				text-decoration-color: ${themeGet('colors.mlcDeepRubine')};
			}
			:focus {
				outline: 1px solid ${themeGet('colors.mlcDeepRubine')};
			}
		}
		&.Button--mlcWhite {
			color: ${themeGet('colors.mlcWhite')};
			:hover,
			:focus,
			:active {
				color: ${themeGet('colors.mlcWhite')};
				text-decoration-color: ${themeGet('colors.mlcWhite')};
			}
			:focus {
				outline: 1px solid ${themeGet('colors.mlcWhite')};
			}
		}
		&.Button--mlcText {
			color: ${themeGet('colors.mlcVeryDarkGrey')};
			:hover,
			:focus,
			:active {
				color: ${themeGet('colors.mlcVeryDarkGrey')};
				text-decoration-color: ${themeGet('colors.mlcVeryDarkGrey')};
			}
			:focus {
				outline: 1px solid ${themeGet('colors.mlcText')};
			}
		}

		@media print {
			&.Button--mlcOrange,
			&.Button--mlcAqua,
			&.Button--mlcRubine,
			&.Button--brandColor,
			&.Button--mlcText,
			&.Button--mlcWhite {
				color: ${themeGet('colors.mlcVeryDarkGrey')};
				background-color: transparent;
				border: 2px solid ${themeGet('colors.mlcText')};
				text-decoration: none;
			}
		}
	}

	@media print {
		width: auto !important;
		&.Button--large,
		&.Button--medium,
		&.Button--small {
		}
	}

	@media screen and (-ms-high-contrast: active) {
		&.Button--primary,
		&.Button--secondary,
		&.Button-tertiary {
			text-decoration: underline;
			border-width: 1px;
			border-style: solid;
		}
	}

	${width}
	${textAlign}
`;

export default styles;
