import palette from '@mlc/mlc-palette';
import { mlcLogo } from '@mlc/symbols';

// sections marked deprecated are from < version 10.0.0 which are should not cause breaking changes, but are no longer being supported //

const themeColors = {
	// -- deprecated -- //
	brandPrimaryLight: palette.mlcOrange,
	brandPrimaryDark: palette.mlcDeepOrange,
	brandSecondaryLight: palette.mlcAqua,
	brandSecondaryDark: palette.mlcDeepAqua,
	textColor: palette.mlcText,
	primary: palette.mlcBlack,
	background: palette.mlcWhite,
	bg: palette.mlcWhite,
	errorColor: palette.mlcRed,
	readOnlyColor: palette.mlcGreen,
	hoverColor: palette.mlcAqua,
	focusColor: palette.mlcAqua,
	disabledColor: '#49fb35',
	dontUse: '#49fb35',
	...palette,
};

export const core = {
	name: 'mlc',

	/* Typography */
	fonts: {
		default: '"Helvetica Neue", Helvetica, Arial, sans-serif',
		headings: 'Charlie-Std, Helvetica, Arial, sans-serif',
		buttons: 'Charlie-Std, Helvetica, Arial, sans-serif',

		// -- deprecated -- //
		fontFamily: '"Helvetica Neue LT Std Roman 55", Helvetica, Arial, sans-serif',
	},
	fontSizes: {
		default: 16,
		inherit: 'inherit',

		/* label */
		label: 16,
		caption: 15,
		button: 18.75,

		/* paragraph */
		disclaimer: 14,
		standard: 16,
		lead: 20,

		/* headings */
		miniscule: 18,
		tiny: 20,
		little: 22,
		regular: 26,
		big: 30,
		huge: 34,
		hero: 44,

		// -- deprecated -- //
		xsmall: 14,
		small: 16,
		medium: 18, // h5
		input: 16,
		paragraph: 16, // body copy
		semilarge: 18, // h4
		large: 20, // h3
		xlarge: 26, // h2
		xxlarge: 34, // h1
		xhero: 72,
		xxhero: 90,
	},
	lineHeights: {
		default: 1.5,
		inherit: 'inherit',

		/* label */
		label: 1.5,
		button: 1,
		caption: 1.5,

		/* paragraph */
		disclaimer: 1.5,
		standard: 1.5,
		lead: 1.75,

		/* headings */
		miniscule: 20 / 18,
		tiny: 23 / 20,
		little: 25 / 22,
		regular: 30 / 26,
		big: 35 / 30,
		huge: 40 / 34,
		hero: 52 / 44,

		// -- deprecated -- //
		xsmall: 20 / 14,
		small: 22 / 16,
		medium: 22 / 18, // h5
		input: 22 / 16,
		paragraph: 22 / 16, // body copy
		semilarge: 24 / 18, // h4
		large: 26 / 20, // h3
		xlarge: 32 / 26, // h2
		xxlarge: 40 / 34, // h1
		xhero: 80 / 72,
		xxhero: 100 / 90,
	},
	fontWeights: {
		inherit: 'inherit',
		thin: 100,
		light: 300,
		regular: 400,
		medium: 500,
		semibold: 700,
		bold: 900,

		// -- deprecated -- //
		black: 900,
	},

	/* Layout */
	space: [0, 5, 10, 15, 20, 25, 30, 40, 50, 60, 70],

	/* Decoration */
	borders: {
		mlcBorderThick: 'medium solid',
		mlcBorderThin: 'thin solid',
	},
	shadows: {
		/* TODO no random colors */
		/* Elevated */
		mlcShadowStrong: '2px 2px 10px 4px rgba(0,0,0,0.1)',
		mlcShadowWeak: '1px 1px 4px 0px rgba(0,0,0,0.1)',

		/* Glowing */
		mlcGlowError: '0 0 6px 2px rgba(153,0,0,0.2)',
		mlcGlowAlert: '0 0 6px 2px rgba(255,155,55,0.2)',
		mlcGlowSuccess: '0 0 6px 2px rgba(44,133,60,0.2)',
		mlcGlowLink: '0 0 6px 2px rgba(3,118,145,0.2)',
		mlcPrimarySelected: '0 0 6px 2px rgba(201,81,9,0.7)',
		mlcSecondarySelected: '0 0 6px 2px rgba(1,131,140,0.7)',
	},
	transitions: {
		mlcTranSlowest: 'all .45s ease-in-out',
		mlcTranSlower: 'all .35s ease-in-out',
		mlcTranSlow: 'all .25s ease-in-out',
		mlcTranQuick: 'all .15s ease-in-out',
		mlcTranQuicker: 'all .10s ease-in-out',
		mlcTranQuickest: 'all .05s ease-in-out',
	},

	/* gridTheme is used by styled-bootstrap-grid API and is not expandable */
	gridTheme: {
		breakpoints: {
			xl: 1200,
			lg: 992,
			md: 768,
			sm: 576,
			xs: 0,
		},
		row: {
			padding: 10, // Sets negative left and right margin
		},
		col: {
			padding: 10, // Sets the left and right padding
		},
		container: {
			padding: 10,
			maxWidth: {
				xl: 1010,
				lg: 960,
				md: 740,
				sm: 740,
				xs: 'auto',
			},
		},
	},
};

export const bundledStyles = (inputCore = core) => {
	return {
		global: {
			base: `
			&,
			*,
			*::before,
			*::after {
				-webkit-box-sizing: border-box;
				box-sizing: border-box;
			}
			position: relative;
			color: ${palette.mlcVeryDarkGrey};
			font-family: ${inputCore.fonts.default};
			font-size: ${inputCore.fontSizes.default}px;
			font-weight: ${inputCore.fontWeights.regular};
			line-height: ${inputCore.lineHeights.default};
			-webkit-font-smoothing: antialiased;
			-moz-osx-font-smoothing: grayscale;
				
			button > * {
                pointer-events: none;
			}
			
            a > * {
                pointer-events: none;
            }
            
			@media print {
				color: ${palette.mlcVeryDarkGrey};
			}
		`,
			link: (props) => `
			color: ${palette.mlcTextLink};
			text-decoration: underline;
			cursor: pointer;
			&:hover,
			&:focus {
				color: ${palette.mlcDeepAqua};
				text-decoration: none;
			}
			
			&:focus {
				/* To overwrite AEM link focus style */
				outline: 1px dotted ${palette.mlcVeryDarkGrey} !important;
			}

			&[href^='tel:'],
			&[href^='sms:'] {
				@media screen and (min-width: ${inputCore.gridTheme.breakpoints.md}px) {
					color: ${palette.mlcVeryDarkGrey};
					pointer-events: none;
					&:hover, &:focus {
						color: ${palette.mlcVeryDarkGrey};
						text-decoration: none;
					}
				}
			}

			@media print {
				&::after {
					content: ${props.href ? ' (+' + props.href + ')' : ''};
					font-size: 0.875em;
					text-decoration: none;
				}
				/* Some links don't make sense to display with generated content */
				&[href^='#']::after,
				&[href^='mailto:']::after,
				&[href^='tel:']::after,
				&[href^='javascript:']::after {
					content: '';
				}
				/* This used to target a .text class by name before the refactor to styled-components */
				& > span {
					text-decoration: underline;
				}
			}
  		`,
			textLink: `
			color: ${palette.mlcTextLink};
			text-decoration: inherit;
		`,
			list: `	
			ul,
			ol {
				margin: 0 0 ${inputCore.space[2]}px 0;
				padding-left: ${inputCore.space[5]}px;
				ul, ol {
					margin-top: ${inputCore.space[1]}px;
				}
			}
			li {
				margin: 0 0 ${inputCore.space[1]}px 0;
			}
		`,
			hr: `
			hr {
				border: none;
				margin: ${inputCore.space[8]}px 0;
				height: 1px;
				background-color: ${palette.mlcMediumGrey};

				@media print {
					height: 0;
					border-top: ${inputCore.borders.mlcBorderThin} ${palette.mlcBlack};
				}
			}
		`,
			paragraph: `
			font-size: ${inputCore.fontSizes.standard}px;
			line-height: ${inputCore.lineHeights.standard};
		    margin-bottom: ${inputCore.space[4]}px;
    		margin-top: 0;
		`,
			headings: `
			font-family: ${inputCore.fonts.headings};
			font-weight: ${inputCore.fontWeights.semibold};
			margin-top: ${inputCore.space[1]}px;
			margin-bottom: ${inputCore.space[1]}px;
		`,
			h1: `
			font-size: ${inputCore.fontSizes.big}px;
			line-height: ${inputCore.lineHeights.big};
			@media screen and (min-width: ${inputCore.gridTheme.breakpoints.md}px) {
				font-size: ${inputCore.fontSizes.huge}px;
				line-height: ${inputCore.lineHeights.huge};
			}
		`,
			h2: `
			font-size: ${inputCore.fontSizes.regular}px;
			line-height: ${inputCore.lineHeights.regular};
			@media screen and (min-width: ${inputCore.gridTheme.breakpoints.md}px) {
				font-size: ${inputCore.fontSizes.big}px;
				line-height: ${inputCore.lineHeights.big};
			}
		`,
			h3: `
			font-size: ${inputCore.fontSizes.little}px;
			line-height: ${inputCore.lineHeights.little};
			@media screen and (min-width: ${inputCore.gridTheme.breakpoints.md}px) {
				font-size: ${inputCore.fontSizes.regular}px;
				line-height: ${inputCore.lineHeights.regular};
			}
		`,
			h4: `
			font-size: ${inputCore.fontSizes.tiny}px;
			line-height: ${inputCore.lineHeights.tiny};
			@media screen and (min-width: ${inputCore.gridTheme.breakpoints.md}px) {
				font-size: ${inputCore.fontSizes.little}px;
				line-height: ${inputCore.lineHeights.little};
			}
		`,
			h5: `
			font-size: ${inputCore.fontSizes.miniscule}px;
			line-height: ${inputCore.lineHeights.miniscule};
			@media screen and (min-width: ${inputCore.gridTheme.breakpoints.md}px) {
				font-size: ${inputCore.fontSizes.tiny}px;
				line-height: ${inputCore.lineHeights.tiny};
			}
		`,
		},
	};
};

// composeTheme :: (Object, Object, Function) -> Object
export const composeTheme = (colors, base, global) => {
	const bp = base.gridTheme.breakpoints;
	const breakpoints = [bp.xs, bp.sm, bp.md, bp.lg, bp.xl];
	breakpoints.xs = bp.xs;
	breakpoints.sm = bp.md;
	breakpoints.md = bp.md;
	breakpoints.lg = bp.lg;
	breakpoints.xl = bp.xl;

	return {
		...base,
		breakpoints: breakpoints,
		...global,
		colors: {
			...colors,
		},
		logo: mlcLogo,
	};
};

export default composeTheme(themeColors, core, bundledStyles());
