import styled, { css } from 'styled-components';
import { themeGet } from 'styled-system';

const buttonSpace = 15;
const DropdownsTheme = ({ colorTheme, highlightLast }) => {
	const color = colorTheme === 'adviser' ? 'mlcAqua' : 'brandPrimaryLight';

	let highlightLastBg = '',
		highlightLastText = '',
		highlightLastTextHover = '',
		highlightLastMarginLeft = 0;

	if (highlightLast === true) {
		highlightLastBg = 'mlcBlack';
		highlightLastText = 'mlcBlack';
		highlightLastTextHover = 'mlcWhite';
		highlightLastMarginLeft = buttonSpace;
	}

	return css`
		/* navigation last item is highlighted */
		.nav-lastItem & {
			&.is-main:last-child {
				@media (min-width: ${themeGet('gridTheme.breakpoints.lg')}px) {
					margin-right: ${highlightLastMarginLeft}px;
				}

				.is-open .Navigation__sublist.is-main {
					@media (min-width: ${themeGet('gridTheme.breakpoints.lg')}px) {
						right: 0;
					}
				}

				.Dropdowns__link.is-main {
					border: 2px solid ${themeGet(`colors.${highlightLastBg}`)};
					color: ${themeGet(`colors.${highlightLastText}`)};

					&:hover,
					&:focus {
						color: ${themeGet(`colors.${color}`)};
					}
				}
			}

			&.is-open.is-main:last-child .Dropdowns__link.is-main {
				background-color: ${themeGet(`colors.${highlightLastBg}`)};
				color: ${themeGet(`colors.${highlightLastTextHover}`)};
			}
		}

		/* navigation item is open */
		&.is-open {
			.Dropdowns__link.is-main {
				color: ${themeGet(`colors.${color}`)};

				&:hover,
				&:active,
				&:focus {
					color: ${themeGet(`colors.${color}`)};
				}
			}
		}

		/* set the dropdown item color theme */
		.Dropdowns__link {
			&:hover,
			&:active,
			&:focus {
				color: ${themeGet(`colors.${color}`)};
			}

			&:focus {
				outline: 1px solid transparent;
			}

			&:after {
				background-color: ${themeGet(`colors.${color}`)};
			}

			&.is-sub {
				&.is-active {
					&:after {
						@media (min-width: ${themeGet('gridTheme.breakpoints.lg')}px) {
							background-color: ${themeGet(`colors.${color}`)};
						}
					}
				}
			}

			/* navigation item is actived */
			&.is-active {
				color: ${themeGet(`colors.${color}`)};
			}
		}

		&.user {
			@media (min-width: ${themeGet('gridTheme.breakpoints.lg')}px) {
				&:after {
					background-color: ${themeGet(`colors.${color}`)};
				}
			}
			.Dropdowns__link.is-ButtonStyle {
				&.is-main {
					border: 0;
				}

				&:after {
					background-color: ${themeGet(`colors.${color}`)};
				}

				@media (max-width: ${themeGet('gridTheme.breakpoints.lg')}px) {
					color: ${themeGet(`colors.${color}`)};
				}
			}
		}

		&.navigation_toplink {
			@media (min-width: ${themeGet('gridTheme.breakpoints.lg')}px) {
				display: none;
			}
		}
	`;
};

export const DropdownsItem = styled.li`
	${DropdownsTheme}
	list-style: none;

	&.user {
		.Dropdowns__link.is-ButtonStyle {
			font-family: ${themeGet('fonts.default')};
			font-size: ${themeGet('fontSizes.standard')}px;
			font-weight: ${themeGet('fontWeights.medium')};
		}
		@media (min-width: ${themeGet('gridTheme.breakpoints.lg')}px) {
			&:after {
				content: '';
				display: block;
				bottom: 0;
				left: ${buttonSpace}px;
				right: ${buttonSpace}px;
				height: 4px;
				position: absolute;
			}
		}
		@media (max-width: ${themeGet('gridTheme.breakpoints.lg')}px) {
			.Dropdowns__link.is-ButtonStyle {
				background-color: ${themeGet('colors.mlcAlmostWhite')};

				&.is-open {
					position: relative;
					border-bottom: 0;
				}
			}
		}
	}

	/* this is for desktop dropdown list only */
	@media (min-width: ${themeGet('gridTheme.breakpoints.lg')}px) {
		position: relative;

		&.is-main {
			border: thin solid transparent;
			&.is-open {
				.Navigation__sublist.is-main {
					visibility: visible;
					opacity: 1;
					transition: all 0.1s ease-in-out;
				}
			}

			&:last-child {
				.Navigation__sublist {
					left: auto;
					right: ${buttonSpace}px;
					transform: translateX(0);
				}
			}
		}
		&.is-sub {
			border-bottom: thin solid ${themeGet('colors.mlcMediumGrey60')};

			&:last-child {
				border-bottom: none;
			}

			&.is-open {
				.Navigation__sublist {
					visibility: visible;
					opacity: 1;
					height: 100%;

					.Dropdowns__item {
						&:last-child {
							border-bottom: 0;
						}
					}
				}
			}

			.Navigation__sublist {
				.Dropdowns__item {
					&:first-child {
						border-top: thin solid ${themeGet('colors.mlcMediumGrey60')};
					}
				}
			}
		}
		.Dropdowns__icon-wrapper--mobile {
			display: none;
		}
		.Dropdowns__icon-wrapper--desktop {
			margin-left: 10px;
			display: block;
		}
		.Navigation__sublist {
			list-style: none;
			padding: 0;
			background-color: ${themeGet('colors.mlcWhite')};

			&.is-main {
				transition: all ease-in-out 0.1s;
				visibility: hidden;
				opacity: 0;
				z-index: 1;
				position: absolute;
				min-width: 185px;
				border: thin solid ${themeGet('colors.mlcMediumGrey60')};
				box-shadow: 2px 2px 10px 4px rgba(0, 0, 0, 0.1);
				left: 50%;
				transform: translateX(-50%);
				top: 60px;

				&:after {
					content: '';
					width: ${buttonSpace}px;
					height: ${buttonSpace}px;
					border-top: thin solid ${themeGet('colors.mlcMediumGrey60')};
					border-left: thin solid ${themeGet('colors.mlcMediumGrey60')};
					position: absolute;
					top: -8px;
					left: 50%;
					transform: translateX(-50%) rotate(45deg);
					background-color: ${themeGet('colors.mlcWhite')};
					pointer-events: auto;
				}
			}
			&.is-sub {
				position: relative;
				left: initial;
				transform: translateX(0);
				box-shadow: none;
				margin-left: 0;
				margin-right: 0;
				background-color: ${themeGet('colors.mlcCore')};
				visibility: hidden;
				height: 0;
				opacity: 0;
				transition: all ease-in-out 0.01s;

				.Dropdowns__link {
					background-color: ${themeGet('colors.mlcAlmostWhite')};
				}

				&:after {
					display: none;
				}
			}
		}
		.Dropdowns__icon-login {
			margin-right: 10px;
		}
	}

	/* this is for mobile dropdowns list only */
	@media (max-width: ${themeGet('gridTheme.breakpoints.lg')}px) {
		border-bottom: thin solid ${themeGet('colors.mlcMediumGrey60')};
		width: 100%;

		&.navigation_toplink {
			border-bottom: none;

			.Dropdowns__link {
				color: ${themeGet('colors.mlcDarkGrey')};
			}
		}

		.Dropdowns__link.is-ButtonStyle:after {
			display: none;
		}
		&.is-main.is-open {
			.Dropdowns__link {
				&:after {
					display: block;
				}
			}
		}
		&.is-sub {
			.Dropdowns__link:after {
				display: none;
			}
			&:first-child {
				&:after {
					content: '';
					position: absolute;
					top: 0;
					left: 0;
					right: 0;
					height: 1px;
					display: block;
					background-color: ${themeGet('colors.mlcMediumGrey60')};
				}
			}
			&.is-open {
				border-bottom: 0;
				margin-top: -50px;

				.Dropdowns__link.is-ButtonStyle {
					z-index: 2;

					&:after {
						display: block;
					}
				}
				.Navigation__sublist {
					visibility: visible;
					height: 100%;
					transform: translateX(0);
					transition: transform 0.2s ease-in-out;

					.Dropdowns__link:after {
						display: none;
					}
				}
			}
		}
		&.is-open {
			position: absolute;
			top: 0;
			right: 0;
			left: 0;
			bottom: 0;
			z-index: 2;

			.Navigation__sublist {
				visibility: visible;
				height: calc(100vh - 100px);
				transform: translateX(0);
			}
		}
		.Dropdowns__icon-wrapper--mobile {
			display: block;
		}
		.Dropdowns__icon-wrapper--desktop {
			display: none;
		}
		.Dropdowns__link.is-ButtonStyle {
			&:after {
				content: '';
				position: absolute;
				left: 0;
				height: 50px;
				top: 0;
				width: 4px;
				display: none;
			}
			&.is-sub {
				&:after {
					display: none;
				}
			}
		}
		.Navigation__sublist {
			list-style: none;
			padding: 0;
			background-color: white;
			z-index: 1;
			transform: translateX(100%);
			visibility: hidden;
			height: 0;
			transition: transform 0.2s ease-in-out;
			position: absolute;
			width: 100%;
			background-color: ${themeGet('colors.mlcWhite')};

			&.is-sub {
				z-index: 2;
				position: relative;
				transform: translateX(100%);
				visibility: hidden;
				position: absolute;
				width: 100%;
			}
		}
		.Dropdowns__icon-login {
			display: none;
		}
		.Dropdowns__content {
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}
`;
