import { EmployeeSsoState } from '../employeeSsoAuthenticate';
import { createLocalStoragePersistence } from './persists';
import BaseDisplayStrategy from './BaseDisplayStrategy';

export default class RedirectStrategy extends BaseDisplayStrategy {
    public readonly strategyName = 'redirect';

    private static readonly LOCAL_STORAGE_PERSIST_KEY = '__daf_employee_sso_auth_response__';

    constructor() {
        super(createLocalStoragePersistence(RedirectStrategy.LOCAL_STORAGE_PERSIST_KEY));
    }

    public authorize(url: string): Promise<void | EmployeeSsoState> {
        window.location.href = url;
        return Promise.resolve();
    }

    public onAuthorized(): Promise<void> {
        return Promise.resolve();
    }

    public shouldBreak(authState: EmployeeSsoState): boolean {
        return !authState;
    }
}
