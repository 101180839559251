import styled, { css } from 'styled-components';

const navCounterStyle = () => css`
	justify-content: center;
	display: block;
	outline: 0;
`;

export const NavCounter = styled.div`
	${navCounterStyle}
`;

const paginatorStyle = () => css`
	margin: 20px 0;
`;

export const PaginatorComponent = styled.nav`
	${paginatorStyle}
`;

const selectionMenuStyle = () => css`
	display: flex;
	margin-bottom: 15px;
	align-items: center;
`;

export const SelectionMenu = styled.span`
	${selectionMenuStyle}
`;
